import {lazy} from 'react';

export const userMessagesPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/user-messages/conversations/:userId',
        exact: true,
        component: lazy(() => import('./MessagesScreen')),
      },
      {
        path: '/user-messages',
        exact: true,
        component: lazy(() => import('./ConversationsScreen')),
      },
    ],
  },
];
