export default [
  {
    id: '1000',
    name: 'Aysha Julka',
    image: 'https://via.placeholder.com/150',
    message: 'commented on your profile picture.',
  },
  {
    id: '1008',
    name: 'Aysha Julka',
    image: 'https://via.placeholder.com/150',
    message: 'changed her profile picture.',
  },
];
