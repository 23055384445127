import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalItem from './VerticalItem';
import AppContext from '../../../utility/AppContext';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalCollapase.style';
import {
  selectUseNevro,
  selectUseMainstay,
  selectEnablePortalPro,
  selectIsPortalProClinic,
} from 'redux/reducers/admin/instance-settings';
import { useDispatch, useSelector } from 'react-redux';
import { EmojiPeopleOutlined } from '@material-ui/icons';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ApiConfig from '@e74/services/ApiConfig';
const needsToBeOpened = (pathname, item) => {
  return pathname && isUrlInChildren(item, pathname);
};

const isUrlInChildren = (parent, url) => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].url === url ||
      url.includes(parent.children[i].url)
    ) {
      return true;
    }
  }

  return false;
};

const VerticalCollapse = ({ classes, ...props }) => {
  const { theme } = useContext(AppContext);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(() => needsToBeOpened(pathname, props.item));
  const { item, level } = props;
  const useNevro = useSelector(selectUseNevro);
  const useMainstay = useSelector(selectUseMainstay);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);

  let isParent = false;
  let isChild = false;
  if (localStorage.getItem('isParent')) {
    isParent = localStorage.getItem('isParent') === 'true';
  }
  if (localStorage.getItem('isChild')) {
    isChild = localStorage.getItem('isChild') === 'true';
  }
  let userRole = localStorage.getItem('userRole');
  let isSystemManager = userRole.indexOf('System Manager') > -1;

  const canRenderThisItem = (item) => {
    if (!useNevro) {
      if (item.hideOnNevroNotEnabled !== undefined) {
        if (item.hideOnNevroNotEnabled) {
          return false;
        }
      }
    }

    if (isSystemManager) {
      return true;
    }

    if (isParent) {
      if (useMainstay) {
        if (item.mainstayParent) {
          return true;
        } else {
          return false;
        }
      }

      if (item.isParent !== undefined) {
        if (item.isParent === isParent && isParent === true) {
          return true;
        }

        return false;
      }
    }

    if (isChild) {
      if (useMainstay) {
        if (item.mainstayChild) {
          return item.access.indexOf(userRole) > -1;
        } else {
          return false;
        }
      }
    }

    if (useNevro) {
      if (item.nevro === undefined) {
        return true;
      }

      return item.nevro === useNevro;
    }

    if (item.access) {
      if (item.access.indexOf(userRole) > -1) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (needsToBeOpened(pathname, props.item)) {
      setOpen(true);
    }
  }, [pathname, props.item]);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        component='li'
        className={clsx(
          useNevro && !isPortalProClinic
            ? classes.navItemNevro
            : classes.navItem,
          'nav-item',
          open && 'open',
        )}
        onClick={handleClick}>
        {item.icon && (
          <Box
            component='span'
            mr={6}
            style={{ display: 'flex', alignItems: 'center' }}>
            {item.icon === 'emojiPeopleOutlined' ? (
              <EmojiPeopleOutlined
                className={clsx('nav-item-icon', classes.listIcon)}
              />
            ) : item.icon === 'HeadsetMic' ? (
              <HeadsetMicIcon />
            ) : (
              <Icon
                color='action'
                className={clsx('nav-item-icon', classes.listIcon)}>
                {item.icon}
              </Icon>
            )}
          </Box>
        )}
        <ListItemText
          classes={{ primary: clsx('nav-item-text', classes.listItemText) }}
          primary={<IntlMessages id={item.messageId} />}
        />
        <Box p={0} clone>
          <IconButton disableRipple>
            <Icon className='nav-item-icon-arrow' color='inherit'>
              {open
                ? 'expand_more'
                : theme.direction === 'ltr'
                  ? 'chevron_right'
                  : 'chevron_left'}
            </Icon>
          </IconButton>
        </Box>
      </ListItem>

      {item.children && (
        <Collapse in={open} className='collapse-children'>
          {item.children.map(
            (children) =>
              canRenderThisItem(item) && (
                <React.Fragment key={children.id}>
                  {children.type === 'collapse' && (
                    <VerticalCollapse
                      key={children.id}
                      item={children}
                      level={level + 1}
                      location={props.location}
                    />
                  )}

                  {children.type === 'item' && canRenderThisItem(children) && (
                    <VerticalItem item={children} level={level + 1} />
                  )}
                </React.Fragment>
              ),
          )}
        </Collapse>
      )}
    </>
  );
};

VerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};
VerticalCollapse.defaultProps = {};

export default withStyles(useStyles)(React.memo(VerticalCollapse));
