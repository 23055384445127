import {createMuiTheme} from '@material-ui/core/styles';
import moment from 'moment';
import SwalAccessibleDialogNode from 'shared/components/SwalAccessibleDialogNode';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import React from 'react';
import {colors} from '@material-ui/core';
import {FontsNevro, ThemeStyleRadius} from 'shared/constants/AppEnums';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const isBreakPointDown = (key) => {
  const defaultTheme = createMuiTheme();
  return defaultTheme.breakpoints.width(key) > window.innerWidth;
};

export const nevroTheme = () => {
  const cardRadius = ThemeStyleRadius.STANDARD;
  const breakpoints = createBreakpoints({});

  return createMuiTheme({
    typography: {
      fontFamily: [FontsNevro.REGULAR, 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: '#00B6EE',
        contrastText: '#fff',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: cardRadius,
          boxShadow: '0px 5px 6px rgb(100, 180, 253, 0.2)',
          [breakpoints.down('md')]: {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
          border: '1px solid #fff',
          '&:hover': {
            backgroundColor: '#fff !important',
            color: '#00B6EE !important',
            border: '1px solid #00B6EE !important',
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: '#00B6EE !important',
        },
      },
      MuiRadio: {
        root: {
          color: '#00B6EE !important',
        },
      },
      MuiAutocomplete: {
        option: {
          background: '#00B6EE',
          '&:hover': {
            background: '#298094',
          },
        },
      },
      MuiMenu: {
        root: {
          backgroundColor: '#00B6EE',
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: '#fff !important',
          },
          '&.Mui-selected': {
            color: '#00B6EE !important',
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: '#006E9F !important',
        },
      },
    },
  });
};

export const nevroDropdownTheme = () => {
  return createMuiTheme({
    palette: {
      primary: {
        main: '#00B6EE',
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#00B6EE',
          color: 'white',
        },
      },
      MuiAutocomplete: {
        option: {
          '&:hover, &[data-focus="true"]': {
            background: 'white !important',
            color: 'black !important',
          },
        },
      },

      MuiMenu: {
        root: {
          backgroundColor: '#00B6EE',
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            color: 'black !important',
            backgroundColor: 'white !important',
          },
          '&.Mui-selected': {
            color: 'black !important',
            backgroundColor: 'white',
          },
        },
      },
    },
  });
};

// --bg-select: #303030;
// --shadow-select: 0 0.25em 1.5em rgb(0 0 0 / 30%);
// --bg-select-hover: #6b8e23;
// --color-select-selected: #81ccc4;
// --bg-border-select: rgba(0, 0, 0, 0.23);
// --bg-border-select-hover: rgba(0, 0, 0, 0.87);
// --bg-border-select-focus: #59bbd1;

export const defaultDropdownTheme = () => {
  return createMuiTheme({
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#7E57FE',
          color: 'white',
        },
      },
      MuiMenu: {
        root: {
          backgroundColor: '#7E57FE',
          color: 'white',
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: '#ffff !important',
            color: 'black',
          },
          '&.Mui-selected': {
            color: '#7E57FE !important',
            backgroundColor: '#ffff !important',
          },
        },
      },
    },
  });
};

export const createRoutes = (routeConfigs) => {
  let allRoutes = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return { ...route, auth };
    });
  }

  return [...routes];
};

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

export const convertDate = (dateInput) => {
  var inputDate = moment.utc(dateInput);
  //var localDate = moment(inputDate).local();
  //return localDate.format('MM/DD/YYYY');
  let currentOffsetHours = localStorage.getItem('timeZoneOffsetHours');
  var localDate = moment(inputDate).add(currentOffsetHours, 'hours');
  var result = localDate.format('MM/DD/YYYY');
  return result;
};

export const convertDateWithoutTimezone = (date) => {
  return moment(date).format('MM/DD/YYYY');
};

export const convertDateYearFirst = (dateInput) => {
  var inputDate = moment.utc(dateInput);
  //var localDate = moment(inputDate).local();
  //return localDate.format('YYYY-MM-DD');
  let currentOffsetHours = localStorage.getItem('timeZoneOffsetHours');
  var localDate = moment(inputDate).add(currentOffsetHours, 'hours');
  var result = localDate.format('MM/DD/YYYY');
  return result;
};

export const convertDateTime = (dateInput) => {
  var inputDate = moment.utc(dateInput);
  // var localDate = moment(inputDate).local();
  let currentOffsetHours = localStorage.getItem('timeZoneOffsetHours');
  var localDate = moment(inputDate).add(currentOffsetHours, 'hours');
  var result = localDate.format('MM/DD/YYYY hh:mm a');
  return result;
};

export const convertDateTime24HourFormat = (dateInput) => {
  var inputDate = moment.utc(dateInput);
  // var localDate = moment(inputDate).local();
  let currentOffsetHours = localStorage.getItem('timeZoneOffsetHours');
  var localDate = moment(inputDate).add(currentOffsetHours, 'hours');
  return localDate.format('MM/DD/YYYY HH:mm');
};

export const convertTime = (dateInput) => {
  var inputDate = moment.utc(dateInput);
  var localDate = moment(inputDate).local();
  return localDate.format('hh:mm a');
};

export const isZipValid = (zip) => {
  return /^\d{5}(-\d{4})?$/.test(zip);
};

export const isPinCodeValid = (pinCode) => /^\d{4}$/.test(pinCode);

export const isEmailAddressValid_Old = (emailAddress) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(
    emailAddress,
  );

export const isEmailAddressValid = (emailAddress) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);

export const convertToInstanceTime = (dateInput) => {
  let currentOffsetHours = localStorage.getItem('timeZoneOffsetHours');
  const convertedDate = new Date(
    moment(dateInput)
      .subtract(new Date(dateInput).getTimezoneOffset(), 'minutes')
      .subtract(currentOffsetHours, 'hours'),
  );
  return convertedDate;
};

export const formatDateTime = (time) =>
  moment.utc(time).format('MM/DD/YYYY hh:mm a');

export const getMapCoords = (coordinates, ref) =>
  typeof coordinates === 'object' &&
  coordinates
    ?.map(
      (c) =>
        `${c.x * ref?.current?.clientWidth},${c.y * ref?.current?.clientHeight
        }`,
    )
    ?.join(' ');

export const getUserFriendlyTimestamp = (rawTimestamp) => {
  let userFriendlyTimestamp = rawTimestamp;

  const timestampInCurrentTimeZone = new Date(convertDateTime(rawTimestamp));
  const today = new Date();
  const yesterday = new Date(new Date().setDate(today.getDate() - 1));

  const timestampInCurrentTimeZoneTimeString = `${timestampInCurrentTimeZone.getHours() >= 13
      ? timestampInCurrentTimeZone.getHours() - 12
      : `${timestampInCurrentTimeZone.getHours()}`
    }:${timestampInCurrentTimeZone.getMinutes() >= 10
      ? timestampInCurrentTimeZone.getMinutes()
      : `0${timestampInCurrentTimeZone.getMinutes()}`
    }${timestampInCurrentTimeZone.getHours() >= 12 ? 'pm' : 'am'}`;

  if (timestampInCurrentTimeZone.toDateString() === today.toDateString()) {
    userFriendlyTimestamp = `Today at ${timestampInCurrentTimeZoneTimeString}`;
  } else if (
    timestampInCurrentTimeZone.toDateString() === yesterday.toDateString()
  ) {
    userFriendlyTimestamp = `Yesterday at ${timestampInCurrentTimeZoneTimeString}`;
  } else {
    userFriendlyTimestamp = `${timestampInCurrentTimeZone.getFullYear()}/${timestampInCurrentTimeZone.getMonth() + 1
      }/${timestampInCurrentTimeZone.getDate()} at ${timestampInCurrentTimeZoneTimeString}`;
  }

  return userFriendlyTimestamp;
};

export const getMultiSelectMenuProps = (
  ITEM_HEIGHT = 48,
  ITEM_PADDING_TOP = 8,
) => ({
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
});

export const getDistinct = (array) =>
  array.filter((value, index, self) => self.indexOf(value) === index);

export const getOrdinal = (num) => {
  switch (num % 100) {
    case 11:
      return num + 'th';
    case 12:
      return num + 'th';
    case 13:
      return num + 'th';
  }

  switch (num % 10) {
    case 1:
      return num + 'st';
    case 2:
      return num + 'nd';
    case 3:
      return num + 'rd';
    default:
      return num + 'th';
  }
};

export const isMonthMMValid = (month) => {
  if (/^\d{2}$/.test(month)) {
    let intMonth = parseInt(month);

    return !!intMonth && intMonth >= 1 && intMonth <= 12;
  }
  return false;
};

export const isMonthMMValidInt = (month) => {
  if (!isNaN(month)) {
    let intMonth = parseInt(month);
    return !!intMonth && intMonth >= 1 && intMonth <= 12;
  }
  return false;
};

export const isYearYYYYValid = (year) => /^\d{4}$/.test(year);

export const isCardSecurityCodeValid = (code) => /^\d{3,4}$/.test(code);
export const accessibleSwal = (props) => {
  let wrapper = document.createElement('div');
  wrapper.id = 'swal-wrapper';
  ReactDOM.render(
    <SwalAccessibleDialogNode title={props.title} text={props.text} />,
    wrapper,
  );
  let el = wrapper.firstChild;
  return swal({
    ...props,
    content: {
      element: el,
    },
    text: null,
    title: null,
  });
};

export const enableZoom = () => {
  // const metaZoomTag = document.querySelector(
  //   `meta[content='${META_TAG_CONTENT_TO_DISABLE_ZOOM}']`,
  // );
  // if (metaZoomTag) metaZoomTag.parentNode.removeChild(metaZoomTag);

  document.body.classList.add('enable-pinch-zoom');
  document.body.classList.remove('disable-pinch-zoom');
};

export const disableZoom = () => {
  // let metaZoomTag = document.createElement('meta');
  // metaZoomTag.setAttribute('name', 'viewport');
  // metaZoomTag.setAttribute('content', META_TAG_CONTENT_TO_DISABLE_ZOOM);

  // document.head.appendChild(metaZoomTag);

  document.body.classList.add('disable-pinch-zoom');
  document.body.classList.remove('enable-pinch-zoom');
};

export const isLocaleDateStringValid = (dateStr) => {
  // const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/g;
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return regex.test(dateStr);
};

export const isGoogleReviewLinkValid = (googleReviewLink) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    googleReviewLink,
  );

export const checkFileSize = (sizeInMb, itemSize, wantsGreater) => {
  const sizeInBytes = sizeInMb * 1024 * 1024;
  if (wantsGreater) {
    return itemSize >= sizeInBytes;
  } else {
    return itemSize < sizeInBytes;
  }
}
