import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Divider,
  Typography,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
  Icon,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon, RecentActors } from '@material-ui/icons';

import { makeStyles, InputAdornment } from '@material-ui/core';
import { DEFAULT_SORT_BY_OPTION } from 'shared/constants/AppConst';
import * as actions from '../../../redux/actions/patient/patient';
import {
  selectActionRequiredCount,
  selectApprovedCount,
  selectCaseReceivedCount,
  selectCaseStatus,
  selectCasesInProgressCount,
  selectCurrentPageNumber,
  selectDeniedCount,
  selectFetchPatients,
  selectPatientNotMovingForwardCount,
  selectPatientStatus,
  selectPendingInsuranceCount,
  selectPendingSubmissionCount,
  selectPortalStatusList,
  selectPreviousStatusCount,
  selectPriorAuthNotRequiredCount,
  selectProcedurePerformedCount,
  selectRowPerPage,
  selectSortBy,
} from 'redux/reducers/patient/patient';

const FilterAndSortPanel = (props) => {
  const customClasses = makeStyles((theme) => ({
    filterAndSortPanel: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
      textWrap: 'nowrap',
    },
    selectList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'black',
    },
    selectionField: {
      width: '270px',
      marginTop: '10px',
      marginLeft: '-12px',
      borderRadius: '4px',
      height: 40,
      border: 'solid #F4F3F3 1px',
    },
    inputLabel: {
      fontSize: 14,
    },

    radioGroup: {
      marginLeft: -10,
      paddingLeft: 0,
    },

    icon: {
      marginRight: 5,
      flexShrink: 0,
    },

    alertText: {
      color: 'red',
      fontWeight: 'bold',
    },
  }));
  const classes = customClasses();

  const pageNo = useSelector((state) => state.patient.currentPageNumber);
  const rowPerPage = useSelector((state) => state.patient.rowPerPage);

  const fetchPatients = useSelector(selectFetchPatients);
  const casesInProgressCount = useSelector(selectCasesInProgressCount);
  const pendingSubmissionCount = useSelector(selectPendingSubmissionCount);
  const caseReceivedCount = useSelector(selectCaseReceivedCount);
  const actionRequiredCount = useSelector(selectActionRequiredCount);
  const pendingInsuranceCount = useSelector(selectPendingInsuranceCount);
  const priorAuthNotRequiredCount = useSelector(
    selectPriorAuthNotRequiredCount,
  );
  const approvedCount = useSelector(selectApprovedCount);
  const deniedCount = useSelector(selectDeniedCount);
  const patientNotMovingForwardCount = useSelector(
    selectPatientNotMovingForwardCount,
  );
  const procedurePerformedCount = useSelector(selectProcedurePerformedCount);

  const previousStatusCount = useSelector(selectPreviousStatusCount);
  const portalStatusList = useSelector(selectPortalStatusList);

  const [pendingSubmissionLog, setpendingSubmissionLog] = useState();
  const [caseReceivedLog, setCaseReceivedLog] = useState();
  const [actionRequiredLog, setactionRequiredLog] = useState();
  const [pendingInsuranceLog, setPendingInsuranceLog] = useState();
  const [priorAuthNotRequiredLog, setPriorAuthNotRequiredLog] = useState();
  const [approvedLog, setApprovedLog] = useState();
  const [deniedLog, setDeniedLog] = useState();
  const [
    patientNotMovingForwardLog,
    setpatientNotMovingForwardLog,
  ] = useState();
  const [procedurePerformedLog, setProcedurePerformedLog] = useState();

  const [caseStatus, setCaseStatus] = useState(props.caseStatus);
  const [sortBy, setSortBy] = useState(props.sortBy);
  const [expanded, setExpanded] = useState(true);
  const [statusCount, setStatusCount] = useState(casesInProgressCount);

  const getStatusCount = (status) => {
    switch (status) {
      case 'Cases in Progress':
        return casesInProgressCount;
      case 'Pending Submission to Nevro':
        return pendingSubmissionCount;
      case 'Case Received by Nevro':
        return caseReceivedCount;
      case 'Action Required by Office':
        return actionRequiredCount;
      case 'Case Pending with Insurance':
        return pendingInsuranceCount;
      case 'Prior Auth Not Required':
        return priorAuthNotRequiredCount;
      case 'Approved Cases':
        return approvedCount;
      case 'Denied Cases':
        return deniedCount;
      case 'Patient Not Moving Forward':
        return patientNotMovingForwardCount;
      case 'Procedure Performed':
        return procedurePerformedCount;
      default:
        return 0;
    }
  };

  const setLogs = (status, obj) => {
    switch (status) {
      case 'Pending Submission to Nevro':
        setpendingSubmissionLog(obj);
        break;
      case 'Case Received by Nevro':
        setCaseReceivedLog(obj);
        break;
      case 'Action Required by Office':
        setactionRequiredLog(obj);
        break;
      case 'Case Pending with Insurance':
        setPendingInsuranceLog(obj);
        break;
      case 'Prior Auth Not Required':
        setPriorAuthNotRequiredLog(obj);
        break;
      case 'Approved Cases':
        setApprovedLog(obj);
        break;
      case 'Denied Cases':
        setDeniedLog(obj);
        break;
      case 'Patient Not Moving Forward':
        setpatientNotMovingForwardLog(obj);
        break;
      case 'Procedure Performed':
        setProcedurePerformedLog(obj);
        break;
      default:
        break;
    }
  };

  const handleAccordionClick = () => {
    setExpanded(!expanded);
  };

  const handleSelectionChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'sortBy':
        setSortBy(value);
        break;
      case 'status':
        setCaseStatus(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const newStatusCount = getStatusCount(caseStatus);
    setStatusCount(newStatusCount);
  }, [
    caseStatus,
    casesInProgressCount,
    pendingSubmissionCount,
    caseReceivedCount,
    actionRequiredCount,
    pendingInsuranceCount,
    priorAuthNotRequiredCount,
    approvedCount,
    deniedCount,
    patientNotMovingForwardCount,
    procedurePerformedCount,
  ]);

  useEffect(() => {
    portalStatusList.forEach((x) => {
      var obj = {
        lastUpdatedOn: new Date(x.lastUpdated).getTime(),
        lastVisitedOn: new Date(x.lastVisited).getTime(),
      };
      setLogs(x.status, obj);
    });
  }, [portalStatusList]);

  // useEffect(() => {
  //   props.setSortBy(sortBy);
  //   const filter = {
  //     sortBy: sortBy,
  //     status: caseStatus,
  //     patientStatus: props.patientStatus,
  //     paginationFilter: {
  //       pageNumber: pageNo,
  //       pageSize: rowPerPage,
  //     },
  //   };
  //   props.fetchFiltered(filter);
  // }, [sortBy, caseStatus, previousStatusCount]);

  useEffect(() => {
    props.setCaseStatus(caseStatus);
    if (!fetchPatients) {
      props.setFetchPatients(true);
    }
  }, [caseStatus]);

  useEffect(() => {
    props.setSortBy(sortBy);
  }, [sortBy]);

  return (
    <div className={classes.filterAndSortPanel}>
      <Divider
        orientation='horizontal'
        variant='middle'
        style={
          {
            // backgroundColor: 'white',
            // margin: '10px 15px 10px -5px',
          }
        }
      />

      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          onClick={handleAccordionClick}>
          <RecentActors className={classes.icon}></RecentActors>
          {expanded ? (
            <Typography sx={{ width: '33%', flexShrink: 1 }}>
              Case Status &nbsp;
            </Typography>
          ) : (
            <Typography
              style={{ flexShrink: 1, textWrap: 'wrap' }}
              sx={{ width: '33%', flexShrink: 1 }}>
              {`${caseStatus} (${statusCount})`}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            row
            aria-label='status'
            name='status'
            value={caseStatus}
            defaultValue={caseStatus}
            onChange={handleSelectionChange}
            className={classes.radioGroup}>
            <FormControlLabel
              value='Cases in Progress'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  Cases in Progress ({casesInProgressCount})
                </Typography>
              }
            />
            <FormControlLabel
              value='Pending Submission to Nevro'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Pending Submission to Nevro</span>
                  <span
                    className={
                      pendingSubmissionLog && pendingSubmissionCount > 0 &&
                        pendingSubmissionLog.lastUpdatedOn >
                        pendingSubmissionLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({pendingSubmissionCount}){' '}
                  </span>
                </Typography>
              }
            />
            <FormControlLabel
              value='Case Received by Nevro'
              control={<Radio size='medium' />}
              className={classes.inputLabel}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Case Received by Nevro</span>
                  <span
                    className={
                      caseReceivedLog && caseReceivedCount > 0 &&
                        caseReceivedLog.lastUpdatedOn >
                        caseReceivedLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({caseReceivedCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Action Required by Office'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Action Required by Office</span>
                  <span
                    className={
                      actionRequiredLog && actionRequiredCount > 0 &&
                        actionRequiredLog.lastUpdatedOn >
                        actionRequiredLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({actionRequiredCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Case Pending with Insurance'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Case Pending with Insurance</span>
                  <span
                    className={
                      pendingInsuranceLog && pendingInsuranceCount > 0 &&
                        pendingInsuranceLog.lastUpdatedOn >
                        pendingInsuranceLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({pendingInsuranceCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Prior Auth Not Required'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Prior Authorization Not Required</span>
                  <span
                    className={
                      priorAuthNotRequiredLog && priorAuthNotRequiredCount > 0 &&
                        priorAuthNotRequiredLog.lastUpdatedOn >
                        priorAuthNotRequiredLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({priorAuthNotRequiredCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Approved Cases'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Approved Cases</span>
                  <span
                    className={
                      approvedLog && approvedCount > 0 &&
                        approvedLog.lastUpdatedOn > approvedLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({approvedCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Denied Cases'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Denied Cases</span>
                  <span
                    className={
                      deniedLog && deniedCount > 0 &&
                        deniedLog.lastUpdatedOn > deniedLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({deniedCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Patient Not Moving Forward'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Patient Not Moving Forward</span>
                  <span
                    className={
                      patientNotMovingForwardLog && patientNotMovingForwardCount > 0 &&
                        patientNotMovingForwardLog.lastUpdatedOn >
                        patientNotMovingForwardLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({patientNotMovingForwardCount}){' '}
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              value='Procedure Performed'
              control={<Radio size='medium' />}
              label={
                <Typography className={classes.inputLabel}>
                  <span>Procedure Performed</span>
                  <span
                    className={
                      procedurePerformedLog && procedurePerformedCount > 0 &&
                        procedurePerformedLog.lastUpdatedOn >
                        procedurePerformedLog.lastVisitedOn
                        ? classes.alertText
                        : ''
                    }>
                    {' '}
                    ({procedurePerformedCount}){' '}
                  </span>
                </Typography>
              }
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      {/* <Divider
        orientation='horizontal'
        variant='middle'
        style={{
          // backgroundColor: 'white',
          margin: '10px 15px 10px -5px',
        }}
      /> */}

      {/* <div className={classes.selectList}>
        <ThemeProvider theme={nevroDropdownTheme}>
          <Select
            labelId='sortByLabel'
            id='sortBy'
            name='sortBy'
            value={sortBy}
            displayEmpty
            className={classes.selectionField}
            onChange={handleSelectionChange}
            startAdornment={
              <InputAdornment position='start'>
                <Sort />
              </InputAdornment>
            }>
            <MenuItem value='' disabled>
              <em>Sort By</em>
            </MenuItem>
            <MenuItem value={DEFAULT_SORT_BY_OPTION}>Date Added</MenuItem>
            <MenuItem value={'FirstName'}>First Name</MenuItem>
            <MenuItem value={'LastName'}>Last Name</MenuItem>
          </Select>
        </ThemeProvider>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sortBy: selectSortBy(state),
  caseStatus: selectCaseStatus(state),
  patientStatus: selectPatientStatus(state),
  currentPageNumber: selectCurrentPageNumber(state),
  rowPerPage: selectRowPerPage(state),
});

const mapActionToProps = {
  fetchFiltered: actions.fetchFiltered,
  setCaseStatus: actions.setCaseStatus,
  setSortBy: actions.setSortBy,
  setPreviousStatusCount: actions.setPreviousCaseStatus,
  setFetchPatients: actions.setFetchPatients,
};

export default connect(mapStateToProps, mapActionToProps)(FilterAndSortPanel);
