export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 4,
};
export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
};
export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  MINI_SIDEBAR_TOGGLE: 'mini_sidebar_toggle',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_LIGHT_NAV: 'hor-light-nav',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const HeaderType = {
  DARK: 'dark',
  LIGHT: 'light',
};
export const RouteTransition = {
  NONE: 'none',
  FADE: 'fade',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: 'Poppins',
  REGULAR: 'Poppins',
  MEDIUM: 'Poppins',
  BOLD: 'Poppins',
  EXTRA_BOLD: 'Poppins',
};
export const FontsNevro = {
  LIGHT: 'Roboto',
  REGULAR: 'Roboto',
  MEDIUM: 'Roboto',
  BOLD: 'Roboto',
  EXTRA_BOLD: 'Roboto',
};
export const AuthType = {
  FIREBASE: 'firebase',
  AWS_COGNITO: 'aws_cognito',
  AUTH0: 'auth0',
  JWT_AUTH: 'jwt_auth',
};

//Fixed on API

export const MessageTimeType = {
  Hour: 1,
  Day: 2,
  Week: 3,
  Month: 4,
};

export const MessageCadence = {
  Before: 1,
  After: 2,
};

export const TemplateStatus = {
  Active: 1,
  Inactive: 2,
};

export const MessageStatus = {
  Active: 1,
  Inactive: 2,
};

export const AppointmentInterval = {
  Today: '1',
  This_Week: '2',
  Last_Week: '3',
  This_Month: '4',
  Last_Month: '5',
  This_Year: '6',
  Custom_Dates: '7',
  Fixed_Date: '8',
};

export const PerformanceMeasureAppointmentInterval = {
  This_Week: '1',
  Last_Week: '2',
  This_Month: '3',
  Last_Month: '4',
  This_Quarter: '5',
  Last_Quarter: '6',
  This_Year: '7',
  Last_Year: '8',
  Custom_Dates: '9',
};

export const Procedure = {
  Medical_Management: '1',
  Ordered_Injection: '2',
  Physical_Therapy: '3',
  Interventional: '4',
  Outside_Referral: '5',
  Pump_Management: '6',
  Ordered_Imaging: '7',
  No_Actions_Taken: '8',
  Administered_Injection: '9',
};

export const PumpIntervention = {
  'Pump Dye Study': '1',
  'Catheter Revision': '2',
  'Pump Change': '3',
};

export const PumpStatus = {
  Maintain: '1',
  Increase: '2',
  Decrease: '3',
  'Change To': '4',
};

export const DosageUnit = {
  MG: '1',
  MCG: '2',
};

export const ProcedureComplianceStatus = {
  Completed: '1',
  Not_Completed: '2',
  Partially_Completed: '3',
};

export const States = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'Not Applicable': 'NA',
};

export const Countries = {
  Afghanistan: 'AF',
  'Aland Islands': 'AX',
  Albania: 'AL',
  Algeria: 'DZ',
  'American Samoa': 'AS',
  Andorra: 'AD',
  Angola: 'AO',
  Anguilla: 'AI',
  Antarctica: 'AQ',
  'Antigua and Barbuda': 'AG',
  Argentina: 'AR',
  Armenia: 'AM',
  Aruba: 'AW',
  Australia: 'AU',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  Bolivia: 'BO',
  'Bosnia and Herzegovina': 'BA',
  Botswana: 'BW',
  'Bouvet Island': 'BV',
  Brazil: 'BR',
  'British Virgin Islands': 'VG',
  'British Indian Ocean Territory': 'IO',
  'Brunei Darussalam': 'BN',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Cambodia: 'KH',
  Cameroon: 'CM',
  Canada: 'CA',
  'Cape Verde': 'CV',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  China: 'CN',
  'Hong Kong, SAR China': 'HK',
  'Macao, SAR China': 'MO',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands': 'CC',
  Colombia: 'CO',
  Comoros: 'KM',
  'Congo (Brazzaville)': 'CG',
  'Congo (Kinshasa)': 'CD',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  "Côte d'Ivoire": 'CI',
  Croatia: 'HR',
  Cuba: 'CU',
  Cyprus: 'CY',
  'Czech Republic': 'CZ',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Ethiopia: 'ET',
  'Falkland Islands (Malvinas)': 'FK',
  'Faroe Islands': 'FO',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  'French Guiana': 'GF',
  'French Polynesia': 'PF',
  'French Southern Territories': 'TF',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Greece: 'GR',
  Greenland: 'GL',
  Grenada: 'GD',
  Guadeloupe: 'GP',
  Guam: 'GU',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  'Heard and Mcdonald Islands': 'HM',
  'Holy See (Vatican City State)': 'VA',
  Honduras: 'HN',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  'Iran, Islamic Republic of': 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jersey: 'JE',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  'Korea (North)': 'KP',
  'Korea (South)': 'KR',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  'Lao PDR': 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  'Macedonia, Republic of': 'MK',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  'Marshall Islands': 'MH',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Mexico: 'MX',
  'Micronesia, Federated States of': 'FM',
  Moldova: 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Netherlands: 'NL',
  'Netherlands Antilles': 'AN',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: 'NU',
  'Norfolk Island': 'NF',
  'Northern Mariana Islands': 'MP',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  'Palestinian Territory': 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Pitcairn: 'PN',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Réunion: 'RE',
  Romania: 'RO',
  'Russian Federation': 'RU',
  Rwanda: 'RW',
  'Saint-Barthélemy': 'BL',
  'Saint Helena': 'SH',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint-Martin (French part)': 'MF',
  'Saint Pierre and Miquelon': 'PM',
  'Saint Vincent and Grenadines': 'VC',
  Samoa: 'WS',
  'San Marino': 'SM',
  'Sao Tome and Principe': 'ST',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Singapore: 'SG',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Islands': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Georgia and the South Sandwich Islands': 'GS',
  'South Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  Sudan: 'SD',
  Suriname: 'SR',
  'Svalbard and Jan Mayen Islands': 'SJ',
  Swaziland: 'SZ',
  Sweden: 'SE',
  Switzerland: 'CH',
  'Syrian Arab Republic (Syria)': 'SY',
  'Taiwan, Republic of China': 'TW',
  Tajikistan: 'TJ',
  'Tanzania, United Republic of': 'TZ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Togo: 'TG',
  Tokelau: 'TK',
  Tonga: 'TO',
  'Trinidad and Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  Turkmenistan: 'TM',
  'Turks and Caicos Islands': 'TC',
  Tuvalu: 'TV',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  'United States of America': 'US',
  'US Minor Outlying Islands': 'UM',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Vanuatu: 'VU',
  'Venezuela (Bolivarian Republic)': 'VE',
  'Viet Nam': 'VN',
  'Virgin Islands, US': 'VI',
  'Wallis and Futuna Islands': 'WF',
  'Western Sahara': 'EH',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
};

export const StatesList = [
  {
    code: 'MO',
    label: 'Missouri',
  },
  {
    code: 'SD',
    label: 'South Dakota',
  },
];

export const TemplateName = [
  'MILD',
  'Kyphoplasy',
  'SI Joint Fusion',
  'SCS Trial',
  'SCS Perm Implant',
  'Epidural Steroid Injection',
  'Medial Branch Blocks',
  'Medial Branch RFA',
];

export const AppointmentTypes = {
  'Follow up': '1',
  'Intervention/Surgical': '2',
  'New Patient': '3',
  Telemed: '4',
  Injection: 5,
};

export const Effectivity = {
  Short_Acting: '1',
  Long_Acting: '2',
};

export const MME = {
  '0-19': '1',
  '20-39': '2',
  '40-59': '3',
  '60-79': '4',
  '80+': '5',
};

export const Genders = {
  Male: '1',
  Female: '2',
  'I prefer not to identify': '3',
};

export const MaritalStatus = {
  Single: '1',
  Married: '2',
  Divorced: '3',
  Widowed: '4',
  Separated: '5',
  Partner: '6',
  Unknown: '7',
};

export const OccupationStatus = {
  'Fully Employed': '1',
  Unemployed: '2',
  'Part Time': '3',
  Disability: '4',
  Retired: '5',
  Unknown: '6',
};

export const NicotineStatus = {
  Current: '1',
  Moderate: '2',
  Former: '3',
  Never: '4',
};

export const DiseaseState = {
  'High Blood Pressure': '1',
  'High Cholesterol': '2',
  Diabetes: '3',
  'Thyroid Problems': '4',
  None: '5',
};

export const PsychiatricStatus = {
  Depression: '1',
  Anxiety: '2',
  Other: '3',
  None: '4',
};

export const SurgicalHistory = {
  'Low Back': '1',
  Neck: '2',
  'SI Joint': '3',
  None: '4',
  Unknown: '5',
};

export const YesNo = {
  Yes: '1',
  No: '0',
};

export const PayorStatus = {
  'Private Insurance(Blue Cross Blue Shield, United, etc.)': '1',
  Medicare: '2',
  Medicaid: '3',
  Tricare: '4',
  'Cash Pay': '5',
  Other: '6',
};

export const Form = {
  SF36: 1,
  ODI: 2,
  NDI: 3,
  PDI: 4,
  'HOOS JR': 5,
  'KOOS JR': 6,
  'PROMIS Global-10': 7,
  'VR-12': 8,
  ORT: 9,
  PHQ2: 10,
  PHQ9: 11,
  GAD7: 12,
  SOAPPR: 13,
  AUDITC: 14,
  CESDR: 15,
};

export const FormColor = {
  SF36: '#50EBA6',
  ODI: '#EA4228',
  NDI: '#5BE12C',
  PDI: '#0000ff',
  'HOOS JR': '#ffb600',
  'KOOS JR': '#bcbf00',
  'PROMIS Global-10': '#00fffa',
  'VR-12': '#50EBA6',
};

export const DefaultPainScores = {
  Worst: 1,
  Average: 2,
  Best: 3,
  Today: 4,
};

export const PainScoreColor = {
  PAIN_SCORE: '#EA4228',
  PAIN_SCORE_WITH_MEDICATION: '#ffb600',
  PAIN_SCORE_BEST: '#45EC9C',
};

export const FormGaugePercentageDivisor = {
  1: 100,
  2: 100,
  3: 100,
  4: 70,
  5: 100,
  6: 100,
  // 7: [67.7, 67.6],
  7: 67.6,
  8: 100,
  9: 26,
  11: 27,
};

export const ActivityTypes = {
  Created: 1,
  Updated: 2,
  Deleted: 3,
  Viewed: 4,
  Sent: 5,
  Failed: 6,
};

export const ActivityCategory = {
  'Admin Configuration': 1,
  'Patient Profile': 2,
  'Appointment Schedules': 3,
  Checkins: 4,
  Interventions: 5,
  'System Users': 6,
  'Patient List': 7,
  'Patient Dashboard': 8,
  'Patient Sign-In': 9,
  'Intake Information': 10,
  'Form Submission': 11,
  Appointments: 12,
  'Patient Study Group': 13,
  'Login Activity': 14,
};

export const Race = {
  'American Indian/Alaska Native': '1',
  Asian: '2',
  'Black or African American': '3',
  'Hispanic or Latino': '4',
  White: '5',
  'I prefer not to identify': '6',
};

export const PatientField = {
  'First Name': 1,
  'Middle Name': 2,
  'Last Name': 3,
  'Date of Birth': 4,
  Email: 5,
  Phone: 6,
  'Street Address': 7,
  'Apt/House #': 8,
  City: 9,
  State: 10,
  Zip: 11,
  Gender: 12,
  Weight: 13,
  Height: 14,
  'Marital Status': 15,
  Occupation: 16,
  'Payor Status': 17,
  Race: 18,
  'Disease State': 19,
  'Psychiatric Status': 20,
  'Surgical History': 21,
  'Nicotine Status': 22,
  'Current Law Suit': 23,
  'Substance Abuse History': 24,
};

export const PatientAppointmentField = {
  'Appointment Date': 1,
  'Appointment Type': 2,
  'Patient First Name': 3,
  'Patient Middle Name': 4,
  'Patient Last Name': 5,
  Room: 6,
  Status: 7,
  'Pain Score': 8,
  'Body Location': 9,
  'Interventional Location': 10,
  'Interventional Direction': 11,
  'Form Scores': 12,
};

export const PatientFieldCategorized = {
  demographics: PatientField,
  appointments: PatientAppointmentField,
};

export const Configuration = {
  'Default Email Sender': 1,
  'Default Sms Number': 2,
  'Practice Name': 3,
  'Clinic Name': 4,
  'Default State': 5,
  'Enable Sms': 6,
  'Enable Email': 7,
  'Default Rand Form': 8,
  'Applicable Forms': 9,
  'Time Zone': 11,
  'Pro Rate': 12,
  'Rpm Rate': 13,
  'Clinic Logo Primary File Name': 14,
  'Practice Logo File Name': 15,
  'Twilio Channel Id': 16,
  'Clinic Logo Secondary File Name': 17,
  'Enable Patient Self Checkin': 18,
  'Practice New Patient Agreement File Name': 19,
  'Use Athena': 20,
  'Athena Practice Id': 21,
  'Athena Department Ids': 22,
  'Extended Patient Ask Sexual Orientation': 23,
  'Extended Patient Ask Assigned Sex At Birth': 24,
  'Extended Patient Ask Preferred Pronouns': 25,
  'Use Rpm': 26,
  'Athena Provider Ids': 27,
  'Extended Patient Ask Communication Preferences': 28,
  'Show Bmi Filter On Sandbox': 29,
  'Patient Appointment Reason Additional Question Id': 30,
  'Patient Appointment Reason New Patient Additional Question Option Id': 31,
  'Additional Question Ids to only show for New Patient Patient Appointment Reason': 32,
  'Intake Form Notification Hours': 33,
  'Show Intake Form For New Patients': 34,
  'Patient Appointment Reason Follow Up Additional Question Option Id': 35,
  'Enable Payment Collection': 37,
  'Show Opt in to Consent Page': 36,
  'Enable Demo Dashboard': 38,
  'Demo Patient Id': 39,
  'Demo Patient Appointment Id': 40,
  'Demo Patient External Id': 41,
  'Enable Educational Videos Page': 42,
  'Therapist Provider Ids': 43,
  'Practice New Patient Agreement File Name Spanish': 44,
  'Enable UDS status Reminder': 45,
  'Orthopedic Provider Ids': 46,
  'Use Reactiv': 47,
  'Reactiv Diagnosis Codes': 48,
  'Reactiv Override Date': 49,
  'Reactiv Email Recipients': 50,
  'Reactiv Providers': 51,
  'Clinic Address': 52,
  'Enable Home Checkin': 53,
  'Clinic Email': 54,
  'Enable Procedure Note': 55,
  'Enable Shot Consent': 56,
  'Therapy Manager Number': 57,
  'Enable Welcome Screen': 58,
  'Pharmacy Required': 59,
  'Hide Exit Pro': 60,
  'Appointment Cancel Emails': 61,
  'Appointment Cancel Email Body': 62,
  'Deployment Date': 63,
  'Skip Payment Departments': 64,
  'Use ModMed': 65,
  'ModMed Access Token Url': 66,
  'ModMed Resource Url': 67,
  'ModMed Username': 68,
  'ModMed Password': 69,
  'ModMed Api Key': 70,
  'Consent Expiration': 71,
  'Health History Reconsiliation': 72,
  'ModMed Doc Mapping Default': 73,
  'ModMed Doc Mapping Patient Agreement': 74,
  'Use Lucid Lane': 75,
  'Order Type Id': 76,
  'Lucid Lane Notification Email': 77,
  'Upside AppointmentId': 78,
  'ModMed Doc Mapping OnePager': 79,
  'ModMed Doc Mapping Screener': 80,
  'Use DrChrono': 81,
  'DrChrono Client Id': 82,
  'DrChrono Client Secret': 83,
  'DrChrono Redirect Url': 84,
  'DrChrono Webhook Secret': 86,
  'Custom Form Submission Emails': 87,
  'Checkin Confirmation Page': 88,
  'Enable Provider Appointment Dashboard': 89,
  'Default Pain Score': 90,
  'Clinic Phone Number': 91,
  'Use Mainstay': 92,
  'Use Nevro': 93,
  'Use AdvancedMD': 94,
  'AdvancedMD Username': 95,
  'AdvancedMD Password': 96,
  'AdvancedMD Office Code': 97,
  'AdvancedMD App Name': 98,
  'AdvancedMD Initial Url': 99,
  'AdvancedMD Token Details': 100,
  'Nevro Salesforce Api Version': 101,
  'Nevro Salesforce Client Id': 102,
  'Nevro Salesforce Client Secret': 103,
  'Nevro Salesforce Token Url': 104,
  'Nevro Salesforce Introspect Url': 105,
  'Nevro Salesforce Token Details': 106,
  'New Appointment Dashboard': 107,
  'Enable Digital Shot Consent': 108,
  'Clinic NPI': 109,
  'Clinic PTAN': 110,
  'Clinic Tax ID': 111,
  'Clinic City': 112,
  'Clinic State': 113,
  'Clinic Zip Code': 114,
  'Clinic Appartment': 115,
  'Use Greenway': 116,
  'Greenway Api Key': 117,
  'Greenway Practice Id': 118,
  'Greenway Service Center Ids': 119,
  'Greenway Provider Ids': 120,
  'Greenway Username': 121,
  'Greenway Password': 122,
  'Greenway License Id': 123,
  'Greenway User Logon': 124,
  'Greenway Application Name': 125,
  'Greenway Machine Name': 126,
  'Greenway Doc Mapping Default': 127,
  'Greenway Doc Mapping Patient Agreement': 128,
  'Clinic City': 112,
  'Clinic State': 113,
  'Clinic Zip Code': 114,
  'Clinic Appartment': 115,
  'Use Greenway': 116,
  'Greenway Api Key': 117,
  'Greenway Practice Id': 118,
  'Greenway Service Center Ids': 119,
  'Greenway Provider Ids': 120,
  'Greenway Username': 121,
  'Greenway Password': 122,
  'Greenway License Id': 123,
  'Greenway User Logon': 124,
  'Greenway Application Name': 125,
  'Greenway Machine Name': 126,
  'Greenway Doc Mapping Default': 127,
  'Greenway Doc Mapping Patient Agreement': 128,
  'Custom Question Department Ids': 129,
  'Auto Logout Time': 130,
  'Use Code Pull': 131,
  'Greenway Doc Mapping OnePager': 132,
  'Enable Portal Pro': 133,
  'Greenway Doc Mapping Screener': 134,
  'Greenway Session Details': 135,
  'Enable MRN Lookup': 136,
  'Enable Psyc Eval': 137,
  'Send Scheduling Data To Salesforce': 138,
  'Campaigns Emails': 139,
  'Mapped Algos Clinic Instance Id': 140,
  'Use EClinicalWorks': 141,
  'EClinicalWorks Client Id': 142,
  'EClinicalWorks Client Secret': 143,
  'EClinicalWorks Resource Url': 144,
  'EClinicalWorks Redirect Url': 145,
  'EClinicalWorks Code Verifier': 146,
  'EClinicalWorks Code Challenge': 147,
  'EClinicalWorks Token Details': 148,
  'EClinicalWorks Bulk Client Id': 149,
  'EClinicalWorks Bulk Group Id': 150,
  'EClinicalWorks Bulk Private Key File Name': 151
};

export const UserRole = {
  Admin: 1,
  Staff: 2,
  Doctor: 3,
};

export const AdditionalQuestionType = {
  DESCRIPTIVE: 1,
  MULTIPLE_CHOICE_SINGLE_ALLOWED: 2,
  LIKERT_SCALE: 3,
  MULTIPLE_CHOICE_MULTIPLE_ALLOWED: 4,
  PHONE_NUMBER: 5,
  ZIP_CODE: 6,
  TAX_ID: 7,
  EMAIL: 8,
  STATES: 9,
  DROP_DOWN_WITH_OPTION: 10,
  DATE: 11,
  TIME_ZONE: 12,
  INFO: 13,
  PATIENT_PHONENUMBER_TYPE: 14,
  COUNTRIES: 15,
  CHECKBOX: 16,
  NUMBER: 17,
  SIGNATURE: 18,
};

export const CustomFieldType = {
  OCCUPATION_STATUS: 1,
  MARITAL_STATUS: 2,
  GENDER: 3,
  RACE: 4,
  APPOINTMENT_TYPE: 5,
  PAYOR_STATUS: 6,
  ETHNICITY: 7,
};

export const IntakeSection = {
  DISEASE_STATE: 6,
  PSYCHAITRIC_STATUS: 7,
  SURGICAL_HISTORY: 8,
  RACE: 9,
};

export const RelationshipToPatient = {
  Self: 1,
  Spouse: 2,
  Child: 3,
  Other: 4,
  Grandparent: 5,
  Grandchild: 6,
  'Nephew or Niece': 7,
  'Foster Child': 9,
  Ward: 10,
  'Stepson or Stepdaughter': 11,
  Employee: 12,
  Unknown: 13,
  'Handicapped Dependent': 14,
  'Sponsored Dependent': 15,
  'Significant Other': 17,
  Mother: 18,
  Father: 19,
  'Emancipated Minor': 21,
  'Organ Donor': 22,
  'Cadaver Donor': 23,
  'Injured Plaintiff': 24,
  'Child (Ins. not Financially Respons.)': 25,
  'Life Partner': 26,
  "Child (Mother's Insurance)": 27,
  "Child (Father's Insurance)": 28,
  'Child (of Mother, Ins. not Financially Respons.)': 29,
  'Child (of Father, Ins. not Financially Respons.)': 30,
  "Stepson or Stepdaughter (Stepmother's Insurance)": 31,
  "Stepson or Stepdaughter (Stepfather's Insurance)": 32,
};

export const ReferralSource = {
  Advertising: 1,
  'Primary Care Physician': 2,
  'Specialist Care Physician': 3,
  'Word of Mouth': 4,
  'Patient in the Practice': 5,
  Hospital: 6,
  'Insurance Company': 7,
  Other: 8,
};

export const AllergyReaction = {
  'Abdominal Pain': 21522001,
  Anaphylaxis: 39579001,
  Angioedema: 41291007,
  'Arthralgia (joint pain)': 57676002,
  Bradycardia: 48867003,
  'Chest Pain': 29857009,
  Confusion: 40917007,
  Cough: 49727002,
  'Decreased Blood Pressure': 12763006,
  Diarrhea: 62315008,
  Dizziness: 404640003,
  'Dry Mouth': 87715008,
  Edema: 267038008,
  Encephalitis: 45170000,
  'Eye Redness': 75705005,
  'Eye Swelling': 45177002,
  'Facial Swelling': 278528006,
  Fever: 386661006,
  Flushing: 403618004,
  'GI bleed': 74474003,
  'Hair Loss': 278040002,
  Hallucinations: 7011001,
  Headache: 25064002,
  Hives: 126485001,
  Insomnia: 193462001,
  'Irregular heart rate': 361138002,
  Itching: 41829006,
  Lightheadedness: 386705008,
  'Muscle cramps': 55300003,
  'Myalgias (muscle pain)': 68962001,
  Nausea: 422587007,
  Other: 74964004,
  Palpitations: 80313002,
  Photosensitivity: 90128006,
  Rash: 271807003,
  'Respiratory distress': 271825005,
  'Ringing in ears': 60862001,
  Seizure: 91175000,
  'Stevens-Johnson Syndrome': 73442001,
  Swelling: 65124004,
  Tachycardia: 3424008,
  Vasculities: 31996006,
  Vomiting: 422400008,
  Wheezing: 56018004,
};

export const AllergySeverity = {
  Mild: 255604002,
  'Mild to moderate': 371923003,
  Moderate: 6736007,
  'Moderate to severe': 371924009,
  Severe: 2448400,
  Fatal: 399166001,
};

export const TobaccoIntakeMethod = {
  Ciggarettes: 1,
  Chew: 2,
  Cigars: 3,
};
export const AlcoholIntake = {
  None: 1,
  Occassional: 2,
  Moderate: 3,
  Heavy: 4,
};

export const CaffeineIntake = {
  None: 1,
  Occassional: 2,
  Moderate: 3,
  Heavy: 4,
};

export const SexualOrientation = {
  'Lesbian, gay or homosexual': 1,
  'Straight or heterosexual': 2,
  Bisexual: 3,
  'Something else': 4,
  "Don't know": 5,
  'Choose not to disclose': 6,
};

export const AssignedSexAtBirth = {
  Male: 1,
  Female: 2,
  'Choose not to disclose': 3,
  Unknown: 4,
};

export const PreferredPronoun = {
  'he/him': 1,
  'she/her': 2,
  'they/them': 3,
};

export const ExtendedDiseaseState = {
  Allergies: 1,
  Anemia: 2,
  Anxiety: 3,
  Arthritis: 4,
  Asthma: 5,
  'Autoimmunie Disease': 6,
  'Back/Neck Pain': 7,
  'Blood Disorder': 8,
  'Bowel Disease': 9,
  CAD: 10,
  CHF: 11,
  COPD: 12,
  Cancer: 13,
  Dementia: 14,
  Developmental: 15,
  Depression: 16,
  'Diabetes Melitus Type 1': 17,
  'Diabetes Melitus Type 2': 18,
  'Diabetic Complications': 19,
  'Endocrine Disease': 20,
  'Eye Problems': 21,
  'Gastritis/Ulcer': 22,
  'GERD/Acid Reflux': 23,
  'Headaches/Migrains': 24,
  'Hearing Loss': 25,
  'Heart Rhythm Disorder': 26,
  'Heart Disease': 27,
  Hypertension: 28,
  Hyperlipidemia: 29,
  'Kidney Disease/Stones': 30,
  Liver: 31,
  'Lung Disease': 32,
  'Mental Illness': 33,
  'Movement Disorder': 34,
  'Nerve Disease': 35,
  'Osteopenia/Osteoporosis': 36,
  'Overweight/Obesity': 37,
  Pneumonia: 38,
  'Prostate Disorder': 39,
  'Spine Disease': 40,
  'Stroke/TIA': 41,
  'Thyroid Disease': 42,
  'Tuberculosis/Pos PPD': 43,
  'Urinary Problems': 44,
  'Viral Disease': 45,
  Other: 46,
};

export const EducationLevel = {
  'less than 8th grade': 1,
  'High School': 2,
  '2 year college': 3,
  '4 year college': 4,
  'Post Graduate': 5,
  Other: 6,
};

export const ContactRelationship = {
  Child: 1,
  Cousin: 2,
  Friend: 3,
  Guardian: 4,
  Other: 5,
  Parent: 6,
  Sibling: 7,
  Spouse: 8,
};

export const FamilyHistoryRelationship = {
  Spouse: 16,
  Mother: 1,
  Father: 2,
  Brother: 3,
  Sister: 4,
  Son: 5,
  Daughter: 6,
  'Maternal Grandmother': 7,
  'Maternal Grandfather': 8,
  'Paternal Grandmother': 9,
  'Paternal Grandfather': 10,
  'Maternal Aunt': 11,
  'Maternal Uncle': 12,
  'Paternal Aunt': 13,
  'Paternal Uncle': 14,
  'Unspecified Relation': 15,
};

export const AthenaInputType = {
  DROPDOWN: 1,
  NUMERIC: 2,
  FREETEXT: 3,
  YESNO: 4,
  DATE: 5,
};

export const HealthHistoryInputType = {
  DROPDOWN: 'DROPDOWN',
  NUMERIC: 'NUMERIC',
  FREETEXT: 'FREETEXT',
  YESNO: 'YESNO',
  DATE: 'DATE',
  CHECKBOX: 'CHECKBOX',
};

export const HealthHistorySection = {
  MEDICAL: 'MEDICAL',
  SURGICAL: 'SURGICAL',
  VACCINATION: 'VACCINATION',
  SOCIAL: 'SOCIAL',
  OBGYN: 'OBGYN',
  FAMILY: 'FAMILY',
  ALLERGY: 'ALLERGY',
  MEDICATION: 'MEDICATION',
};

export const PhiDisclosureInformationType = {
  'Appointment Reminders': 1,
  'Results (lab test, X-Ray, etc)': 2,
  Financial: 3,
};

export const PhiDisclosureCommunicationType = {
  Telephone: 1,
  'Voice Mail': 2,
  'Patient Portal & Secure Email': 3,
};

export const InterventionalLocationType = {
  CIRCLE: 1,
  HEART: 2,
  SQUARE: 3,
  TRIANGLE: 4,
  CROSS: 5,
};

export const PerioperativeMessageDetailApplicableTo = {
  ALL: 1,
  NEW: 2,
  RETURNING: 3,
};

export const PerioperativeMessageDetailTrigger = {
  APPOINTMENT_DATE: 1,
  BIRTH_DATE: 2,
  VAS_IMPROVEMENT: 3,
  PRO_IMPROVEMENT: 4,
};

export const PatientPhoneNumberType = {
  MOBILE: 7,
  HOME: 1,
  WORK: 2,
  EMERGENCY_CONTACT_HOME: 3,
  EMERGENCY_CONTACT_MOBILE: 4,
  GUARANTOR: 5,
  NEXT_KIN: 6,
};

export const AdaInterventionalLocationLaterality = {
  FRONT_LEFT: 1,
  FRONT_RIGHT: 2,
  BACK_LEFT: 3,
  BACK_RIGHT: 4,
};

export const PatientAppointmentPainScoreType = {
  IN_CLINIC: 1,
  PAIN_JOURNAL: 2,
};

export const ReportSummaryDataType = {
  PRO: 1,
  PAIN_SCORE: 2,
};

export const OrthopedicEnum = {
  NECK: 1,
  SHOULDER: 2,
  ELBOW: 3,
  WRIST: 4,
  HAND: 5,
  BACK: 6,
  HIP: 7,
  KNEE: 8,
  ANKLE: 9,
  FOOT: 10,
};

export const LateralityEnum = {
  LEFT: 1,
  RIGHT: 2,
};

export const CustomFormType = {
  Survery_Form: 1,
  Reactiv8_Form: 2,
};

export const CommunicationCohort = {
  'All Patients': 1,
  'All Patients With Appointments on': 2,
  'All Patients With Appointments at': 3,
  'All Patients With Appointments at ': 4,
};

export const CommunicationPreference = {
  Text: 1,
  Email: 2,
  ManualSignature: 3,
};

export const ProgressBar = {
  New_Patient_Paperwork: 1,
  Health_History_Forms: 2,
  Check_In_Questions: 3,
  VAS_Scores: 4,
  Body_Map: 5,
  PRO: 6,
  Payment: 7,
  Completed: 8,
  // Signed_In: 1,
  // Completed: 2,
};

export const FirebaseAppointmentStatus = {
  Add: 1,
  Delete: 2,
  Update: 3,
};

export const AppointmentGridStatus = {
  Checking_In: 1,
  Upcoming: 2,
  Completed: 3,
  No_Show: 4,
};

export const AppointmentHoverStatusEnums = {
  PatientData: 1,
  PaymentData: 2,
  HomeCheckIn: 3,
  DigitalConsent: 4,
  Pathways: 5,
  AppointmentNotes: 6,
  DelayedCheckIn: 7,
  RefreshPayment: 8,
};

export const DocumentType = {
  ENCOUNTER: 1,
  INSURANCE: 2,
  SURGICAL_CANDIDANCY: 3,
  PSYCH_EVAL: 4,
  MANUAL_CONSENT_DOCUMENT: 5,
  OTHER: 6,
};

export const CodePullAppointmentDuration = {
  'Not seen in greater than 3 months': 1,
  'Not seen in greater than 6 months': 2,
  'Not seen in greater than 9 months': 3,
  'Not seen in greater than 12 months': 4,
  'Custom Timeframe': 5,
};

export const AppointmentLookbackLimit = {
  // Within_Three_Months: 1,
  // Within_Six_Months: 2,
  // Within_Twelve_Months: 3,
  All_Time: 4,
  Custom_Date: 5,
};

export const CodePullOperatorEnum = {
  'All of These Codes': 1,
  'Any of These Codes': 2,
};

export const CodePullTemplateType = {
  Default: 1,
  Custom: 2,
};

export const CodePullInsuranceList = {
  'All Insurances': 1,
  'Commercial Payors': 2,
  'Medicare / Medicaid': 3,
};

export const TextPerWeekOptions = {
  10: 1,
  20: 2,
  // ALL: 3,
};

export const ClinicUserTypeEnum = {
  Doctor: 1,
  'Nurse Practitioner': 2,
  'Physician Assistant': 3,
};

export const PerioperativeMessageTemplateEnum = {
  Active: 1,
  Inactive: 2,
};

export const PerioperativeMessageDetailEnum = {
  Active: 1,
  Inactive: 2,
};

export const CampaignReportDropdownEnum = {
  // Monthly: 1,
  // Quarterly: 2,
  All_Time: 3,
  Custom_Dates: 4,
};