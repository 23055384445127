import {ACTION_TYPES} from '../../actions/reports/patientsSpotlight';

const initialState = {
  list: {data: []},
  filter: {
    improvementFactor: 1,
    referringProviderIds: [],
    patientFirstName: '',
    patientLastName: '',
    doctorId: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PATIENTS_SPOTLIGHT:
      return {...state, list: action.payload};
    case ACTION_TYPES.SET_FILTER_IMPROVEMENT_FACTOR:
      return {
        ...state,
        filter: {...state.filter, improvementFactor: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PATIENT_FIRST_NAME:
      return {
        ...state,
        filter: {...state.filter, patientFirstName: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PATIENT_LAST_NAME:
      return {
        ...state,
        filter: {...state.filter, patientLastName: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_REFERRING_PROVIDER_IDS:
      return {
        ...state,
        filter: {...state.filter, referringProviderIds: action.payload},
      };
    case ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      };
    default:
      return state;
  }
};

export const selectFilter = (state) => state.patientsSpotlight.filter;

export const selectPatientsSpotlightList = (state) =>
  state.patientsSpotlight.list;

export const selectFilterImprovementFactor = (state) =>
  selectFilter(state).improvementFactor;

export const selectFilterReferringProviderIds = (state) =>
  selectFilter(state).referringProviderIds;

export const selectFilterPatientFirstName = (state) =>
  selectFilter(state).patientFirstName;

export const selectFilterPatientLastName = (state) =>
  selectFilter(state).patientLastName;
