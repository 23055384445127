//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//JWT_AUTH
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_USER_DATA = 'set_user_data';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const SET_TOKEN_SET = 'set_token_set';
export const SET_USER_ROLE = 'set_user_role';
export const CHANGE_PASSWORD = 'change_password';
export const SET_PASSWORD = 'set_password';
export const FORGET_PASSWORD = 'forget_password';
export const SET_IS_PARENT = 'set_is_parent';
export const SET_IS_CHILD = 'set_is_child';

//FIREBASE_AUTH
export const UPDATE_FIREBASE_USER = 'update_firebase_user';

//AWS_COGNITO_AUTH
export const UPDATE_COGNITO_USER = 'update_cognito_user';

//AUTH0
export const UPDATE_AUTH0_USER = 'update_auth0_user';
export const SET_AUTH0_TOKEN_SET = 'set_auth0_token_set';
export const SIGNOUT_AUTH0_SUCCESS = 'signout_auth0_success';

//ADMIN
export const GET_MEDICINES_DATA = 'get_medicines_data';

export const QUIZ_ACTION_TYPES = {
  QuizLoad: 'QuizLoad',
  QuizAnswer: 'QuizAnswer',
  QuizSubmit: 'QuizSubmit',
  PagerUpdate: 'PagerUpdate',
};

//PATIENT
export const GET_DASHBOARD_DATA = 'get_dashboard_data';

export const SET_USE_ACCESSIBILITY = 'set_common_use_accessibility';
