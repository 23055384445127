import {SET_DOCTOR_ID} from '../actions/doctor';

const initialState = {
  id: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCTOR_ID:
      return {...state, id: action.payload.id};
    default:
      return state;
  }
};

export const selectDoctorId = (state) => state.doctor.id;
