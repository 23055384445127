import {lazy} from 'react';

export const adminPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/admin/dashboard',
        component: lazy(() => import('./dashboard')),
      },
      {
        path: '/admin/bodylocations',
        component: lazy(() => import('./BodyLocations')),
      },
      {
        path: '/admin/medicines',
        component: lazy(() => import('./Medicines')),
      },
      {
        path: '/admin/studygroups',
        component: lazy(() => import('./StudyGroups')),
      },
      {
        path: '/admin/rooms',
        component: lazy(() => import('./Rooms')),
      },
      {
        path: '/admin/interventionalitems/:id',
        component: lazy(() => import('./InterventionalItems')),
      },
      {
        path: '/admin/interventionaldirections',
        component: lazy(() => import('./InterventionalDirections')),
      },
      {
        path: '/admin/interventionallocations',
        component: lazy(() => import('./InterventionalLocations')),
      },
      {
        path: '/admin/adainterventionallocations',
        component: lazy(() => import('./AdaInterventionalLocations')),
      },
      {
        path: '/admin/instancesettings',
        component: lazy(() => import('./InstanceSettings')),
      },
      {
        path: '/admin/referrals/',
        component: lazy(() => import('./Referrals')),
      },
      {
        path: '/admin/resourcetypes/',
        component: lazy(() => import('./ResourceTypes')),
      },
      {
        path: '/admin/painpumpmedicines/',
        component: lazy(() => import('./PainPumpMedicines')),
      },
      {
        path: '/pathways/',
        component: lazy(() => import('./Pathways/Reminder')),
      },
      {
        path: '/pathwaysresponse/:id',
        component: lazy(() => import('./Pathways/Reminder/Messages/Response')),
      },
      {
        path: '/communicationdashboard/',
        component: lazy(() => import('./CommunicationDashboard')),
      },
    ],
  },
];
