import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';
import {selectCurrentUserId} from '../../reducers/user-messages/user-messages';

export const ACTION_TYPES = {
  FETCH_USERS: 'FETCH_USER_MESSAGES_USERS',
  FETCH_FILTERED_MESSAGES: 'FETCH_FILTERED_USER_MESSAGES_MESSAGES',
  SET_CURRENT_USER_ID: 'SET_USER_MESSAGES_CURRENT_USER_ID',
};

export const fetchUsers = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .userMessages()
    .fetchUsers()
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_USERS, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchFilteredMessages = () => (dispatch, getState) => {
  dispatch(fetchStart());
  api
    .userMessages()
    .fetchByUser(getState().userMessages.data.currentUserId)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_FILTERED_MESSAGES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setSeenByUserId = (userId) => (dispatch, getState) => {
  dispatch(fetchStart());
  api
    .userMessages()
    .setSeenByUserId({
      fromAlgosUserId: userId || selectCurrentUserId(getState()),
    })
    .then(() => {
      dispatch(fetchSuccess());
    });
};

export const setCurrentUserId = (newUserId) => (dispatch) => {
  dispatch({type: ACTION_TYPES.SET_CURRENT_USER_ID, payload: newUserId});
};
