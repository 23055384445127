import {
  selectFilterDateFilter,
  selectFilterStartDateFilter,
  selectFilterEndDateFilter,
} from 'redux/reducers/reports/patientsPerformanceMeasure';
import {DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'shared/constants/AppConst';
import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_PATIENTS_PERFORMANCE_MEASURE:
    'FETCH_PATIENTS_PERFORMANCE_MEASURE_PATIENTS_PERFORMANCE_MEASURE',
  SET_FILTER_DATE_FILTER: 'SET_PATIENTS_PERFORMANCE_MEASURE_FILTER_DATE_FILTER',
  SET_FILTER_APPOINTMENT_START_DATE:
    'SET_PATIENTS_PERFORMANCE_MEASURE_FILTER_START_DATE',
  SET_FILTER_APPOINTMENT_END_DATE:
    'SET_PATIENTS_PERFORMANCE_MEASURE_FILTER_END_DATE',
  RESET_FILTER: 'RESET_PATIENTS_PERFORMANCE_MEASURE_FILTER',
};

export const fetchPatientsPerformanceMeasure = (
  pageSize = DEFAULT_PAGINATION_ROWS_PER_PAGE,
  pageNumber = 0,
) => (dispatch, getState) => {
  dispatch(fetchStart());
  const currentReduxState = getState();
  api
    .reports()
    .patientsPerformanceMeasure(
      selectFilterDateFilter(currentReduxState),
      selectFilterStartDateFilter(currentReduxState),
      selectFilterEndDateFilter(currentReduxState),
    )
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_PATIENTS_PERFORMANCE_MEASURE,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setFilterDateFilter = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_DATE_FILTER,
    payload: newValue,
  });
};

export const setFilterStartDate = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_APPOINTMENT_START_DATE,
    payload: newValue,
  });
};

export const setFilterEndDate = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_APPOINTMENT_END_DATE,
    payload: newValue,
  });
};

export const resetFilter = () => (dispatch) => {
  dispatch({type: ACTION_TYPES.RESET_FILTER});
  dispatch(fetchPatientsPerformanceMeasure());
};
