import React, {useEffect, useRef} from 'react';

const SwalAccessbleDialogNode = ({title, text}) => {
  const focusRef = useRef(null);

  useEffect(() => {
    focusRef.current.focus();
  }, []);

  return (
    <div>
      <h1 className='swal-title'>{title}</h1>
      <p id='swal-description' className='swal-text'>
        {text}
      </p>
      <button ref={focusRef} className='hidden-focusable'>
        {title} {text} Press tab to move to action buttons.
      </button>
    </div>
  );
};

export default SwalAccessbleDialogNode;
