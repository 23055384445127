import jwtAxios from '@e74/services/auth/index';
import {  DEFAULT_PAGINATION_ROWS_PER_PAGE  } from 'shared/constants/AppConst';

export default {
  medicine(url = 'medicines/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  medicinesubtype(url = 'medicinesubtypes/') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  additionalQuestions(url = 'additionalquestions/') {
    return {
      fetchAll: (appointmentId, newPatient = false) =>
        jwtAxios.get(
          url + `?appointmentid=${appointmentId}&newpatient=${newPatient}`,
        ),
      fetchPreviousAppointmentInquiryQuestion: (patientId) =>
        jwtAxios.get(url + 'previousappointmentinquiryquestion', {
          params: { patientId },
        }),
    };
  },
  additionalQuestionResponses(url = 'additionalQuestionResponses/') {
    return {
      create: (newRecord) => jwtAxios.post(url, newRecord),
      createMultiple: (newRecords) =>
        jwtAxios.post(url + 'multiple', newRecords),
      delete: (id) => jwtAxios.delete(url + id),
      submitCustomFormResponse: (dto) =>
        jwtAxios.post(`${url}submitCustomFormResponse`, dto),
      submitVerificationResponse: (dto) =>
        jwtAxios.post(`${url}submitVerificationResponse`, dto),
      getResponseForPatient: (patientId) =>
        jwtAxios.get(`${url}getresponseforpatient/${patientId}`),
      generateReactivForms: (id, formId) =>
        jwtAxios.post(
          `${url}generatereactivforms/${id}?customformid=${formId}`,
        ),
      checkIfResponseExist: (formId, appointmentId, patientId, providerId) =>
        jwtAxios.get(
          `${url}checkresponsemade?customFormId=${formId}${appointmentId !== null ? `&appointmentId=${appointmentId}` : ''
          }${patientId != null ? `&patientId=${patientId}` : ''}${providerId !== null ? `&providerId=${providerId}` : ''
          }`,
        ),
      saveFacilityAndContact: (dto) =>
        jwtAxios.post(`${url}savefacilityandcontact`, dto),
      generatePatientReactivForms: (patientId) =>
        jwtAxios.post(`${url}generatepatientreactivforms/${patientId}`),
    };
  },
  perioperativemessagedetails(url = 'PerioperativeMessageDetails/') {
    return {
      fetchMessageTemplates: () => jwtAxios.get(url + 'getmessagetemplates'),
      fetchByTemplateId: (id, doctorId = null) =>
        jwtAxios.get(url + id, { params: { doctorId } }),
      create: (newRecord) => jwtAxios.post(url + 'createMessage', newRecord),
      update: (updateRecord) => jwtAxios.put(url, updateRecord),
      delete: (id) => {
        return jwtAxios.delete(url + id);
      },
      send: (messageSendRequest) =>
        jwtAxios.post(url + 'send', messageSendRequest),
      duplicate: (messageDuplicateRequest) =>
        jwtAxios.post(url + 'duplicate', messageDuplicateRequest),
      messageResponse: (filter) =>
        jwtAxios.post(url + 'messageresponse', filter),
      exportToCSV: (filter) =>
        jwtAxios.post(url + 'exporttocsv', filter, { responseType: 'blob' }),
      sendAppointmentPathway: (dto) =>
        jwtAxios.post(url + 'sendappointmentpathway', dto),
      getById: (id) => jwtAxios.get(url + `getbyid/${id}`),
      getMessageDetailsForManualSend: (id) =>
        jwtAxios.get(url + `getmessagedetailsformanualsend/${id}`),
      GetCampaignPathwayById: (id) =>
        jwtAxios.get(url + `getcampaignpathwaybyid/${id}`),
      updateIsCalled: (id, isCalled) =>
        jwtAxios.post(url + 'updateIsCalled/' + id),
      updateIsScheduled: (id, isScheduled) =>
        jwtAxios.post(url + 'updateIsScheduled/' + id),
    };
  },
  patientAppointmentCodes(url = 'PatientAppointmentCodes/') {
    return {
      upsert: (patientAppointmentId, newRecord) =>
        jwtAxios.post(url + patientAppointmentId, newRecord),
      fetchAll: (patientAppointmentId) =>
        jwtAxios.get(url, {
          params: { patientAppointmentId },
        }),
      getallcodesforcodepull: () =>
        jwtAxios.get(url + 'getallcodesforcodepull'),
    };
  },
  perioperativemessagetemplates(url = 'PerioperativeMessageTemplates/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => {
        return jwtAxios.delete(url + id);
      },
      getexternaltriggertemplates: () =>
        jwtAxios.get(url + 'getexternaltriggertemplates'),
    };
  },
  bodylocation(url = 'bodylocations/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  studygroup(url = 'studygroups/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  room(url = 'rooms/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  interventionalitem(url = 'interventionalitems/') {
    return {
      fetchAll: (itemType) => jwtAxios.get(url + 'getall/' + itemType),
      fetchById: (id) => jwtAxios.get(url + 'getbyid/' + id),
      create: (newRecord) => jwtAxios.post(url + 'insert', newRecord),
      update: (id, updateRecord) =>
        jwtAxios.put(url + 'update/' + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + 'delete/' + id),
    };
  },
  interventionallocation(url = 'interventionallocations/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  interventionaldirection(url = 'interventionaldirections/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  referral(url = 'referrals/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  painpumpmedicine(url = 'painpumpmedicines/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  patient(url = 'patients/') {
    return {
      fetchAll: (pageSize, pageNumber) =>
        jwtAxios.get(
          url + 'paged?pageSize=' + pageSize + '&pageNumber=' + pageNumber,
        ),
      fetchFiltered: (filter) => jwtAxios.post(url + 'filtered', filter),
      intakeemail: (intakeinformation) =>
        jwtAxios.post(url + 'intakeemail', intakeinformation),
      intakesms: (intakeinformation) =>
        jwtAxios.post(url + 'intakesms', intakeinformation),
      search: (searchParam, pageSize, pageNumber, doctorId) =>
        jwtAxios.get(url + 'search', {
          params: { searchParam, pageSize, pageNumber, doctorId },
        }),
      verifytoken: (tokenInformation) =>
        jwtAxios.post(url + 'verifytoken', tokenInformation),
      fetchById: (id) => jwtAxios.get(url + id),
      fetchByExternalId: (id) => jwtAxios.get(url + 'externalId/' + id),
      searchExternalPatients: (searchParam) => {
        

        return jwtAxios.post(`${url}external`, searchParam);
      },
      fetchIntakeInformation: (id) => jwtAxios.get(url + id + '/intake'),
      fetchPatientIntakeInformation: (id, nevroCaseId, token) =>
        jwtAxios.get(
          url +
          'GetPatientInfoForIntake/' +
          id +
          '/' +
          nevroCaseId +
          '?token=' +
          token,
        ),
      appointmentDashboardInfo: (info) =>
        jwtAxios.post(url + 'appointmentdashboardinfo', info),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord, updateEncryptedFields = false) =>
        jwtAxios.put(url + id, updateRecord, { params: { updateEncryptedFields } }),
      updateWithDocuments: (
        id,
        nevroCaseId,
        updateRecord,
        updateEncryptedFields = false,
      ) =>
        jwtAxios.put(
          url + 'updatewithdocuments/' + id + '/' + nevroCaseId,
          updateRecord,
          {
            params: { updateEncryptedFields },
          },
        ),
      delete: (id) => jwtAxios.delete(url + id),
      updateIntakeEndDate: (newRecord) =>
        jwtAxios.post(url + 'intakeenddate', newRecord),
      updatemme: (newRecord) => jwtAxios.post(url + 'mme', newRecord),
      closeAccount: (intakeinformation) =>
        jwtAxios.post(url + 'closeaccount', intakeinformation),
      closeAccountConfirmation: (accountInformation) =>
        jwtAxios.post(url + 'closeaccountconfirmation', accountInformation),
      reportsummary: (requestInfo) =>
        jwtAxios.post(url + 'reportsummary/', requestInfo),
      interventionforminfo: (id) =>
        jwtAxios.get(url + 'interventionforminfo/' + id),
      bulkintakenotification: (info) =>
        jwtAxios.post(url + 'bulkintakenotification', info),
      closeIntakeUpdate: (info) =>
        jwtAxios.post(url + 'closeintakeupdate', info),
      checkIfPatientAlreadyExists: (patientInfo) =>
        jwtAxios.post(url + 'checkifpatientalreadyexists', patientInfo),
      patientscontrollist: (doctorId = null) =>
        jwtAxios.get(url + 'patientscontrollist', { params: { doctorId } }),
      patientControlListForTemplate: (
        templateId,
        messageDetailId,
        doctorId = null,
      ) =>
        jwtAxios.get(
          `${url}patientscontrollistfortemplate?templateId=${templateId}&messageDetailId=${messageDetailId}&doctorId=${doctorId}`,
        ),
      exportToCSV: (options) =>
        jwtAxios.post(url + 'exporttocsv', options, { responseType: 'blob' }),
      updateActiveInactive: (id) =>
        jwtAxios.post(url + 'updateactiveinactive/' + id),
      updateRpmKey: (patientId, rpmKey) =>
        jwtAxios.put(`${url}${patientId}/rpmkey`, { rpmKey }),
      triggerMobileApplicationUrlSms: (patientId) =>
        jwtAxios.get(`${url}${patientId}/trigger-mobile-application-url-sms`),
      signNewPatientAgreement: (patientId, newPatientAgreementUpdateRequest) =>
        jwtAxios.post(
          `ehr/patients/${patientId}/newpatientagreement`,
          newPatientAgreementUpdateRequest,
        ),
      createExtendedPatient: (extendedPatientCreateRequest) =>
        jwtAxios.post(
          `ehr/patients/extendedpatient`,
          extendedPatientCreateRequest,
        ),
      getExtendedPatient: (id) =>
        jwtAxios.get('ehr/patients/extendedpatient/' + id),
      getExtendedPatientDrChrono: (id) =>
        jwtAxios.get('ehr/patients/extendedpatientdrchrono/' + id),
      fetchNextAppointment: (patientId) =>
        jwtAxios.get(url + patientId + '/next-appointment'),
      fetchPainJournal: (id, painInterval) =>
        jwtAxios.get(url + id + `/painjournal?interval=${painInterval}`),
      fetchPatientsSpotlightPdf: (id) =>
        jwtAxios.get(url + id + '/patientsspotlightpdf', {
          responseType: 'blob',
        }),
      fetchEnableText: (id) => jwtAxios.get(url + id + '/enabletext'),
      fetchBasicInfoIncludingPhoneNumbers: (id) =>
        jwtAxios.get(url + id + '/basicinfoincludingphonenumbers'),
      patchPatientPhoneNumbers: (id, request, enableText = null) =>
        jwtAxios.patch(url + id + '/patientphonenumbers', request, {
          params: { enabletext: enableText },
        }),
      setHasSuccessStoryBeenShared: (id, request) =>
        jwtAxios.patch(url + id + '/hassuccessstorybeenshared', request),
      getAddress: (id) => jwtAxios.get(url + 'getaddress/' + id),
      updateAlgosSignedDate: (id) =>
        jwtAxios.patch(url + 'updatealgossigneddate/' + id),
      updateVerificationSignedDate: (id) =>
        jwtAxios.patch(url + 'updateverificationsigneddate/' + id),
      getSignedDatesForVerification: (id) =>
        jwtAxios.get(url + 'getsigneddatesforverification/' + id),
      updateConsentsSignedDate: (id, request) =>
        jwtAxios.patch(url + 'updateconsentssigneddate/' + id, request),
      updateConsentSignedDateAfterExpiration: (id) =>
        jwtAxios.patch(url + 'updateconsentsigneddateafterexpiration/' + id),
      createNevro: (newRecord) => jwtAxios.post(url + 'nevro', newRecord),
      updateNevroForDoctor: (id, nevroCaseId, updateRecord) =>
        jwtAxios.post(
          url + 'nevrointakedoctor/' + id + '/' + nevroCaseId,
          updateRecord,
        ),
      updateNevroForPatient: (id, nevroCaseId, updateRecord) =>
        jwtAxios.post(
          url + 'nevrointakepatient/' + id + '/' + nevroCaseId,
          updateRecord,
        ),
      nevroIntakeEmail: (id, nevroCaseId) =>
        jwtAxios.get(url + 'nevrointakeemail/' + id + '/' + nevroCaseId),
      sendNevroIntakeFollowupText: (id, nevroCaseId, to, sendVia = 'text') =>
        jwtAxios.get(
          url + 'sendfollowuptext/' + id + '/' + nevroCaseId + '?to=' + to,
        ),
      sendReactiv8FollowupText: (id, to, sendVia = 'text') =>
        jwtAxios.get(
          url +
          'sendreactiv8followuptext/' +
          id +
          '?to=' +
          to +
          '&sendVia=' +
          sendVia,
        ),
      fetchByNevroCaseId: (id) =>
        jwtAxios.get(url + 'getpatientbynevrocaseid/' + id),
      fetchPatientForCaseCloning: (nevroCaseId) =>
        jwtAxios.get(url + 'getpatientinfoforcasecloning/' + nevroCaseId),
      archive: (patientId, caseId, reason) =>
        jwtAxios.post(`${url}${patientId}/${caseId}?reason=${reason}`),
      unarchive: (caseId) => jwtAxios.post(url + 'unarchive/' + caseId),
      updatePatientAgreementDate:(id) => jwtAxios.patch(url+'updatepatientagreementdate/'+id),
    };
  },
  patientappointments(url = 'patientappointments/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchFiltered: (newRecord) => jwtAxios.post(url + 'filtered', newRecord),
      fetchById: (id) => jwtAxios.get(url + 'info/' + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + 'delete/' + id),
      schedule: (newRecord) => jwtAxios.post(url + 'schedule', newRecord),
      updateschedule: (newRecord) =>
        jwtAxios.post(url + 'updateschedule', newRecord),
      checkIn: (newRecord) => jwtAxios.post(url + 'checkin', newRecord),
      patientFiltered: (newRecord) =>
        jwtAxios.post(url + 'patientFiltered', newRecord),
      getUnfilledForms: (appointmentId) =>
        jwtAxios.get(url + 'getunfilledforms/' + appointmentId),
      getPatientInfo: (appointmentId) =>
        jwtAxios.get(url + 'getPatientInfo/' + appointmentId),
      exportToCSV: (options) =>
        jwtAxios.post(url + 'exporttocsv', options, { responseType: 'blob' }),
      getInjectionToBeAdministeredToday: (request) =>
        jwtAxios.post(url + 'getInjectionToBeAdministeredToday', request),
      getDetailedNotifications: (rawNotifications) =>
        jwtAxios.post(url + 'detailed-notifications', rawNotifications),
      aaos: () => jwtAxios.get(url + 'aaos', { responseType: 'blob' }),
      onePager: (id) =>
        jwtAxios.get(url + id + '/one-pager', { responseType: 'blob' }),
      assignedPro: (id) => jwtAxios.get(url + id + '/assigned-pro'),
      previousBodyMap: (id) => jwtAxios.get(url + id + '/previousbodymap'),
      exitfrompro: (dto) => jwtAxios.post(`${url}exitfrompro`, dto),
      sendcommunicationmessage: (dto) =>
        jwtAxios.post(`${url}sendcommunicationmessage`, dto),
      getAppointmentDetailsForCustomForm: (id) =>
        jwtAxios.get(`${url}getappointmentdetailsforcustomform/${id}`),
      sendPerioperativeReminders: () => {
        jwtAxios.get(`${url}send-perioperative-reminders`);
      },
      providerAppointmentReport: (dto) =>
        jwtAxios.post(`${url}providerappointmentreport`, dto),
      sendAppointmentReminderText: (patientId) =>
        jwtAxios.post(url + 'sendappointmentremindertext/' + patientId),
      recordNewPain: (appointmentId) =>
        jwtAxios.patch(url + `recordnewpain/${appointmentId}`),
      getAppointmentsForStaff: (dto) =>
        jwtAxios.post(url + 'getappointmentsforstaff', dto),
      getPaymentDetails: (appointmentId) =>
        jwtAxios.get(url + `getpaymentdetails/${appointmentId}`),
      saveAppointmentNote: (id, note) =>
        jwtAxios.post(url + `saveappointmentnote/${id}`, note),
      getappointmentnote: (id) =>
        jwtAxios.get(url + `getappointmentnote/${id}`),
      delayedCheckIn: (id) => jwtAxios.post(url + `delayedcheckin/${id}`),
      getAppointmentStatus: (dto) =>
        jwtAxios.post(url + 'getappointmentstatus', dto),
      exportNoShowAppointments: (dto) =>
        jwtAxios.post(url + `exportnoshowappointments`, dto, {
          responseType: 'blob',
        }),
      getFirstAppointmentIdForPatient: (patientId) => jwtAxios.get(url + `getfirstappointmentidforpatient/${patientId}`),
    };
  },
  patientappointmentprocedures(url = 'patientappointmentprocedures/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      fetchByPatientId: (id) => jwtAxios.get(url + 'patient/' + id),
      fetchByAppointmentId: (id) => jwtAxios.get(url + 'appointment/' + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
      upsert: (newRecord) => jwtAxios.post(url + 'upsert', newRecord),
      compliancecheck: (id, appointmentId) =>
        jwtAxios.get(
          url + 'compliancecheck/' + id + '/?appointmentId=' + appointmentId,
        ),
      complianceupdate: (id, updateRecord) =>
        jwtAxios.put(url + 'complianceupdate', updateRecord),
      complianceresult: (id) => jwtAxios.get(url + 'complianceresult/' + id),
      aaos: () => jwtAxios.get(url + 'aaos', { responseType: 'blob' }),
    };
  },
  paymentLogs(url = 'paymentlogs/') {
    return {
      getDaySheet: (date, departmentId) =>
        jwtAxios.get(
          url +
          `getdaysheet?date=${date}${departmentId === '' ? '' : `&departmentId=${departmentId}`
          }`,
        ),
      getPaymentHistory: (appointmentId) =>
        jwtAxios.get(url + `getpaymenthistory/${appointmentId}`),
      exportFile: (exportType) =>
        jwtAxios.post(url + 'exportfile', exportType, { responseType: 'blob' }),
    };
  },
  gender(url = 'enums?type=gender') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  maritalStatus(url = 'enums?type=maritalStatus') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  occupation(url = 'enums?type=occupation') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  nicotineStatus(url = 'enums?type=nicotineStatus') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  diseaseState(url = 'enums?type=diseaseState') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  psychiatricStatus(url = 'enums?type=psychiatricStatus') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  surgicalHistory(url = 'enums?type=surgicalHistory') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  payorStatus(url = 'enums?type=payorStatus') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  yesNo(url = 'enums?type=yesNo') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  patientAppointmentsDateFilter(
    url = 'enums?type=patientAppointmentsDateFilter',
  ) {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  patientAppointmentsStatus(url = 'enums?type=patientAppointmentsStatus') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  defaultPainScores(url = 'defaultPainScores') {
    return {
      fethcAll: () => jwtAxios.get(url),
    };
  },
  timeZones(url = 'timeZones') {
    return {
      fetchAll: () => jwtAxios.get(url),
    };
  },
  patientFormSubmission(url = 'patientformsubmissions/') {
    return {
      create: (newRecord) => jwtAxios.post(url + 'create', newRecord),
      fetchById: (id) => jwtAxios.get(url + 'result/' + id),
      aaos: () => jwtAxios.get(url + 'aaos', { responseType: 'blob' }),
    };
  },
  patientdashboard(url = 'patients/dashboard') {
    return {
      fetchAll: (doctorId) => jwtAxios.get(url, { params: { doctorId: doctorId } }),
    };
  },
  patientStudyGroups(url = 'patientstudygroups/') {
    return {
      update: (newRecord) => jwtAxios.post(url + 'studygroup', newRecord),
      fetchByPatient: (id) => jwtAxios.get(url + 'studygroups/' + id),
      fetchListByPatient: (id) => jwtAxios.get(url + 'studygroupslist/' + id),
      fetchById: (id) => jwtAxios.get(url + id),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  reports(url = 'reports/') {
    return {
      summary: (requestInfo) => jwtAxios.post(url + 'summary', requestInfo),
      patientsSpotlight: (
        improvementFactor,
        referringProviderIds = '',
        patientFirstName = '',
        patientLastName = '',
        pageSize = DEFAULT_PAGINATION_ROWS_PER_PAGE,
        pageNumber = 0,
        doctorId = null,
      ) =>
        jwtAxios.get(url + 'patientsSpotlight', {
          params: {
            improvementfactor: improvementFactor,
            referringproviderids: referringProviderIds.join(','),
            patientfirstname: patientFirstName,
            patientlastname: patientLastName,
            pagesize: pageSize,
            pagenumber: pageNumber,
            doctorid: doctorId,
          },
        }),
      surveyReports: (filters) => jwtAxios.post(`${url}surveyreport`, filters),
      patientsPerformanceMeasure: (
        dateFilter = 1,
        startDate = null,
        endDate = null,
      ) =>
        jwtAxios.get(url + 'patientsPerformanceMeasure', {
          params: {
            datefilter: dateFilter,
            startdate: startDate,
            enddate: endDate,
          },
        }),
      appointmentTypes: (officeVisitType) =>
        jwtAxios.get(
          `${url}appointmenttypes?officeVisitType=${officeVisitType}`,
        ),
      officeVisits: (parameter) =>
        jwtAxios.post(url + 'officevisits', parameter),
      getDepartmentProviders: (departmentId = null) => {
        let tempUrl = url + 'departmentprovider';
        if (departmentId) {
          tempUrl += '?departmentId=' + departmentId;
        }
        return jwtAxios.get(tempUrl);
      },
      getRefferingProviders: (departmentId = null, providerId = null) => {
        let tempUrl = url + 'refferingprovider';
        let queryString = '';
        if (departmentId) {
          queryString = '?departmentId=' + departmentId;
        }

        if (providerId) {
          queryString +=
            queryString === ''
              ? '?providerId=' + providerId
              : '&providerId=' + providerId;
        }

        return jwtAxios.get(tempUrl + queryString);
      },
      getRefferalTrackerData: (
        departmentId = null,

        providerId = null,

        refferingProviderId = null,
      ) => {
        let tempUrl = url + 'refferaltracker';
        let queryString = '';
        if (departmentId) {
          queryString = '?departmentId=' + departmentId;
        }

        if (providerId) {
          queryString +=
            queryString === ''
              ? '?providerId=' + providerId
              : '&providerId=' + providerId;
        }

        if (refferingProviderId) {
          queryString += '&refferingProviderId=' + refferingProviderId;
        }

        return jwtAxios.get(tempUrl + queryString);
      },
      appointmentTypes: (officeVisitType) =>
        jwtAxios.get(
          `${url}appointmenttypes?officeVisitType=${officeVisitType}`,
        ),
      officeVisits: (parameter) =>
        jwtAxios.post(url + 'officevisits', parameter),
      getDepartmentProviders: (departmentId = null) => {
        let tempUrl = url + 'departmentprovider';
        if (departmentId) {
          tempUrl += '?departmentId=' + departmentId;
        }
        return jwtAxios.get(tempUrl);
      },
      getRefferingProviders: (departmentId = null, providerId = null) => {
        let tempUrl = url + 'refferingprovider';
        let queryString = '';
        if (departmentId) {
          queryString = '?departmentId=' + departmentId;
        }

        if (providerId) {
          queryString +=
            queryString === ''
              ? '?providerId=' + providerId
              : '&providerId=' + providerId;
        }

        return jwtAxios.get(tempUrl + queryString);
      },
      getRefferalTrackerData: (
        departmentId = null,
        providerId = null,
        refferingProviderId = null,
      ) => {
        let tempUrl = url + 'refferaltracker';
        let queryString = '';
        if (departmentId) {
          queryString = '?departmentId=' + departmentId;
        }

        if (providerId) {
          queryString +=
            queryString === ''
              ? '?providerId=' + providerId
              : '&providerId=' + providerId;
        }

        if (refferingProviderId) {
          queryString += '&refferingProviderId=' + refferingProviderId;
        }

        return jwtAxios.get(tempUrl + queryString);
      },
    };
  },
  userMessages(url = 'usermessages/') {
    return {
      create: (userMessageCreateRequest) =>
        jwtAxios.post(url, userMessageCreateRequest),
      fetchById: (id) => jwtAxios.get(url + id),
      fetchByUser: (userId, limit = null, sortDesc = false) =>
        jwtAxios.get(url, { params: { userId, limit, sortDesc } }),
      fetchUsers: () => jwtAxios.get(url + 'users'),
      setSeenByUserId: (userMessageSeenRequest) =>
        jwtAxios.patch(url + 'seen', userMessageSeenRequest),
      setUnseen: (id) => jwtAxios.patch(url + id + '/unseen'),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  users(url = 'users/') {
    return {
      fetchAll: (filter) => jwtAxios.post(url + 'filtered', filter),
      register: (userInfo) => jwtAxios.post(url + 'register', userInfo),
      delete: (id) => jwtAxios.delete(url + id),
      fetchById: (id) => jwtAxios.get(url + id),
      fetchAccountInfoById: (id) => jwtAxios.get(url + 'accountInfo/' + id),
      update: (id, updateRecord) =>
        jwtAxios.put(url + 'userinfo/' + id, updateRecord),
      toggleTFA: (id) => jwtAxios.put(url + 'toggletfa/' + id),
      sendtfacode: (info) => jwtAxios.post(url + 'sendtfacode', info),
      verifytfacode: (info) => jwtAxios.post(url + 'verifytfacode', info),
      addAndConfirmPhoneNumber: (id, phoneNumber) =>
        jwtAxios.put(url + 'addAndConfirmPhoneNumber/' + id, phoneNumber),
      updateAndConfirmPhoneNumber: (id, phoneNumber) =>
        jwtAxios.put(url + 'updateAndConfirmPhoneNumber/' + id, phoneNumber),
      deletePhoneNumber: (id) => jwtAxios.delete(url + id + '/phone-number'),
      checkIfTFAEnabled: (id) => jwtAxios.get(url + 'checkIfTFAEnabled/' + id),
      login: (loginInfo) => jwtAxios.post(url + 'login', loginInfo),
      getcurrentuserpincode: (id) =>
        jwtAxios.get(url + 'getcurrentuserpincode/' + id),
      updatepincode: (info) => jwtAxios.put(url + 'updatepincode', info),
      sendwelcomeemail: (info) => jwtAxios.post(url + 'sendwelcomeemail', info),
      sendRegistrationEmail: (info) =>
        jwtAxios.post(url + 'sendRegistrationEmail', info),
      getPasswordResetToken: (info) =>
        jwtAxios.post(url + 'getPasswordResetToken', info),
      getLastPasswordChangedDate: (userId) =>
        jwtAxios.get(`${url}getLastPasswordChangedDate/${userId}`),
      getHasSignedAgreement: (userId) =>
        jwtAxios.get(`${url}getHasSignedAgreement/${userId}`),
      setAgreementSignedDate: (info) =>
        jwtAxios.post(url + 'setAgreementSignedDate', info),
      isUserIdValid: (id) => jwtAxios.get(url + 'isUserIdValid/' + id),
      logindetail: (info) => jwtAxios.post(url + 'logindetail', info),
      userscontrollist: () => jwtAxios.get(url + 'userscontrollist'),
      deleteProfileImage: (id) => jwtAxios.delete(url + `${id}/profileImage`),
      getprovidersforbaselines: () =>
        jwtAxios.get(url + 'getprovidersforbaselines'),
      getDoctorById: (id) => jwtAxios.get(url + 'GetDoctorById/' + id),
      sendProfileCompletionText: (id) =>
        jwtAxios.post(url + 'sendprofilecompletiontext/' + id),
      sendProfileData: (id, info) =>
        jwtAxios.put(url + 'updatedoctorprofile/' + id, info),
      updatePhoneNumberAndEnable2FA: (id, info) =>
        jwtAxios.put(url + 'updatephonenumberandenable2fa/' + id, info),
      getproviders: () => jwtAxios.get(url + 'getproviders'),
      doctorConsent: (id, updateRecord) =>
        jwtAxios.put(url + 'userinfo/doctorconsent/' + id, updateRecord),
      getSignature: (consentSignatureFile) =>
        jwtAxios.get(url + 'getSignature/' + consentSignatureFile),
      downloadAttestationFile: (id) =>
        jwtAxios.get(url + 'userinfo/downloadAttestationFile/' + id),
      sendDoctorConsent: (id) => jwtAxios.post(url + 'sendconsentdoctor/' + id),
      getEmrProviders: () => jwtAxios.get(url + 'getemrproviders'),
      getClinicalUsersByType: (clinicalUserTypeEnum) =>
        jwtAxios.get(
          url + `getclinicalusersbytype?type=${clinicalUserTypeEnum}`,
        ),
      getAssociatedDoctors: () => jwtAxios.get(url + `getassociateddoctors`),
      checkIfAutoConsented: (id) =>
        jwtAxios.get(url + `checkifautoconsented/${id}`),
    };
  },
  providerFacilities(url = 'providerfacilities') {
    return {
      addProviderFacility: (dto) =>
        jwtAxios.post(`${url}/addProviderFacility`, dto),
      deleteProviderFacility: (id) =>
        jwtAxios.delete(`${url}/deleteproviderfacility/${id}`),
      getByProviderIdToSelect: (providerId) =>
        jwtAxios.get(`${url}/getbyprovideridtoselect/${providerId}`),
      editProviderFacility: (dto) =>
        jwtAxios.put(`${url}/editProviderFacility`, dto),
      getbyinstanceid: () => jwtAxios.get(`${url}/getbyinstanceid`),
    };
  },
  clinicalContacts(url = 'clinicalcontacts') {
    return {
      addClinicalContact: (dto) =>
        jwtAxios.post(`${url}/addclinicalcontact`, dto),
      deleteClinicalContact: (id) =>
        jwtAxios.delete(`${url}/deleteclinicalcontact/${id}`),
      getByProviderIdToSelect: (providerId) =>
        jwtAxios.get(`${url}/getbyprovideridtoselect/${providerId}`),
      editClinicContact: (dto) =>
        jwtAxios.put(`${url}/editclinicalcontact`, dto),
    };
  },
  activitylogs(url = 'activitylogs/') {
    return {
      filtered: (requestInfo) => jwtAxios.post(url + 'filtered', requestInfo),
    };
  },
  instances(url = 'instances/') {
    return {
      fetchAll: (filter) => jwtAxios.post(url + 'filtered', filter),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      fetchById: (id) => jwtAxios.get(url + id),
      delete: (id) => jwtAxios.delete(url + id),
      getCurrentInstanceId: () => jwtAxios.get(url + 'getCurrentInstanceId'),
      emailInstancesInfo: (month, year) =>
        jwtAxios.get(url + `emailInstancesInfo/${month}/${year}`),
      getPatients: (id, filter) =>
        jwtAxios.post(url + id + '/patients', filter),
    };
  },
  instanceSettings(url = 'instanceSettings/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchApplicableForms: () => jwtAxios.get(url + 'getapplicableforms'),
      addOrUpdateAll: (updatedSettings) =>
        jwtAxios.put(url + 'addOrUpdateAll', updatedSettings),
      addOrUpdateApplicableForms: (updatedApplicableForms) =>
        jwtAxios.put(url + 'addorupdateapplicableforms', {
          applicableForms: updatedApplicableForms,
        }),
      getDefaultState: () => jwtAxios.get(url + 'getDefaultState'),
      getDefaultRandForm: () => jwtAxios.get(url + 'getDefaultRandForm'),
      getTimeZoneOffsetHours: () =>
        jwtAxios.get(url + 'getTimeZoneOffsetHours'),
      fetchEnablePatientSelfCheckin: () =>
        jwtAxios.get(url + 'enablepatientselfcheckin'),
      updateClinicLogoPrimary: (formData) =>
        jwtAxios.put(url + 'clinic-logo-primary', formData),
      deleteClinicLogoPrimary: () =>
        jwtAxios.delete(url + 'clinic-logo-primary'),
      updateClinicLogoSecondary: (formData) =>
        jwtAxios.put(url + 'clinic-logo-secondary', formData),
      deleteClinicLogoSecondary: () =>
        jwtAxios.delete(url + 'clinic-logo-secondary'),
      updatePracticeLogo: (formData) =>
        jwtAxios.put(url + 'practice-logo', formData),
      deletePracticeLogo: () => jwtAxios.delete(url + 'practice-logo'),
      updatePracticeNewPatientAgreement: (formData) =>
        jwtAxios.put(url + 'practicenewpatientagreement', formData),
      updatePracticeNewPatientAgreementSpanish: (formData) =>
        jwtAxios.put(url + 'practicenewpatientagreementspanish', formData),
      deletePracticeNewPatientAgreement: () =>
        jwtAxios.delete(url + 'practicenewpatientagreement'),
      deletePracticeNewPatientAgreementSpanish: () =>
        jwtAxios.delete(url + 'practicenewpatientagreementspanish'),
      getPracticeNewPatientAgreementFileName: () =>
        jwtAxios.get(url + 'practicenewpatientagreementfilename'),
      getPracticeNewPatientAgreementSpanishFileName: () =>
        jwtAxios.get(url + 'practicenewpatientagreementSpanishfilename'),
      fetchUseAthena: () => jwtAxios.get(url + 'useathena'),
      fetchExtendedPatientAskSexualOrientation: () =>
        jwtAxios.get(url + 'extendedPatientAskSexualOrientation'),
      fetchExtendedPatientAskAssignedSexAtBirth: () =>
        jwtAxios.get(url + 'extendedPatientAskAssignedSexAtBirth'),
      fetchExtendedPatientAskPreferredPronouns: () =>
        jwtAxios.get(url + 'extendedPatientAskPreferredPronouns'),
      fetchClinicName: () => jwtAxios.get(url + 'clinicName'),
      fetchUseRpm: () => jwtAxios.get(url + 'userpm'),
      fetchClinicLogoPrimaryUrl: () =>
        jwtAxios.get(url + 'cliniclogoprimaryurl'),
      fetchClinicLogoSecondaryUrl: () =>
        jwtAxios.get(url + 'cliniclogosecondaryurl'),
      fetchExtendedPatientAskCommunicationPreferences: () =>
        jwtAxios.get(url + 'extendedPatientAskCommunicationPreferences'),
      fetchShowBmiFilterOnSandbox: () =>
        jwtAxios.get(url + 'showBmiFilterOnSandbox'),
      fetchPatientAppointmentReasonAdditionalQuestionId: () =>
        jwtAxios.get(url + 'patientappointmentreasonadditionalquestionid'),
      fetchPatientAppointmentReasonNewPatientAdditionalQuestionOptionId: () =>
        jwtAxios.get(
          url + 'patientappointmentreasonnewpatientadditionalquestionoptionid',
        ),
      fetchAdditionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason: () =>
        jwtAxios.get(
          url +
          'additionalquestionidstoonlyshowfornewpatientpatientappointmentreason',
        ),
      fetchShowIntakeFormForNewPatients: () =>
        jwtAxios.get(url + 'showintakeformfornewpatients'),
      fetchPatientAppointmentReasonFollowUpAdditionalQuestionOptionId: () =>
        jwtAxios.get(
          url + 'patientappointmentreasonfollowupadditionalquestionoptionid',
        ),
      fetchEnablePaymentCollection: () =>
        jwtAxios.get(url + 'enablepaymentcollection'),
      fetchShowOptInToConsentPage: () =>
        jwtAxios.get(url + 'showoptintoconsentpage'),
      fetchEnableDemoDashboard: () => jwtAxios.get(url + 'enabledemodashboard'),
      fetchDemoPatientId: () => jwtAxios.get(url + 'demopatientid'),
      fetchDemoPatientAppointmentId: () =>
        jwtAxios.get(url + 'demopatientappointmentid'),
      fetchDemoPatientExternalId: () =>
        jwtAxios.get(url + 'demopatientexternalid'),
      fetchDemoPatientAppointmentExternalId: () =>
        jwtAxios.get(url + 'demopatientappointmentexternalid'),
      fetchEnableEducationalVideosPage: () =>
        jwtAxios.get(url + 'enableEducationalVideosPage'),
      fetchHomeCheckinEnabled: () => jwtAxios.get(url + 'enableHomeCheckin'),
      enableprocedurenote: () => jwtAxios.get(url + 'enableprocedurenote'),
      enableshotconsent: () => jwtAxios.get(url + 'enableshotconsent'),
      getIsPharmacyRequired: () => jwtAxios.get(url + 'getispharmacyrequired'),
      getEnableWelcomeScreen: () =>
        jwtAxios.get(url + 'getenablewelcomescreen'),
      getEnableHideExitPro: () => jwtAxios.get(url + 'getenablehideexitpro'),
      fetchUseNevro: () => jwtAxios.get(url + 'usenevro'),
      fetchUseModMed: () => jwtAxios.get(url + 'usemodmed'),
      getCheckInConfirmationPage: () =>
        jwtAxios.get(url + 'getcheckinconfirmationpage'),
      fetchUseDrChrono: () => jwtAxios.get(url + 'usedrchrono'),
      sendDrChronoAuthLink: (email) =>
        jwtAxios.post(url + 'senddrchronoauthlink?email=' + email),
      fetchUseMainstay: () => jwtAxios.get(url + 'usemainstay'),
      fetchUseAdvancedMD: () => jwtAxios.get(url + 'useadvancedmd'),
      fetchUseGreenway: () => jwtAxios.get(url + 'usegreenway'),
      useNewAppointmentDashboard: () =>
        jwtAxios.get(url + 'usenewappointmentdashboard'),
      getInstanceSettingsForAppointmentGrid: () =>
        jwtAxios.get(url + 'getinstancesettingsforappointmentgrid'),
      getEmrEnabled: () => jwtAxios.get(url + 'getemrenabled'),
      getAutoLogoutTime: () => jwtAxios.get(url + 'getautologouttime'),
      fetchUseCodePull: () => jwtAxios.get(url + 'usecodepull'),
      getEnabledPortalPro: () => jwtAxios.get(url + 'getenabledportalpro'),
      isPortalProClinic: () => jwtAxios.get(url + 'portalproclinic'),
      getMrnLookupEnabled: () => jwtAxios.get(url + 'getmrnlookupenabled'),
      getPsycEvalEnabled: () => jwtAxios.get(url + 'getpsycevalenabled'),
      fetchUseEClinicalWorks: () => jwtAxios.get(url + 'useeclinicalworks'),
      sendEClinicalWorksAuthLink: (email) =>
        jwtAxios.post(url + 'sendeclinicalworksauthlink?email=' + email),
      updateEClinicalWorksBulkPrivateKeyFile: (formData) =>
        jwtAxios.put(url + 'eclinicalworks-bulk-privatekey', formData),
      deleteEClinicalWorksBulkPrivateKeyFile: () =>
        jwtAxios.delete(url + 'eclinicalworks-bulk-privatekey'),
    };
  },
  resourceTypes(url = 'resourceTypes/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  resources(url = 'resources/') {
    return {
      fetchAll: (filter) => jwtAxios.post(url + 'filtered', filter),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
      controlList: () => jwtAxios.get(url + 'control-list'),
      fetchExtendedUrlFromShortenedUrl: (shortenedUrl) =>
        jwtAxios.post(url + 'extended-url', shortenedUrl, {
          headers: { 'Content-Type': 'application/json' },
        }),
      fetchEducational: () => jwtAxios.get(url + 'educational'),
      fetchFaq: () => jwtAxios.get(url + 'faq'),
    };
  },
  resourceFileActivities(url = 'resourceFileActivities/') {
    return {
      fetchAll: (filter) => jwtAxios.post(url + 'filtered', filter),
      create: (newRecord) => jwtAxios.post(url, newRecord),
    };
  },
  customFields(url = 'customfields') {
    return {
      fetchAll: (type = null) => jwtAxios.get(url, { params: { type } }),
      fetchById: (id) => jwtAxios.get(url + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
      getAppointmentTypes: () => jwtAxios.get(url + '/getappointmenttypes'),
    };
  },
  ehr(url = 'ehr/') {
    return {
      allergies: (searchvalue) =>
        jwtAxios.get(url + 'allergies/' + searchvalue),
      medications: (searchvalue) =>
        jwtAxios.get(url + 'medications/' + searchvalue),
      socialHistory: () => jwtAxios.get(url + 'socialhistory'),
      gynHistory: () => jwtAxios.get(url + 'gynhistory'),
      fetchPatientEncounters: (patientId) =>
        jwtAxios.get(url + `patients/${patientId}/encounters`),
      pullChangedAppointments: () =>
        jwtAxios.get(url + 'pullchangedappointments'),
      pullChangedAppointmentsForInstance: () =>
        jwtAxios.get(url + 'pullchangedappointmentsforinstance'),
      healthhistoryforms: (appointmentId) =>
        jwtAxios.get(url + `appointments/${appointmentId}/healthhistoryforms`),
      healthHistoryForm: (appointmentId, formId) =>
        jwtAxios.get(url + `healthhistoryform/${appointmentId}/${formId}`),
      updateHealthHistoryForm: (request) =>
        jwtAxios.put(url + 'healthhistoryform', request),
      pullChangedPatients: () => jwtAxios.get(url + 'pullchangedpatients'),
      pullChangedPatientsForInstance: () =>
        jwtAxios.get(url + 'pullchangedpatientsforinstance'),
      fetchTopAvailableInsurancePackages: () =>
        jwtAxios.get(url + 'topavailableinsurancepackages'),
      fetchAvailableInsurancePackages: (planName) =>
        jwtAxios.get(url + 'availableinsurancepackages', { params: { planName } }),
      fetchEncounterServices: (appointmentId) =>
        jwtAxios.get(
          url + `patientappointments/${appointmentId}/encounterservices`,
        ),
      fetchEnabledCustomScreeners: (patientAppointmentId) =>
        jwtAxios.get(
          url +
          `patientappointments/${patientAppointmentId}/enabledcustomscreeners`,
        ),
      submitCustomScreeners: (patientAppointmentId, customScreeners) =>
        jwtAxios.post(
          url + `patientappointments/${patientAppointmentId}/customscreeners`,
          customScreeners,
        ),
      cancelCheckin: (patientAppointmentId) =>
        jwtAxios.get(
          url + `patientappointments/${patientAppointmentId}/cancel-checkin`,
        ),
      createPdf: (text) => jwtAxios.post(url + 'createPdf', { text }),
      fetchPatientBalanceByPatientAppointment: (patientAppointmentId) =>
        jwtAxios.get(
          url + `patientappointments/${patientAppointmentId}/balance`,
        ),
      collectPatientPayment: (patientId, collectPatientPaymentRequest) =>
        jwtAxios.post(
          url + `patients/${patientId}/collectpatientpayment`,
          collectPatientPaymentRequest,
        ),
      cashPayment: (patientId, appointmentId) =>
        jwtAxios.post(
          `${url}patients/${patientId}/collectcashpayment/${appointmentId}`,
        ),
      validatePin: (patientId, appointmentId, pin) =>
        jwtAxios.post(
          `${url}patients/${patientId}/collectcashpaymentbyadmin/${appointmentId}?pin=${pin}`,
        ),
      pullChangedAppointmentsForModMed: () =>
        jwtAxios.get(url + 'PullChangedModMedAppointments'),
      pullChangedAppointmentsForAdvancedMD: () =>
        jwtAxios.get(url + 'pullchangedadvancedmdappointments'),
      pullChangedAppointmentsForGreenway: () =>
        jwtAxios.get(url + 'pullchangedgreenwayappointments'),
    };
  },
  patientAppointmentPeriodicPainScores(
    url = 'patientappointmentperiodicpainscores',
  ) {
    return {
      create: (newRecord) => jwtAxios.post(url, newRecord),
    };
  },
  adaInterventionalLocations(url = 'adainterventionallocations/') {
    return {
      fetchAll: () => jwtAxios.get(url),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + id, updateRecord),
      delete: (id) => jwtAxios.delete(url + id),
    };
  },
  providers(url = 'providers/') {
    return {
      fetchAllReferring: () => jwtAxios.get(url + 'referring'),
    };
  },
  healthKit(url = 'healthkit/') {
    return {
      getData: (patientId, startDate, endDate, timeWindow) =>
        jwtAxios.get(url + 'getData', {
          params: { patientId, startDate, endDate, timeWindow },
        }),
    };
  },

  customForms(url = 'customforms') {
    return {
      getById: (id) => jwtAxios.get(`${url}/${id}`),
      getByIdWithQuestions: (id) =>
        jwtAxios.get(`${url}/getbyidwithquestions/${id}`),
      getsurveyforms: () => jwtAxios.get(`${url}/getsurveyforms`),
      getnevroaccessform: () => jwtAxios.get(`${url}/getnevroaccessform`),
      fetchSurveyReportPDF: (customFormId, appointmentId) =>
        jwtAxios.get(
          url + '/' + appointmentId + '/surveyreportpdf/' + customFormId,
          {
            responseType: 'blob',
          },
        ),
      fetchAllSurveyReportsPDF: (customFormId, appointmentIds) =>
        jwtAxios.post(
          url + '/allsurveyreportspdf/' + customFormId,
          appointmentIds,
          {
            responseType: 'blob',
          },
        ),
      getintakeformgreenway: () => jwtAxios.get(`${url}/getgreenwayintakeform`),
    };
  },
  patientCard(url = 'patientcards') {
    return {
      getcardsforpatient: (patientId) =>
        jwtAxios.get(`${url}/getcardsforpatient/${patientId}`),
    };
  },
  procedureNote(url = 'patientappointmentprocedurenote') {
    return {
      getTemplate: (appointmentId, key, recordOrder = 0) =>
        jwtAxios.get(`${url}/template`, {
          params: { appointmentId, key, recordOrder },
        }),
      check: (appointmentId, key) =>
        jwtAxios.get(`${url}/check`, { params: { appointmentId, key } }),
      staff: () => jwtAxios.get(`${url}/staff`),
      submit: (appointmentId, key) =>
        jwtAxios.get(`${url}/submit`, { params: { appointmentId, key } }),
      save: (record) => jwtAxios.post(`${url}/save`, record),
    };
  },
  departments(url = 'departmentdetails') {
    return {
      getAll: () => jwtAxios.get(`${url}`),
      getDepartmentsForBaseline: () =>
        jwtAxios.get(`${url}/getdepartmentsforbaseline`),
    };
  },
  shotConsent(url = 'patientappointmentshotconsent') {
    return {
      getTemplate: (appointmentId, key, recordOrder = 0) =>
        jwtAxios.get(`${url}/template`, {
          params: { appointmentId, key, recordOrder },
        }),
      staff: () => jwtAxios.get(`${url}/staff`),
      save: (record) => jwtAxios.post(`${url}/save`, record),
    };
  },
  parent(url = 'parent') {
    return {
      getClinics: () => jwtAxios.get(`${url}/getClinics`),
    };
  },
  clinics(url = 'clinics') {
    return {
      fetchAll: () => jwtAxios.get(url),
      fetchById: (id) => jwtAxios.get(url + '/' + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + '/' + id, updateRecord),
    };
  },
  providerappointmentbaselinedates(url = 'providerappointmentbaselinedates') {
    return {
      insert: (dto) => jwtAxios.post(`${url}/insert`, dto),
      getForProvider: (providerId, date) =>
        jwtAxios.get(
          `${url}/getforprovider?providerid=${providerId}&date=${date}`,
        ),
    };
  },
  patientAppointmentDigitalConsent(url = 'patientappointmentdigitalconsent') {
    return {
      fetchById: (id) => jwtAxios.get(url + '/' + id),
      create: (newRecord) => jwtAxios.post(url, newRecord),
      update: (id, updateRecord) => jwtAxios.put(url + '/' + id, updateRecord),
      downloadDigitalConsentPdf: (appointmentId) =>
        jwtAxios.get(url + '/consentpdf/' + appointmentId, {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        }),
      check: (appointmentId) => jwtAxios.get(url + '/check/' + appointmentId),
    };
  },
  nevro(url = 'nevro') {
    return {
      syncfiles: (nevroCaseId) =>
        jwtAxios.get(url + '/syncfiles/' + nevroCaseId),
      downloadFile: (id, fileId, fileType) =>
        jwtAxios.get(
          url +
          '/download/' +
          id +
          '/' +
          fileId +
          (fileType ? '/' + fileType : ''),
        ),
      fetchChatterFeed: (patientId, nevroCaseId) =>
        jwtAxios.get(url + '/chatterfeed/' + patientId + '/' + nevroCaseId),
      getProviderFromNevro: (npi) =>
        jwtAxios.get(url + `/getproviderfromnevro/${npi}`),
      signDocument: (nevroCaseId, fileId, signatureData) =>
        jwtAxios.post(url + '/signdocument', {
          nevroCaseId,
          fileId,
          signatureData,
        }),
      sendSignText: (nevroCaseId, fileId) =>
        jwtAxios.get(url + '/sendsigntext/' + nevroCaseId + '/' + fileId),
      sendMessage: (data) => jwtAxios.post(url + '/postmessage/', data),
      sendComment: (data) => jwtAxios.post(url + '/postcomment/', data),
      delete: (id) => jwtAxios.delete(url + '/' + id),
      sendCaseCloneRequest: (id, record) =>
        jwtAxios.post(url + '/caseclonerequest/' + id, record),
      getChatterNotification: () => jwtAxios.get(url + '/chatternotification'),
      resetChatterNotification: (nevroCaseId) =>
        jwtAxios.get(url + '/resetchatternotification/' + nevroCaseId),
      getChatterNotificationCount: () =>
        jwtAxios.get(url + '/chatternotificationcount'),
      searchByNpi: (npi) => jwtAxios.get(`${url}/searchbynpi?npi=${npi}`),
    };
  },
  codePullTemplates(url = 'codepulltemplates') {
    return {
      getAll: () => jwtAxios.get(url),
      delete: (id) => jwtAxios.delete(url + '/' + id),
    };
  },
  codePulls(url = 'codepulls') {
    return {
      getAll: (status) => jwtAxios.get(`${url}?status=${status}`),
      getByTemplateId: (templateId) =>
        jwtAxios.get(`${url}/getbytemplateid/${templateId}`),
      create: (dto) => jwtAxios.post(url, dto),
      toggleApprovalStatus: (id) =>
        jwtAxios.patch(`${url}/toggleapprovalstatus/${id}`),
      getDetailsToEnable: (id) =>
        jwtAxios.get(`${url}/getdetailstoenable/${id}`),
      saveCampaignPathway: (dto) =>
        jwtAxios.post(`${url}/savecampaignpathway`, dto),
      updateCampaignToEnable: (id, dto) =>
        jwtAxios.put(`${url}/updatecampaigntoenable/${id}`, dto),
      toggleSendingText: (id) =>
        jwtAxios.patch(`${url}/togglesendingtext/${id}`),
      finishCampaign: (id) => jwtAxios.delete(`${url}/finishcampaign/${id}`),
      getSummaryEmail: (id) => jwtAxios.get(`${url}/getsummaryemail/${id}`),
      getDetailsToDuplicate: (id) =>
        jwtAxios.get(`${url}/getdetailstoduplicate/${id}`),
      sendSummaryEmail: (id) => jwtAxios.post(`${url}/sendsummaryemail/${id}`),
      sendText: () => jwtAxios.post(`${url}/sendcodepulltext`),
      getAllTemplates: () => jwtAxios.get(`${url}/getalltemplates`),
      deleteCampaign: (id) => jwtAxios.delete(`${url}/deletecampaign/${id}`),
      update: (dto) => jwtAxios.put(`${url}/updatecampaign`, dto),
      approveCampgaign: (id, dto) =>
        jwtAxios.post(`${url}/approvecampaign/${id}`, dto),
      campaignReport: (dto) =>
        jwtAxios.post(`${url}/campaignreport`, dto),
    };
  },
  codePullPatients(url = 'codepullpatients') {
    return {
      getByCampaignId: (id, paginationFilter, sortBy, sortDirection) =>
        jwtAxios.get(
          `${url}/getbycampaignid/${id}?pagenumber=${paginationFilter.pageNumber}&pagesize=${paginationFilter.pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
        ),
      removePatientsFromList: (ids, campaignId) =>
        jwtAxios.patch(`${url}/removepatientsfromlist/`, {
          ids: ids,
          campaignId: campaignId,
        }),
    };
  },
  greenway(url = 'greenway') {
    return {
      syncDefaultData: () => jwtAxios.get(url + '/syncdefaultdata'),
    };
  },
  appSettings(url = 'appsettings') {
    return {
      getEClinicalWorksAuthUrl: () => jwtAxios.get(url + '/eclinicalworksauthurl'),
    };
  }
};
