import {ACTION_TYPES} from '../../actions/admin/perioperativemessagetemplate';

const initialState = {
  list: [],
  currentTemplateId: '',
};

export const perioperativemessagetemplates = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...state,
        list: [...action.payload],
      };
    case ACTION_TYPES.CREATE:
      return {
        ...state,
        list: [...state.list, action.payload],
      };

    case ACTION_TYPES.UPDATE:
      return {
        ...state,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x,
        ),
      };

    case ACTION_TYPES.DELETE:
      return {
        ...state,
        list: state.list.filter((x) => x.id !== action.payload),
      };
    case ACTION_TYPES.SET_CURRENT_TEMPLATE_ID:
      return {
        ...state,
        currentTemplateId: action.payload,
      };
    default:
      return state;
  }
};

export const selectPerioperativeMessageTemplates = (state) =>
  state.perioperativemessagetemplates.list;

export const selectPerioperativeMessageTemplatesWithCodes = (state) =>
  selectPerioperativeMessageTemplates(state).filter((t) =>
    t?.perioperativeMessageTemplateCodes?.some((code) => !!code.code),
  );

export const selectCurrentTemplateId = (state) =>
  state.perioperativemessagetemplates.currentTemplateId;
