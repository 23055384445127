import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@e74/utility/Utils';
import {adminPagesConfigs} from './admin';
import {patientsPagesConfigs} from './patients';
import {reportsPagesConfigs} from './reports';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {usersPagesConfigs} from './users';
import {instancesPagesConfigs} from './instances';
import {resourcePagesConfigs} from './resources';
import {userMessagesPagesConfig} from './user-messages';
import {demoPagesConfigs} from './demo';
import {customFormsPagesConfigs} from './customforms';
import {clinicsPagesConfigs} from './clinics';
import {codePullPagesConfigs} from './codePulls';

const routeConfigs = [
  ...adminPagesConfigs,
  ...patientsPagesConfigs,
  ...usersPagesConfigs,
  ...instancesPagesConfigs,
  ...reportsPagesConfigs,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...resourcePagesConfigs,
  ...userMessagesPagesConfig,
  ...demoPagesConfigs,
  ...customFormsPagesConfigs,
  ...clinicsPagesConfigs,
  ...codePullPagesConfigs,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
