import {ACTION_TYPES} from 'redux/actions/ehr';

const initialState = {
  data: {
    availableInsurancePackages: [],
    socialHistoryQuestions: [],
    gynHistoryQuestions: [],
    enabledCustomScreeners: [],
  },
};

export const ehrReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_AVAILABLE_INSURANCE_PACKAGES:
      return {
        ...state,
        data: {...state.data, availableInsurancePackages: action.payload},
      };
    case ACTION_TYPES.FETCH_SOCIAL_HISTORY_QUESTIONS:
      return {
        ...state,
        data: {...state.data, socialHistoryQuestions: action.payload},
      };
    case ACTION_TYPES.FETCH_GYN_HISTORY_QUESTIONS:
      return {
        ...state,
        data: {...state.data, gynHistoryQuestions: action.payload},
      };
    case ACTION_TYPES.FETCH_ENABLED_CUSTOM_SCREENERS:
      return {
        ...state,
        data: {...state.data, enabledCustomScreeners: action.payload},
      };
    default:
      return state;
  }
};

export const selectAvailableInsurancePackages = (state) =>
  state.ehr.data.availableInsurancePackages;

export const selectSocialHistoryQuestions = (state) =>
  state.ehr.data.socialHistoryQuestions;
export const selectGynHistoryQuestions = (state) =>
  state.ehr.data.gynHistoryQuestions;

export const selectEnabledCustomScreeners = (state) =>
  state.ehr.data.enabledCustomScreeners;
