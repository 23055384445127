import {lazy} from 'react';

export const patientsPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/patients/dashboard',
        component: lazy(() => import('./dashboard/')),
      },
      {
        path: '/patients/list',
        component: lazy(() => import('./patientlist/index')),
      },
      {
        path: '/patients/create/:id',
        component: lazy(() => import('./patient/PatientForm')),
      },
      {
        path: '/patients/createnevro/:id/:nevroCaseId',
        component: lazy(() => import('./patient/PatientFormNevro')),
      },
      {
        path: '/patients/patientdashboard/:id',
        component: lazy(() => import('./patient/patientdashboard')),
      },
      {
        path: '/patients/basicintake',
        component: lazy(() => import('./patient/PatientBasicForm')),
      },
      {
        path: '/patients/basicintakenevro',
        component: lazy(() => import('./patient/PatientBasicFormNevro')),
      },
      {
        path: '/patients/appointments',
        component: lazy(() => import('../appointments/appointmentlist/index')),
      },
      {
        path: '/patients/staffappointments',
        component: lazy(() =>
          import('../appointments/appointmentlist/StaffAppointmentGrid'),
        ),
      },
      {
        path: '/formresponse/result/:id',
        component: lazy(() => import('shared/components/Quiz/Result')),
      },
      {
        path: '/patients/intervention/interventionform/:id/:patientid',
        component: lazy(() => import('./intervention/interventionform')),
      },
      {
        path: '/patients/bulkintakenotification',
        component: lazy(() =>
          import('../patients/patient/bulkintakenotification'),
        ),
      },
      {
        path: '/patients/procedurenoteform/:id/:patientid/:providerId/:type',
        component: lazy(() => import('./patient/ProcedureNoteForm')),
      },
      {
        path: '/patients/basicintakemainstay',
        component: lazy(() => import('./patient/PatientBasicFormMainstay')),
      },
      {
        path: '/patients/createmainstay/:id',
        component: lazy(() => import('./patient/PatientFormMainstay')),
      },
      {
        path: '/patients/nevro/chatternotification',
        component: lazy(() => import('./nevro/chatterNotification')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/patients/closeaccount/:id?/:token?',
        component: lazy(() => import('./patient/CloseAccount')),
      },
      {
        path: '/patients/intake/:id?/:token?/:appointmentId?',
        component: lazy(() => import('./intakesteps/intake')),
      },
      {
        path: '/patients/sf36/:id/:appointmentid?',
        component: lazy(() => import('../formresponse/sf36')),
      },
      {
        path: '/patients/odi/:id/:appointmentid',
        component: lazy(() => import('../formresponse/odi')),
      },
      {
        path: '/patients/ndi/:id/:appointmentid',
        component: lazy(() => import('../formresponse/ndi')),
      },
      {
        path: '/patients/pdi/:id/:appointmentid',
        component: lazy(() => import('../formresponse/pdi')),
      },
      {
        path: '/patients/koosjr/:id/:appointmentid',
        component: lazy(() => import('../formresponse/koosjr')),
      },
      {
        path: '/patients/hoosjr/:id/:appointmentid',
        component: lazy(() => import('../formresponse/hoosjr')),
      },
      {
        path: '/patients/phq9/:id/:appointmentid',
        component: lazy(() => import('../formresponse/phq9')),
      },
      {
        path: '/patients/phq2/:id/:appointmentid',
        component: lazy(() => import('../formresponse/phq2')),
      },
      {
        path: '/patients/gad7/:id/:appointmentid',
        component: lazy(() => import('../formresponse/gad7')),
      },
      {
        path: '/patients/ort/:id/:appointmentid',
        component: lazy(() => import('../formresponse/ort')),
      },
      {
        path: '/patients/promisglobal10/:id/:appointmentid',
        component: lazy(() => import('../formresponse/promisglobal10')),
      },
      {
        path: '/patients/vr12/:id/:appointmentid?',
        component: lazy(() => import('../formresponse/vr12')),
      },
      {
        path: '/patients/soappr/:id/:appointmentid?',
        component: lazy(() => import('../formresponse/soappr')),
      },
      {
        path: '/patients/cesdr/:id/:appointmentid?',
        component: lazy(() => import('../formresponse/cesdr')),
      },
      {
        path: '/patients/auditc/:id/:appointmentid?',
        component: lazy(() => import('../formresponse/auditc')),
      },
      {
        path: '/patients/appointmentdashboard/:id?/:appointmentid?/:revisit?',
        component: lazy(() =>
          import('../appointments/appointment/AppointmentDashboard'),
        ),
      },
      {
        path: '/patients/intakenotavailable',
        component: lazy(() => import('./intakesteps/intakenotavailable')),
      },
      {
        path: '/patients/intakecomplete',
        component: lazy(() => import('./intakesteps/intakecomplete')),
      },
      {
        path: '/termsandconditions',
        component: lazy(() =>
          import('../patients/intakesteps/TermsAndConditions'),
        ),
      },
      {
        path: '/privacypolicy',
        component: lazy(() => import('../patients/intakesteps/Privacy')),
      },
      {
        path: '/mainstayconsent',
        component: lazy(() =>
          import('../patients/intakesteps/mainStayConsent'),
        ),
      },
      {
        path: '/checkincomplete',
        component: lazy(() =>
          import('../appointments/appointment/CheckInConfirmation'),
        ),
      },
      {
        path: '/homecheckincomplete',
        component: lazy(() =>
          import('../appointments/appointment/HomeCheckInConfirmation'),
        ),
      },
      {
        path:
          '/patients/:patientId/appointments/:appointmentId/patient-checkin',
        component: lazy(() =>
          import('../appointments/appointment/patient-checkin'),
        ),
      },
      {
        path: '/newpatientpaperwork/:id?',
        component: lazy(() => import('./patient/ExtendedPatientForm')),
      },
      {
        path: '/newpatientpaperworkdrchorono/:id?',
        component: lazy(() => import('./patient/ExtendedPatientFormDrChrono')),
      },
      {
        path: '/patients/resignconsent/:id?',
        component: lazy(() => import('./patient/NewPatientConsent/index')),
      },
      {
        path:
          '/patients/:patientid/appointments/:appointmentid/healthhistoryforms/:formid?',
        component: lazy(() => import('../healthhistoryforms/index')),
      },
      {
        path:
          '/patients/:patientid/appointments/:patientappointmentid/customscreeners',
        component: lazy(() => import('./CustomScreeners')),
      },
      {
        path: '/patients/:patientId/clientInfo/:appointmentId',
        component: lazy(() => import('../appointments/appointment/ClientInfo')),
      },
      {
        path:
          '/patients/:patientId/clientenquiry/:appointmentId/client-enquiry',
        component: lazy(() =>
          import('../appointments/appointment/ClientEnquiry'),
        ),
      },
      {
        path: '/periodicvas/:patientId/:patientAppointmentId',
        component: lazy(() => import('../periodic-pain-score-form')),
      },
      {
        path: '/thankyou',
        component: lazy(() =>
          import('../periodic-pain-score-form/ThankYouPage'),
        ),
      },
      {
        path: '/exit',
        component: lazy(() => import('../periodic-pain-score-form/ExitPage')),
      },
      {
        path: '/clinical/healthhistoryform',
        component: lazy(() => import('../clinicalforms/healthhistoryform')),
      },
      {
        path: '/patients/:patientId/payment/:patientAppointmentId',
        component: lazy(() => import('./PaymentCollector')),
      },
      {
        path: '/patients/nevro/intake/patient/:id/:nevroCaseId',
        component: lazy(() => import('./intakenevro/patient/intake')),
      },
      {
        path: '/patients/nevro/intake/doctor/:id/:nevroCaseId',
        component: lazy(() => import('./intakenevro/doctor/intake')),
      },
      {
        path: '/patients/digitalconsentform/:id',
        component: lazy(() => import('./patient/DigitalConsentForm')),
      },
      {
        path: '/patients/nevro/doctor/document/sign/:nevroCaseId/:fileId',
        component: lazy(() => import('./DoctorDocumentSign')),
      },
      // {
      //   path: '/patients/intakegreenway/:id?/:token?/:appointmentId?',
      //   component: lazy(() => import('./intakegreenway/intake')),
      // },
      {
        path: '/patients/intakegreenway/:id',
        component: lazy(() => import('./intakegreenway/IntakeGreenway')),
      },
    ],
  },
];
