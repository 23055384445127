import {selectCurrentTemplateId} from 'redux/reducers/admin/perioperativemessagetemplate';
import {selectDoctorId} from 'redux/reducers/doctor';
import api from '../../../@e74/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess} from '../Common';

export const ACTION_TYPES = {
  CREATE: 'CREATE_PERIOPERATIVE_MESSAGE_DETAILS',
  UPDATE: 'UPDATE_PERIOPERATIVE_MESSAGE_DETAILS',
  DELETE: 'DELETE_PERIOPERATIVE_MESSAGE_DETAILS',
  FETCH_BY_TEMPLATE_ID: 'FETCH_BY_TEMPLATE_ID_PERIOPERATIVE_MESSAGE_DETAILS',
};

export const fetchByTemplateId = (templateId) => (dispatch, getState) => {
  dispatch(fetchStart());
  api
    .perioperativemessagedetails()
    .fetchByTemplateId(
      templateId ?? selectCurrentTemplateId(getState()),
      selectDoctorId(getState()),
    )
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch({
        type: ACTION_TYPES.FETCH_BY_TEMPLATE_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const create = (data, onSuccess, onError) => (dispatch) => {
  dispatch(fetchStart());
  api
    .perioperativemessagedetails()
    .create(data)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch({
        type: ACTION_TYPES.CREATE,
        payload: res.data,
      });
      onSuccess();
    })
    .catch((err) => {
      onError && onError(err);
    });
};

export const update = (data, onSuccess, onError) => (dispatch) => {
  dispatch(fetchStart());
  api
    .perioperativemessagedetails()
    .update(data)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch({
        type: ACTION_TYPES.UPDATE,
        payload: {...data},
      });
      onSuccess();
    })
    .catch((err) => {
      onError && onError(err);
    });
};

export const deletePerioperativeMessageDetail = (id) => (dispatch) => {
  dispatch(fetchStart());
  api
    .perioperativemessagedetails()
    .delete(id)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch({
        type: ACTION_TYPES.DELETE,
        payload: id,
      });
    })
    .catch((err) => {});
};
