import {createStyles} from '@material-ui/core/styles';
import {Fonts} from '../../../../shared/constants/AppEnums';

const useStyles = (theme) =>
  createStyles({
    navItem: {
      height: 44,
      marginTop: 2,
      marginBottom: 2,
      fontWeight: 700,
      cursor: 'pointer',
      textDecoration: 'none !important',
      paddingLeft:
        theme.direction === 'ltr' ? (props) => 24 + 50 * props.level : 12,
      paddingRight:
        theme.direction === 'rtl' ? (props) => 24 + 50 * props.level : 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '&.active': {
        backgroundColor: '#7E57FE',
        borderRight: '3px solid #F7AF40',
        pointerEvents: 'none',
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .nav-item-text': {
          color: '#FFF !important',
          // fontFamily: Fonts.MEDIUM,
        },
        '& .nav-item-icon': {
          color: '#FFF !important',
        },
      },

      '&:hover, &:focus': {
        backgroundColor: '#7E57FE',
        color: '#fff',

        '& .nav-item-icon': {
          color: '#FFF',
        },

        '& .nav-item-icon-arrow': {
          // color: 'rgb(100, 180, 253, 0.2)',
        },
      },

      '& .nav-item-icon': {
        // color: '#FFF',
        color: 'black',
      },
      '& .nav-item-text': {
        // color: '#FFF',
        fontSize: 18,
      },
    },
    navItemNevro: {
      height: 44,
      marginTop: 2,
      marginBottom: 2,
      fontWeight: 700,
      cursor: 'pointer',
      textDecoration: 'none !important',
      paddingLeft:
        theme.direction === 'ltr' ? (props) => 24 + 50 * props.level : 12,
      paddingRight:
        theme.direction === 'rtl' ? (props) => 24 + 50 * props.level : 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '&.active': {
        backgroundColor: '#00B6EE',
        // borderRight: '3px solid #F7AF40',
        pointerEvents: 'none',
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .nav-item-text': {
          color: 'white !important',
          // fontFamily: Fonts.MEDIUM,
          fontWeight: 550,
        },
        '& .nav-item-icon': {
          color: 'white !important',
        },
      },

      '&:hover, &:focus': {
        backgroundColor: '#00B6EE',

        '& .nav-item-icon': {
          color: '#000',
        },

        '& .nav-item-icon-arrow': {
          color: 'rgb(100, 180, 253, 0.2)',
        },
      },
      '& .nav-item-icon': {
        // color: '#000',
      },
      '& .nav-item-text': {
        color: '#000',
        fontSize: 18,
      },
    },
    '@media (max-width: 1919px)': {
      navItem: {
        paddingLeft:
          theme.direction === 'ltr' ? (props) => 17 + 50 * props.level : 12,
        paddingRight:
          theme.direction === 'rtl' ? (props) => 17 + 50 * props.level : 12,

        '& .nav-item-text': {
          fontSize: 16,
        },
      },
      navItemNevro: {
        paddingLeft:
          theme.direction === 'ltr' ? (props) => 17 + 50 * props.level : 12,
        paddingRight:
          theme.direction === 'rtl' ? (props) => 17 + 50 * props.level : 12,

        '& .nav-item-text': {
          fontSize: 16,
        },
      },
    },
    listIcon: {
      fontSize: 18,
      marginRight: 5,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      // fontFamily: Fonts.REGULAR,
    },
  });
export default useStyles;
