import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
//import LanguageSwitcher from '../../LanguageSwitcher';
import {toggleNavCollapsed} from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import SearchBar from '../../SearchBar';
import useStyles from './AppHeader.style';
import UserInfo from '../../../../shared/components/UserInfo';
import clsx from 'clsx';
import Notifications from '../../../../shared/components/Notifications';
import DoctorSwitcher from '../../../../shared/components/DoctorSwitcher';
import GooglePageTranslator from '../../../../shared/components/GooglePageTranslator';
import {useAuthUser} from '@e74/utility/AppHooks';
import ClinicSwitcher from 'shared/components/ClinicSwitcher';
import {
  selectEnablePortalPro,
  selectIsPortalProClinic,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import ApiConfig from '@e74/services/ApiConfig';

const AppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const user = useAuthUser();
  //const useNevro = useSelector(selectUseNevro);
  let userRole = localStorage.getItem('userRole');
  let isSystemManager = userRole.indexOf('System Manager') > -1;
  const isPortalProClinic = useSelector(selectIsPortalProClinic);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}></Menu>
  );

  return (
    <>
      <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(toggleNavCollapsed())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>

          <Box className={classes.grow} />
          <UserInfo />
          <Box marginX={4} className={classes.sectionDesktop}>
            {/* <LanguageSwitcher /> */}
            <GooglePageTranslator />
          </Box>

          {<DoctorSwitcher />}
          {!isPortalProClinic && (
            <>
              <Notifications />
              <SearchBar borderLight placeholder='Search Patient…' />
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};
export default AppHeader;
