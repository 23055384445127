import React, {useContext} from 'react';
import {
  makeStyles,
  Avatar,
  Box,
  MenuItem,
  Menu,
  IconButton,
  ThemeProvider,
} from '@material-ui/core';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import {useDispatch, useSelector} from 'react-redux';
import {onJWTAuthSignout} from '../../../redux/actions';
import {useAuthUser} from '../../../@e74/utility/AppHooks';
import AppContext from '../../../@e74/utility/AppContext';
import clsx from 'clsx';
import {grey, orange} from '@material-ui/core/colors';
import {Fonts} from '../../constants/AppEnums';
import {withRouter, useHistory} from 'react-router-dom';
import {S3_USERS_BASE_URL} from 'shared/constants/AppConst';
import {
  selectEnablePortalPro,
  selectIsPortalProClinic,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import {defaultDropdownTheme, nevroDropdownTheme} from '@e74/utility/Utils';

const useStyles = makeStyles((theme) => {
  return {
    crUserInfo: {
      backgroundColor: 'white',
      paddingTop: 10,
      paddingBottom: 10,
      minHeight: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        paddingRight: 0,
      },
      marginLeft: 2,
      marginRight: 2,
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    crUserInfoNevro: {
      backgroundColor: 'white',
      // marginTop: 10,
      // marginBottom: -10,
      width: '100%',
      // borderRadius: 10,
      // paddingBottom: 10,
      // minHeight: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        paddingRight: 0,
      },
      marginLeft: 2,
      marginRight: 2,
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    profilePic: {
      height: 40,
      width: 40,
      fontSize: 24,
      backgroundColor: (props) =>
        props.useNevro ? theme.palette.primary.main : orange[500],
      [theme.breakpoints.up('xl')]: {
        height: 45,
        width: 45,
      },
    },
    userInfo: {
      width: 'calc(100% - 25px)',
      [theme.breakpoints.up('md')]: {
        marginLeft: 16,
      },
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 18,
      // fontFamily: Fonts.MEDIUM,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
      color: '#000000',
    },
    designation: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      // color: 'blue',
    },

    expandIcon: {
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {marginLeft: 12},
    },

    adminRoot: {
      color: grey[500],
      fontSize: 16,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    desktopSection: {
      display: 'none',
      [theme.breakpoints.up('md')]: {display: 'flex'},
    },

    desktopSectionNevro: {
      display: 'flex',
    },
  };
});

const UserInfo = ({...props}) => {
  const {themeMode} = useContext(AppContext);
  const dispatch = useDispatch();
  const user = useAuthUser();
  const history = useHistory();
  const useNevro = useSelector(selectUseNevro);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onGoToChangePassword = () => {
    props.history.push('/change-password');
  };

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const onGoToMyAccount = () => {
    props.history.push('/my-account');
  };

  const classes = useStyles({themeMode, useNevro});

  return (
    <Box
      px={{xs: 4, xl: 7}}
      className={clsx(
        useNevro && !isPortalProClinic
          ? classes.crUserInfoNevro
          : classes.crUserInfo,
        'cr-user-info',
      )}>
      <Box display='flex' alignItems='center'>
        {user.profileImage ? (
          <Avatar
            className={classes.profilePic}
            src={`${S3_USERS_BASE_URL}${user.profileImage}`}
          />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}
        <Box className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box
              mb={0}
              className={clsx(
                classes.userName,
                useNevro && !isPortalProClinic
                  ? classes.desktopSection
                  : classes.desktopSectionNevro,
              )}>
              {user.displayName ? user.displayName : 'Admin User '}
            </Box>
            <Box className={classes.expandIcon} color='#313541'>
              <IconButton onClick={handleClick} style={{padding: 0}}>
                <ExpandMoreIcon />
              </IconButton>
              <ThemeProvider
                theme={
                  useNevro && !isPortalProClinic
                    ? nevroDropdownTheme
                    : defaultDropdownTheme
                }>
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem onClick={onGoToMyAccount}>My Account</MenuItem>
                  <MenuItem onClick={onGoToChangePassword}>
                    Change Password
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (user) {
                        dispatch(onJWTAuthSignout(history));
                      }
                    }}>
                    Logout
                  </MenuItem>
                </Menu>
              </ThemeProvider>
            </Box>
          </Box>
          <Box
            color={grey.A200}
            className={clsx(
              classes.designation,
              useNevro && !isPortalProClinic
                ? classes.desktopSection
                : classes.desktopSectionNevro,
            )}>
            {user.role ? user.role : 'System Manager'}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(UserInfo);
