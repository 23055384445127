import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup',
        component: React.lazy(() => import('./Signup/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/change-password',
        component: React.lazy(() => import('./ChangePassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/reset-password',
        component: React.lazy(() => import('./ResetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/set-password',
        component: React.lazy(() => import('./ChangePassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/already-set-up',
        component: React.lazy(() => import('./ChangePassword/AlreadySetUp')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/terms-and-conditions',
        component: React.lazy(() => import('./TermsAndConditions')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/my-account',
        component: React.lazy(() => import('./MyAccount')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
];
