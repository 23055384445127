import api from '@e74/services/ApiConfig';
import {convertToInstanceTime} from '@e74/utility/Utils';
import {fetchStart, fetchError, fetchSuccess} from '../Common';
import {selectFilter} from 'redux/reducers/patient/patientappointments';

export const ACTION_TYPES = {
  FETCH_FILTERED: 'FETCH_FILTERED_APPOINTMENTS',
  SET_FILTER_APPOINTMENT_START_DATE: 'SET_APPOINTMENT_LIST_FILTER_START_DATE',
  SET_FILTER_APPOINTMENT_END_DATE:
    'SET_APPOINTMENT_LIST_FILTER_APPOINTMENT_END_DATE',
  SET_FILTER_TOP_DATE: 'SET_APPOINTMENT_LIST_FILTER_TOP_DATE',
  SET_FILTER_FIXED_DATE: 'SET_APPOINTMENT_LIST_FILTER_FIXED_DATE',
  SET_FILTER_DATE_FILTER: 'SET_APPOINTMENT_LIST_FILTER_DATE_FILTER',
  SET_FILTER_PAIN_SCORE_PAIR: 'SET_APPOINTMENT_LIST_FILTER_PAIN_SCORE_PAIR',
  SET_FILTER_BODY_LOCATION_ID: 'SET_APPOINTMENT_LIST_FILTER_BODY_LOCATION_ID',
  SET_FILTER_APPOINTMENT_STATUS_ID:
    'SET_APPOINTMENT_LIST_FILTER_APPOINTMENT_STATUS_ID',
  RESET_FILTER: 'RESET_APPOINTMENT_LIST_FILTER',
};

export const schedule = (data, onSuccess) => (dispatch) => {
  api
    .patientappointments()
    .schedule(data)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateschedule = (data, onSuccess) => (dispatch) => {
  api
    .patientappointments()
    .updateschedule(data)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const checkIn = (data, onSuccess, onError) => (dispatch) => {
  dispatch(fetchStart());
  api
    .patientappointments()
    .checkIn(data)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) => {
      onError && onError(err);
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const fetchFiltered = (request) => (dispatch, getState) => {
  dispatch(fetchStart());
  const filter = selectFilter(getState());
  api
    .patientappointments()
    .fetchFiltered({
      paginationFilter: {
        pageNumber: request?.paginationFilter?.pageNumber || 0,
        pageSize: request?.paginationFilter?.pageSize || 15,
      },
      dateFilter: filter.dateFilter || 0,
      startDate: convertToInstanceTime(filter.startDate) || null,
      endDate: convertToInstanceTime(filter.endDate) || null,
      painScoreMin: filter.painScorePair[0],
      painScoreMax: filter.painScorePair[1],
      bodyLocationId: filter.bodyLocationId || '',
      appointmentStatus: filter.appointmentStatusId || null,
      doctorId: getState().doctor.id,
      departmentId: request
        ? request.departmentId === null
          ? null
          : request.departmentId
        : null,
    })
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_FILTERED, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setFilterDateFilter = (newDateFilter) => (dispatch) => {
  dispatch({type: ACTION_TYPES.SET_FILTER_DATE_FILTER, payload: newDateFilter});
};

export const setFilterAppointmentStartDate = (newDate) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_APPOINTMENT_START_DATE,
    payload: newDate,
  });
};

export const setFilterAppointmentEndDate = (newDate) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_APPOINTMENT_END_DATE,
    payload: newDate,
  });
};

export const setFilterTopDate = (newDate) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_TOP_DATE,
    payload: newDate,
  });
};

export const setFilterFixedDate = (newDate) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_FIXED_DATE,
    payload: newDate,
  });
};

export const setFilterPainScorePair = (newScorePair) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_PAIN_SCORE_PAIR,
    payload: newScorePair,
  });
};

export const setFilterBodyLocationId = (newId) => (dispatch) => {
  dispatch({type: ACTION_TYPES.SET_FILTER_BODY_LOCATION_ID, payload: newId});
};

export const setFilterAppointmentStatusId = (newId) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_APPOINTMENT_STATUS_ID,
    payload: newId,
  });
};

export const resetFilter = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.RESET_FILTER,
  });
  dispatch(fetchFiltered());
};

export const pullChangedAppointments = (onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedAppointments()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
      onSuccess && onSuccess(res);
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const pullChangedAppointmentsForInstance = (onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedAppointmentsForInstance()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
      onSuccess && onSuccess(res);
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const pullChangedAppointmentsForModMed = (onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedAppointmentsForModMed()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
      onSuccess && onSuccess(res);
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const pullChangedAppointmentsForAdvancedMD = (onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedAppointmentsForAdvancedMD()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
      onSuccess && onSuccess(res);
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const pullChangedAppointmentsForGreenway = (onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedAppointmentsForGreenway()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
      onSuccess && onSuccess(res);
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};