import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ApiConfig from '@e74/services/ApiConfig';

const AppLogoWhite = () => {
  const [clinicLogoPrimaryUrl, setClinicLogoPrimaryUrl] = React.useState('');

  React.useEffect(() => {
    ApiConfig.instanceSettings()
      .fetchClinicLogoPrimaryUrl()
      .then((response) => setClinicLogoPrimaryUrl(response.data));
  });

  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
      // backgroundColor: '#D3D3D3',
    },

    logo: {
      height: 125,
      margin: '0 auto',
      objectFit: 'contain',
      padding: 10,
    },

    logoRootNevro: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
      backgroundColor: '#fff',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <img className={classes.logo} src={clinicLogoPrimaryUrl} alt='e74-logo' />
    </Box>
  );
};

export default AppLogoWhite;
