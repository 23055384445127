import moment from 'moment';

export default function GetSessionExpirationTime() {
  let expDate = null;
  if (typeof localStorage !== 'undefined') {
    expDate = localStorage.getItem('expireTime');
    if (expDate) {
      expDate = moment(expDate).toDate();
    }
  }
  return expDate;
}
