import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchError, fetchSuccess} from '../Common';

export const ACTION_TYPES = {
  QuizLoad: 'QuizLoad',
  QuizAnswer: 'QuizAnswer',
  QuizSubmit: 'QuizSubmit',
  PagerUpdate: 'PagerUpdate',
};

export const create = (data, onSuccess, onError) => (dispatch) => {
  dispatch(fetchStart());
  api
    .patientFormSubmission()
    .create(data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.QuizSubmit,
        payload: res.data,
        result: res,
      });
      dispatch(fetchSuccess('Form Response added successfully'));
      onSuccess && onSuccess();
    })
    .catch((err) => {
      onError && onError(err);
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};
