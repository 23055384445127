import api from '../../../@e74/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess, hideMessage} from '../Common';
export const ACTION_TYPES = {
  CREATE: 'CREATE_RESOURCE_TYPE',
  UPDATE: 'UPDATE_RESOURCE_TYPE',
  DELETE: 'DELETE_RESOURCE_TYPE',
  FETCH_ALL: 'FETCH_ALL_RESOURCE_TYPES',
};

export const fetchAll = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .resourceTypes()
    .fetchAll()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        payload: response.data,
      });
      dispatch(fetchSuccess());
      dispatch(hideMessage());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const create = (data, onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .resourceTypes()
    .create(data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.CREATE,
        payload: res.data,
      });
      dispatch(fetchSuccess());
      //dispatch(showMessage('ResourceType added successfully'));
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const update = (id, data, onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .resourceTypes()
    .update(id, data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.UPDATE,
        payload: {id, ...data},
      });
      dispatch(fetchSuccess());
      //dispatch(showMessage('ResourceType updated successfully'));
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const Delete = (id) => (dispatch) => {
  dispatch(fetchStart());
  api
    .resourceTypes()
    .delete(id)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.DELETE,
        payload: id,
      });
      dispatch(fetchSuccess());
      //dispatch(showMessage('ResourceType deleted successfully'));
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
