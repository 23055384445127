import {lazy} from 'react';

export const clinicsPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/clinics/clinicform/:id?',
        component: lazy(() => import('./clinicform')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/clinics/',
        component: lazy(() => import('./cliniclist')),
      },
    ],
  },
];
