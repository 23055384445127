import React, {useEffect, useState} from 'react';
import {Icon, ListItem, ListItemText, withStyles} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Badge, NavLink} from '../../../index';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalItem.style';
import {
  selectEnablePortalPro,
  selectIsPortalProClinic,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import {useDispatch, useSelector} from 'react-redux';
import {EmojiPeopleOutlined, HomeWorkOutlined} from '@material-ui/icons';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ApiConfig from '@e74/services/ApiConfig';

const VerticalItem = ({classes, ...props}) => {
  const {item} = props;
  const useNevro = useSelector(selectUseNevro);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);

  return (
    <ListItem
      button
      component={NavLink}
      to={item.url}
      activeClassName='active'
      className={clsx(
        useNevro && !isPortalProClinic ? classes.navItemNevro : classes.navItem,
        'nav-item',
      )}
      exact={item.exact}>
      {item.icon && (
        <Box
          component='span'
          mr={3}
          style={{display: 'flex', alignItems: 'center'}}>
          {item.icon === 'emojiPeopleOutlined' ? (
            <EmojiPeopleOutlined
              className={clsx(classes.listIcon, 'nav-item-icon')}
            />
          ) : item.icon === 'homeWorkOutlined' ? (
            <HomeWorkOutlined
              className={clsx(classes.listIcon, 'nav-item-icon')}
            />
          ) : item.icon === 'HeadsetMic' ? (
            <HeadsetMicIcon />
          ) : (
            <Icon
              className={clsx(classes.listIcon, 'nav-item-icon')}
              color='action'>
              {item.icon}
            </Icon>
          )}
        </Box>
      )}
      <ListItemText
        primary={<IntlMessages id={item.messageId} />}
        classes={{primary: 'nav-item-text'}}
      />
      {item.count && (
        <Box mr={1} clone>
          <Badge count={item.count} color={item.color} />
        </Box>
      )}
    </ListItem>
  );
};

VerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

VerticalItem.defaultProps = {};

export default withStyles(useStyles)(withRouter(React.memo(VerticalItem)));
