import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box, Card, Typography, makeStyles} from '@material-ui/core';

const Loader = (props) => {
  const useStyles = makeStyles(() => ({
    cardRoot: {
      boxShadow: '10px 10px 135px 0px rgba(0,0,0,0.75) !important',
      padding: '1rem 2.5rem',
      borderRadius: '10px',
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-evenly',
      alignItems:'center'
    },
  }));
  const classes = useStyles();

  return (
    <Box
      height='100%'
      display='flex'
      flex={1}
      alignItems='center'
      justifyContent='center'
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}>
      <Card
        className={classes.cardRoot}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress />
        {props.message && (
          <Typography color='primary' variant='subtitle1'>
            {props.message}
          </Typography>
        )}
        {props.message && <Typography>{props.message}</Typography>}
      </Card>
    </Box>
  );
};

export default Loader;
