import api from '@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_ALL: 'FETCH_ALL_PATIENT_SEARCH_RESULTS',
  SET_SHOW_RESULTS: 'SET_SHOW_RESULTS',
};

export const fetchAll = (query) => (dispatch, getState) => {
  dispatch(fetchStart());
  api
    .patient()
    .search(query, 300, 0, getState().doctor.id)
    .then((res) => {
      dispatch({type: ACTION_TYPES.FETCH_ALL, payload: res.data});
      dispatch(fetchSuccess());
      dispatch(setShowResults(true));
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setShowResults = (newValue) => (dispatch) => {
  dispatch({type: ACTION_TYPES.SET_SHOW_RESULTS, payload: newValue});
};
