import React from 'react';
import {Box, makeStyles} from '@material-ui/core';

const HipaaLogo = () => {
  /* styling */
  const useStyles = makeStyles(() => ({
    root: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      zIndex: 1,
      cursor: 'pointer',
      backgroundColor: '#dfd5ff',
      paddingTop: 10,
    },
    logo: {
      height: 100,
      margin: '0 auto',
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img
        className={classes.logo}
        alt='Hipaa Logo'
        src={require('assets/images/hipaa.png').default}
      />
    </Box>
  );
};

export default HipaaLogo;
