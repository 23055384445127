import {lazy} from 'react';

export const instancesPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/instances/instanceform/:id?',
        component: lazy(() => import('./instanceform')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/instances/',
        component: lazy(() => import('./instanceslist')),
      },
    ],
  },
];
