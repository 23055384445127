import {ReportSummaryDataType} from 'shared/constants/AppEnums';
import {ACTION_TYPES} from '../../actions/reports/summary';

const initialState = {
  list: {},
  filter: {
    studyGroups: [],
    visitRange: [0, 24],
    ageRange: [0, 100],
    painScoreRange: [0, 10],
    bmiRange: [18, 50],
    bodyLocations: [],
    genders: [],
    occupations: [],
    maritalStatus: [],
    nicotineStatus: [],
    payorStatus: [],
    diseaseStates: [],
    psychaitricStatus: [],
    surgicalHistory: [],
    patientId: '',
    patientName: '',
    randScoreRange: [0, 0],
    perioperativeMessageTemplateIds: [],
    formIds: [],
    reportSummaryDataType: ReportSummaryDataType.PRO,
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SUMMARY:
      return {...state, list: action.payload};
    case ACTION_TYPES.SET_FILTER_STUDY_GROUPS:
      return {...state, filter: {...state.filter, studyGroups: action.payload}};
    case ACTION_TYPES.SET_FILTER_VISIT_RANGE:
      return {
        ...state,
        filter: {...state.filter, visitRange: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_AGE_RANGE:
      return {
        ...state,
        filter: {...state.filter, ageRange: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PAIN_SCORE_RANGE:
      return {
        ...state,
        filter: {...state.filter, painScoreRange: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_BMI_RANGE:
      return {
        ...state,
        filter: {...state.filter, bmiRange: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_BODY_LOCATIONS:
      return {
        ...state,
        filter: {...state.filter, bodyLocations: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_GENDERS:
      return {...state, filter: {...state.filter, genders: action.payload}};
    case ACTION_TYPES.SET_FILTER_OCCUPATIONS:
      return {...state, filter: {...state.filter, occupations: action.payload}};
    case ACTION_TYPES.SET_FILTER_MARITAL_STATUS:
      return {
        ...state,
        filter: {...state.filter, maritalStatus: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PAYOR_STATUS:
      return {...state, filter: {...state.filter, payorStatus: action.payload}};
    case ACTION_TYPES.SET_FILTER_NICOTINE_STATUS:
      return {
        ...state,
        filter: {...state.filter, nicotineStatus: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_DISEASE_STATES:
      return {
        ...state,
        filter: {...state.filter, diseaseStates: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PSYCHAITRIC_STATUS:
      return {
        ...state,
        filter: {...state.filter, psychaitricStatus: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_SURGICAL_HISTORY:
      return {
        ...state,
        filter: {...state.filter, surgicalHistory: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PATIENT_NAME:
      return {
        ...state,
        filter: {...state.filter, patientName: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_RAND_SCORE_RANGE:
      return {
        ...state,
        filter: {...state.filter, randScoreRange: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_PERIOPERATIVE_MESSAGE_TEMPLATE_IDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          perioperativeMessageTemplateIds: action.payload,
        },
      };
    case ACTION_TYPES.SET_FILTER_FORM_IDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          formIds: action.payload,
        },
      };
    case ACTION_TYPES.SET_FILTER_REPORT_SUMMARY_DATA_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          reportSummaryDataType: action.payload,
        },
      };
    case ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      };
    default:
      return state;
  }
};

export const selectFilterRandScoreRange = (state) =>
  state.summary.filter.randScoreRange;

export const selectFilterPerioperativeMessageTemplateIds = (state) =>
  state.summary.filter.perioperativeMessageTemplateIds;

export const selectFilterFormIds = (state) => state.summary.filter.formIds;

export const selectFilterReportSummaryDataType = (state) =>
  state.summary.filter.reportSummaryDataType;
