import {ACTION_TYPES} from '../../actions/user-messages/user-messages';

const initialState = {
  data: {
    messages: [],
    users: [],
    currentUserId: '',
  },
  ui: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USERS:
      return {...state, data: {...state.data, users: action.payload}};
    case ACTION_TYPES.FETCH_FILTERED_MESSAGES:
      return {...state, data: {...state.data, messages: action.payload}};
    case ACTION_TYPES.SET_CURRENT_USER_ID:
      return {
        ...state,
        data: {...state.data, currentUserId: action.payload},
      };
    default:
      return state;
  }
};

export const selectUsers = (state) => state.userMessages.data.users;
export const selectMessages = (state) => state.userMessages.data.messages;
export const selectCurrentUserId = (state) =>
  state.userMessages.data.currentUserId;
export const selectCurrentUser = (state) =>
  state.userMessages.data.users.find(
    (user) => user.id === selectCurrentUserId(state),
  );
