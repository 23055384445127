import api from '@e74/services/ApiConfig';
import {fetchError, fetchSuccess} from '../Common';
export const ACTION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  FETCH_ALL: 'FETCH_ALL',
  FETCH_BY_ID: 'FETCH_BY_ID',
};

export const fetchAll = (pageSize, pageNumber) => (dispatch) => {
  api
    .resources()
    .fetchAll(pageSize, pageNumber)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        payload: response.data,
      });
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const create = (data, onSuccess, onError) => {
  return async (dispatch) => {
    api
      .resources()
      .create(data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch({
            type: ACTION_TYPES.CREATE,
            payload: res.data,
          });
          dispatch(fetchSuccess());
          onSuccess();
        } else {
          dispatch(fetchError(res.response.data.error));
          dispatch(fetchError(res));
          onError(res.errors);
        }
      })
      .catch((err) => {
        onError(err?.response?.data?.errors, err?.message);
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err));
      });
  };
};

export const update = (id, data, onSuccess, onError) => (dispatch) => {
  api
    .resources()
    .update(id, data)
    .then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        dispatch({
          type: ACTION_TYPES.UPDATE,
          payload: res.data,
        });
        dispatch(fetchSuccess());
        onSuccess();
      } else {
        onError(res.data.errors, res.data.message);
        dispatch(fetchError(res.response.data.error));
        dispatch(fetchError(res));
      }
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const Delete = (id, onSuccess) => (dispatch) => {
  api
    .resources()
    .delete(id)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.DELETE,
        payload: id,
      });
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchById = (id, onSuccess) => (dispatch) => {
  api
    .resources()
    .fetchById(id)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.FETCH_BY_ID,
        payload: id,
      });
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
