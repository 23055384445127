import { ACTION_TYPES } from 'redux/actions/admin/instance-settings';
import { Configuration } from 'shared/constants/AppEnums';

const initialState = {
  data: {
    list: {
      defaultEmailSender: '',
      defaultSmsNumber: '',
      practiceName: '',
      clinicName: '',
      defaultState: '',
      enableSms: false,
      enableEmail: false,
      enablePatientSelfCheckin: false,
      defaultRandForm: '',
      applicableForms: [],
      timeZoneId: '',
      proRate: 0,
      rpmRate: 0,
      clinicLogoPrimaryFileName: '',
      clinicLogoPrimaryFile: null,
      clinicLogoSecondaryFileName: '',
      clinicLogoSecondaryFile: null,
      practiceLogoFileName: '',
      practiceLogoFile: null,
      useAthena: false,
      athenaPracticeId: '',
      athenaDepartmentIds: '',
      extendedPatientAskAssignedSexAtBirth: false,
      extendedPatientAskSexualOrientation: false,
      extendedPatientAskPreferredPronouns: false,
      useRpm: false,
      athenaProviderIds: '',
      extendedPatientAskCommunicationPreferences: false,
      showBmiFilterOnSandbox: false,
      patientAppointmentReasonAdditionalQuestionId: '',
      patientAppointmentReasonNewPatientAdditionalQuestionOptionId: '',
      additionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason: '',
      intakeFormNotificationHours: '',
      showIntakeFormForNewPatients: false,
      patientAppointmentReasonFollowUpAdditionalQuestionOptionId: '',
      enablePaymentCollection: false,
      showOptInToConsentPage: false,
      enableDemoDashboard: false,
      demoPatientId: '',
      demoPatientAppointmentId: '',
      demoPatientExternalId: '',
      enableEducationalVideosPage: false,
      athenaTherapistIds: '',
      reactivDiagnosisCodes: '',
      reactivOverrideDate: '',
      reactivEmailRecipients: '',
      useReactiv: false,
      reactivProviders: '',
      athanaOrthopedicIds: '',
      enableUdsStatusReminder: false,
      clinicAddress: '',
      enableHomeCheckin: false,
      clinicEmail: '',
      enableProcedureNote: false,
      enableShotConsent: false,
      therapyManagerNumber: '',
      welcomeScreenEnabled: false,
      isPharmacyRequired: false,
      hideExitPro: false,
      apptCancelEmails: '',
      apptCancelEmailBody: '',
      deploymentDate: '',
      skipPaymentDepartments: '',
      useModMed: false,
      modMedAccessTokenUrl: '',
      modMedResourceUrl: '',
      modMedUsername: '',
      modMedPassword: '',
      modMedApiKey: '',
      consentExpiration: '',
      healthHistoryReconsiliation: '',
      modMedDocMappingDefault: '',
      modMedDocMappingPatientAgreement: '',
      modMedDocMappingOnePager: '',
      modMedDocMappingScreener: '',
      useLucidLane: false,
      orderTypeId: '',
      lucidLaneNotificationEmail: '',
      customFormSubmissionEmails: '',
      upsideAppointmentId: '',
      // useNevro: false,
      clinicPhoneNumber: '',
      checkinConfirmationPage: '',
      enableProviderAppointmentDashboard: '',
      useDrChrono: false,
      drChronoClientId: '',
      drChronoClientSecret: '',
      drChronoRedirectUrl: '',
      drChronoWebhookSecret: '',
      useMainstay: false,
      defaultPainScore: '',
      useAdvancedMD: false,
      advancedMDUsername: '',
      advancedMDPassword: '',
      advancedMDOfficeCode: '',
      advancedMDAppName: '',
      advancedMDInitialUrl: '',
      advancedMDTokenDetails: '',
      nevroSalesforceApiVersion: '',
      nevroSalesforceClientId: '',
      nevroSalesforceClientSecret: '',
      nevroSalesforceTokenUrl: '',
      nevroSalesforceIntrospectUrl: '',
      newAppointmentDashboard: false,
      enableDigitalShotConsent: false,
      useGreenway: false,
      greenwayApiKey: '',
      greenwayPracticeId: '',
      greenwayServiceCenterIds: '',
      greenwayProviderIds: '',
      greenwayUsername: '',
      greenwayPassword: '',
      greenwayLicenseId: '',
      greenwayUserLogon: '',
      greenwayApplicationName: '',
      greenwayMachineName: '',
      greenwayDocMappingDefault: '',
      greenwayDocMappingPatientAgreement: '',
      greenwayDocMappingOnePager: '',
      greenwayDocMappingScreener: '',
      greenwaySessionDetails: '',
      newAppointmentDashboard: false,
      enableDigitalShotConsent: false,
      customQuestionDepartmentIds: '',
      autoLogoutTime: '',
      useCodePull: false,
      greenwayDocMappingOnePager: '',
      enablePortalPro: false,
      greenwayDocMappingScreener: '',
      greenwaySessionDetails: '',
      enableMrnLookup: false,
      enablePsycEval: false,
      campaignEmails: '',
      mappedAlgosClinicInstanceId: '',
      useEClinicalWorks: false,
      eclinicalWorksClientId: '',
      eclinicalWorksClientSecret: '',
      eclinicalWorksResourceUrl: '',
      eclinicalWorksRedirectUrl: '',
      eclinicalWorksCodeVerifier: '',
      eclinicalWorksCodeChallenge: '',
      eclinicalWorksTokenDetails: '',
      eclinicalWorksBulkClientId: '',
      eclinicalWorksBulkGroupId: '',
      eclinicalWorksBulkPrivateKeyFileName: '',
      eclinicalWorksBulkPrivateKeyFile: null,
    },
    timeZoneOffsetHours: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            defaultEmailSender: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Default Email Sender'],
            )?.configurationValue,
            defaultSmsNumber: action.payload.find(
              (x) => x.configurationId === Configuration['Default Sms Number'],
            )?.configurationValue,
            clinicName: action.payload.find(
              (x) => x.configurationId === Configuration['Clinic Name'],
            )?.configurationValue,
            defaultState: action.payload.find(
              (x) => x.configurationId === Configuration['Default State'],
            )?.configurationValue,
            enableSms:
              action.payload.find(
                (x) => x.configurationId === Configuration['Enable Sms'],
              )?.configurationValue === 'true',
            enableEmail:
              action.payload.find(
                (x) => x.configurationId === Configuration['Enable Email'],
              )?.configurationValue === 'true',
            enablePatientSelfCheckin:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Enable Patient Self Checkin'],
              )?.configurationValue === 'true',
            defaultRandForm: action.payload.find(
              (x) => x.configurationId === Configuration['Default Rand Form'],
            )?.configurationValue,
            applicableForms: action.payload
              .filter(
                (x) => x.configurationId === Configuration['Applicable Forms'],
              )
              .map((x) => +x.configurationValue),
            timeZoneId: action.payload.find(
              (x) => x.configurationId === Configuration['Time Zone'],
            )?.configurationValue,
            proRate: action.payload.find(
              (x) => x.configurationId === Configuration['Pro Rate'],
            )?.configurationValue,
            rpmRate: action.payload.find(
              (x) => x.configurationId === Configuration['Rpm Rate'],
            )?.configurationValue,
            clinicLogoPrimaryFileName: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Clinic Logo Primary File Name'],
            )?.configurationValue,
            clinicLogoSecondaryFileName: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Clinic Logo Secondary File Name'],
            )?.configurationValue,
            practiceLogoFileName: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Practice Logo File Name'],
            )?.configurationValue,
            practiceNewPatientAgreementFileName: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Practice New Patient Agreement File Name'],
            )?.configurationValue,
            useAthena:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Athena'],
              )?.configurationValue === 'true',
            athenaPracticeId: action.payload.find(
              (x) => x.configurationId === Configuration['Athena Practice Id'],
            )?.configurationValue,
            athenaDepartmentIds: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Athena Department Ids'],
            )?.configurationValue,
            extendedPatientAskSexualOrientation:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Extended Patient Ask Sexual Orientation'],
              )?.configurationValue === 'true',
            extendedPatientAskAssignedSexAtBirth:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Extended Patient Ask Assigned Sex At Birth'],
              )?.configurationValue === 'true',
            extendedPatientAskPreferredPronouns:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Extended Patient Ask Preferred Pronouns'],
              )?.configurationValue === 'true',
            useRpm:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Rpm'],
              )?.configurationValue === 'true',
            athenaProviderIds: action.payload.find(
              (x) => x.configurationId === Configuration['Athena Provider Ids'],
            )?.configurationValue,
            extendedPatientAskCommunicationPreferences:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration[
                  'Extended Patient Ask Communication Preferences'
                  ],
              )?.configurationValue === 'true',
            showBmiFilterOnSandbox:
              action.payload.find(
                (x) =>
                  x?.configurationId ===
                  Configuration['Show Bmi Filter On Sandbox'],
              )?.configurationValue === 'true',
            patientAppointmentReasonAdditionalQuestionId: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration[
                'Patient Appointment Reason Additional Question Id'
                ],
            )?.configurationValue,
            patientAppointmentReasonNewPatientAdditionalQuestionOptionId: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration[
                'Patient Appointment Reason New Patient Additional Question Option Id'
                ],
            )?.configurationValue,
            additionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration[
                'Additional Question Ids to only show for New Patient Patient Appointment Reason'
                ],
            )?.configurationValue,
            intakeFormNotificationHours: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration['Intake Form Notification Hours'],
            )?.configurationValue,
            showIntakeFormForNewPatients:
              action.payload.find(
                (x) =>
                  x?.configurationId ===
                  Configuration['Show Intake Form For New Patients'],
              )?.configurationValue === 'true',
            patientAppointmentReasonFollowUpAdditionalQuestionOptionId: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration[
                'Patient Appointment Reason Follow Up Additional Question Option Id'
                ],
            )?.configurationValue,
            enablePaymentCollection:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Enable Payment Collection'],
              )?.configurationValue === 'true',
            showOptInToConsentPage:
              action.payload.find(
                (x) =>
                  x?.configurationId ===
                  Configuration['Show Opt in to Consent Page'],
              )?.configurationValue === 'true',
            enableDemoDashboard:
              action.payload.find(
                (x) =>
                  x?.configurationId === Configuration['Enable Demo Dashboard'],
              )?.configurationValue === 'true',
            demoPatientId: action.payload.find(
              (x) => x?.configurationId === Configuration['Demo Patient Id'],
            )?.configurationValue,
            demoPatientAppointmentId: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration['Demo Patient Appointment Id'],
            )?.configurationValue,
            demoPatientExternalId: action.payload.find(
              (x) =>
                x?.configurationId ===
                Configuration['Demo Patient External Id'],
            )?.configurationValue,
            enableEducationalVideosPage:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Enable Educational Videos Page'],
              )?.configurationValue === 'true',
            athenaTherapistIds: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Therapist Provider Ids'],
            )?.configurationValue,
            practiceNewPatientAgreementSpanishFileName: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration[
                'Practice New Patient Agreement File Name Spanish'
                ],
            )?.configurationValue,
            athenaOrthopedicIds: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Orthopedic Provider Ids'],
            )?.configurationValue,
            enableUdsStatusReminder:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Enable UDS status Reminder'],
              )?.configurationValue === 'true',
            reactivDiagnosisCodes: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Reactiv Diagnosis Codes'],
            )?.configurationValue,
            reactivOverrideDate: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Reactiv Override Date'],
            )?.configurationValue,
            reactivEmailRecipients: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Reactiv Email Recipients'],
            )?.configurationValue,
            useReactiv:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Reactiv'],
              )?.configurationValue === 'true',
            reactivProviders: action.payload.find(
              (x) => x.configurationId === Configuration['Reactiv Providers'],
            )?.configurationValue,
            clinicAddress: action.payload.find(
              (x) => x.configurationId === Configuration['Clinic Address'],
            )?.configurationValue,
            enableHomeCheckin:
              action.payload.find(
                (x) =>
                  x.configurationId === Configuration['Enable Home Checkin'],
              )?.configurationValue === 'true',
            clinicEmail: action.payload.find(
              (x) => x.configurationId === Configuration['Clinic Email'],
            )?.configurationValue,
            enableProcedureNote:
              action.payload.find(
                (x) =>
                  x.configurationId === Configuration['Enable Procedure Note'],
              )?.configurationValue === 'true',
            enableShotConsent:
              action.payload.find(
                (x) =>
                  x.configurationId === Configuration['Enable Shot Consent'],
              )?.configurationValue === 'true',
            therapyManagerNumber: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Therapy Manager Number'],
            )?.configurationValue,
            welcomeScreenEnabled:
              action.payload.find(
                (x) =>
                  x.configurationId === Configuration['Enable Welcome Screen'],
              )?.configurationValue === 'true',
            isPharmacyRequired:
              action.payload.find(
                (x) => x.configurationId === Configuration['Pharmacy Required'],
              )?.configurationValue === 'true',
            hideExitPro:
              action.payload.find(
                (x) => x.configurationId === Configuration['Hide Exit Pro'],
              )?.configurationValue === 'true',
            apptCancelEmails: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Appointment Cancel Emails'],
            )?.configurationValue,
            apptCancelEmailBody: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Appointment Cancel Email Body'],
            )?.configurationValue,
            deploymentDate: action.payload.find(
              (x) => x.configurationId === Configuration['Deployment Date'],
            )?.configurationValue,
            skipPaymentDepartments: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Skip Payment Departments'],
            )?.configurationValue,
            useModMed:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use ModMed'],
              )?.configurationValue === 'true',
            modMedAccessTokenUrl: action.payload.find(
              (x) =>
                x.configurationId === Configuration['ModMed Access Token Url'],
            )?.configurationValue,
            modMedResourceUrl: action.payload.find(
              (x) => x.configurationId === Configuration['ModMed Resource Url'],
            )?.configurationValue,
            modMedUsername: action.payload.find(
              (x) => x.configurationId === Configuration['ModMed Username'],
            )?.configurationValue,
            modMedPassword: action.payload.find(
              (x) => x.configurationId === Configuration['ModMed Password'],
            )?.configurationValue,
            modMedApiKey: action.payload.find(
              (x) => x.configurationId === Configuration['ModMed Api Key'],
            )?.configurationValue,
            modMedDocMappingDefault: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['ModMed Doc Mapping Default'],
            )?.configurationValue,
            modMedDocMappingPatientAgreement: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['ModMed Doc Mapping Patient Agreement'],
            )?.configurationValue,
            modMedDocMappingOnePager: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['ModMed Doc Mapping OnePager'],
            )?.configurationValue,
            modMedDocMappingScreener: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['ModMed Doc Mapping Screener'],
            )?.configurationValue,
            useLucidLane:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Lucid Lane'],
              )?.configurationValue === 'true',
            orderTypeId: action.payload.find(
              (x) => x.configurationId === Configuration['Order Type Id'],
            )?.configurationValue,
            lucidLaneNotificationEmail: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Lucid Lane Notification Email'],
            )?.configurationValue,
            consentExpiration: action.payload.find(
              (x) => x.configurationId === Configuration['Consent Expiration'],
            )?.configurationValue,
            healthHistoryReconsiliation: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Health History Reconsiliation'],
            )?.configurationValue,
            customFormSubmissionEmails: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Custom Form Submission Emails'],
            )?.configurationValue,
            upsideAppointmentId: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Upside AppointmentId'],
            )?.configurationValue,
            checkinConfirmationPage:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Checkin Confirmation Page'],
              )?.configurationValue === 'true',
            enableProviderAppointmentDashboard: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Enable Provider Appointment Dashboard'],
            )?.configurationValue,
            useDrChrono:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use DrChrono'],
              )?.configurationValue === 'true',
            drChronoClientId: action.payload.find(
              (x) => x.configurationId === Configuration['DrChrono Client Id'],
            )?.configurationValue,
            drChronoClientSecret: action.payload.find(
              (x) =>
                x.configurationId === Configuration['DrChrono Client Secret'],
            )?.configurationValue,
            drChronoRedirectUrl: action.payload.find(
              (x) =>
                x.configurationId === Configuration['DrChrono Redirect Url'],
            )?.configurationValue,
            drChronoWebhookSecret: action.payload.find(
              (x) =>
                x.configurationId === Configuration['DrChrono Webhook Secret'],
            )?.configurationValue,
            useNevro:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Nevro'],
              )?.configurationValue === 'true',
            clinicPhoneNumber: action.payload.find(
              (x) => x.configurationId === Configuration['Clinic Phone Number'],
            )?.configurationValue,
            useMainstay:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Mainstay'],
              )?.configurationValue === 'true',
            defaultPainScore: action.payload.find(
              (x) => x.configurationId === Configuration['Default Pain Score'],
            )?.configurationValue,
            useAdvancedMD:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use AdvancedMD'],
              )?.configurationValue === 'true',
            advancedMDUsername: action.payload.find(
              (x) => x.configurationId === Configuration['AdvancedMD Username'],
            )?.configurationValue,
            advancedMDPassword: action.payload.find(
              (x) => x.configurationId === Configuration['AdvancedMD Password'],
            )?.configurationValue,
            advancedMDOfficeCode: action.payload.find(
              (x) =>
                x.configurationId === Configuration['AdvancedMD Office Code'],
            )?.configurationValue,
            advancedMDAppName: action.payload.find(
              (x) => x.configurationId === Configuration['AdvancedMD App Name'],
            )?.configurationValue,
            advancedMDInitialUrl: action.payload.find(
              (x) =>
                x.configurationId === Configuration['AdvancedMD Initial Url'],
            )?.configurationValue,
            nevroSalesforceApiVersion: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Nevro Salesforce Api Version'],
            )?.configurationValue,
            nevroSalesforceClientId: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Nevro Salesforce Client Id'],
            )?.configurationValue,
            nevroSalesforceClientSecret: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Nevro Salesforce Client Secret'],
            )?.configurationValue,
            nevroSalesforceTokenUrl: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Nevro Salesforce Token Url'],
            )?.configurationValue,
            nevroSalesforceIntrospectUrl: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Nevro Salesforce Introspect Url'],
            )?.configurationValue,
            newAppointmentDashboard:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['New Appointment Dashboard'],
              )?.configurationValue === 'true',
            enableDigitalShotConsent:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Enable Digital Shot Consent'],
              )?.configurationValue === 'true',
            customQuestionDepartmentIds: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Custom Question Department Ids'],
            )?.configurationValue,
            autoLogoutTime: action.payload.find(
              (x) => x.configurationId === Configuration['Auto Logout Time'],
            )?.configurationValue,
            useCodePull:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Code Pull'],
              )?.configurationValue === 'true',
            enablePortalPro:
              action.payload.find(
                (x) => x.configurationId === Configuration['Enable Portal Pro'],
              )?.configurationValue === 'true',
            useGreenway:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Greenway'],
              )?.configurationValue === 'true',
            greenwayApiKey: action.payload.find(
              (x) => x.configurationId === Configuration['Greenway Api Key'],
            )?.configurationValue,
            greenwayPracticeId: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Greenway Practice Id'],
            )?.configurationValue,
            greenwayServiceCenterIds: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Greenway Service Center Ids'],
            )?.configurationValue,
            greenwayProviderIds: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Greenway Provider Ids'],
            )?.configurationValue,
            greenwayUsername: action.payload.find(
              (x) => x.configurationId === Configuration['Greenway Username'],
            )?.configurationValue,
            greenwayPassword: action.payload.find(
              (x) => x.configurationId === Configuration['Greenway Password'],
            )?.configurationValue,
            greenwayLicenseId: action.payload.find(
              (x) => x.configurationId === Configuration['Greenway License Id'],
            )?.configurationValue,
            greenwayUserLogon: action.payload.find(
              (x) => x.configurationId === Configuration['Greenway User Logon'],
            )?.configurationValue,
            greenwayApplicationName: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Greenway Application Name'],
            )?.configurationValue,
            greenwayMachineName: action.payload.find(
              (x) =>
                x.configurationId === Configuration['Greenway Machine Name'],
            )?.configurationValue,
            greenwayDocMappingDefault: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Greenway Doc Mapping Default'],
            )?.configurationValue,
            greenwayDocMappingPatientAgreement: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Greenway Doc Mapping Patient Agreement'],
            )?.configurationValue,
            greenwayDocMappingOnePager: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Greenway Doc Mapping OnePager'],
            )?.configurationValue,
            greenwayDocMappingScreener: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Greenway Doc Mapping Screener'],
            )?.configurationValue,
            enableMrnLookup:action.payload.find(
                (x) => x.configurationId === Configuration['Enable MRN Lookup'],
              )?.configurationValue === 'true',
            customQuestionDepartmentIds: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['Custom Question Department Ids'],
            )?.configurationValue,
            autoLogoutTime: action.payload.find(
              (x) => x.configurationId === Configuration['Auto Logout Time'],
            )?.configurationValue,
            useCodePull:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use Code Pull'],
              )?.configurationValue === 'true',
            sendSchedulingDataToSalesforce:
              action.payload.find(
                (x) => x.configurationId === Configuration['Send Scheduling Data To Salesforce'],
              )?.configurationValue === 'true',
            enablePsycEval:
              action.payload.find(
                (x) => x.configurationId === Configuration['Enable Psyc Eval'],
              )?.configurationValue === 'true',
            campaignEmails: action.payload.find(
              (x) => x.configurationId === Configuration['Campaigns Emails'],
            )?.configurationValue,
            mappedAlgosClinicInstanceId:
              action.payload.find(
                (x) =>
                  x.configurationId ===
                  Configuration['Mapped Algos Clinic Instance Id'],
              )?.configurationValue ?? null,
            useEClinicalWorks:
              action.payload.find(
                (x) => x.configurationId === Configuration['Use EClinicalWorks'],
              )?.configurationValue === 'true',
            eclinicalWorksClientId: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Client Id'],
            )?.configurationValue ?? null,
            eclinicalWorksClientSecret: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Client Secret'],
            )?.configurationValue ?? null,
            eclinicalWorksResourceUrl: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Resource Url'],
            )?.configurationValue ?? null,
            eclinicalWorksRedirectUrl: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Redirect Url'],
            )?.configurationValue ?? null,
            eclinicalWorksCodeChallenge: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Code Challenge'],
            )?.configurationValue ?? null,
            eclinicalWorksBulkClientId: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Bulk Client Id'],
            )?.configurationValue ?? null,
            eclinicalWorksBulkGroupId: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Bulk Group Id'],
            )?.configurationValue ?? null,
            eclinicalWorksBulkPrivateKeyFileName: action.payload.find(
              (x) =>
                x.configurationId ===
                Configuration['EClinicalWorks Bulk Private Key File Name'],
            )?.configurationValue ?? null,
          },
        },
      };
    case ACTION_TYPES.SET_DEFAULT_EMAIL_SENDER:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, defaultEmailSender: action.payload },
        },
      };
    case ACTION_TYPES.SET_DEFAULT_SMS_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, defaultSmsNumber: action.payload },
        },
      };
    case ACTION_TYPES.SET_CLINIC_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, clinicName: action.payload },
        },
      };
    case ACTION_TYPES.SET_DEFAULT_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, defaultState: action.payload },
        },
      };
    case ACTION_TYPES.SET_ENABLE_SMS:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, enableSms: action.payload },
        },
      };
    case ACTION_TYPES.SET_ENABLE_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, enableEmail: action.payload },
        },
      };
    case ACTION_TYPES.SET_ENABLE_PATIENT_SELF_CHECKIN:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, enablePatientSelfCheckin: action.payload },
        },
      };
    case ACTION_TYPES.SET_DEFAULT_RAND_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, defaultRandForm: action.payload },
        },
      };
    case ACTION_TYPES.SET_APPLICABLE_FORMS:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, applicableForms: action.payload },
        },
      };
    case ACTION_TYPES.SET_TIME_ZONE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, timeZoneId: action.payload },
        },
      };
    case ACTION_TYPES.SET_PRO_RATE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, proRate: action.payload },
        },
      };
    case ACTION_TYPES.SET_RPM_RATE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, rpmRate: action.payload },
        },
      };
    case ACTION_TYPES.SET_CLINIC_LOGO_PRIMARY_FILE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, clinicLogoPrimaryFileName: action.payload },
        },
      };
    case ACTION_TYPES.SET_CLINIC_LOGO_PRIMARY_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, clinicLogoPrimaryFile: action.payload },
        },
      };
    case ACTION_TYPES.SET_CLINIC_LOGO_SECONDARY_FILE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            clinicLogoSecondaryFileName: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CLINIC_LOGO_SECONDARY_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, clinicLogoSecondaryFile: action.payload },
        },
      };
    case ACTION_TYPES.SET_PRACTICE_LOGO_FILE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, practiceLogoFileName: action.payload },
        },
      };
    case ACTION_TYPES.SET_PRACTICE_LOGO_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, practiceLogoFile: action.payload },
        },
      };
    case ACTION_TYPES.SET_TIME_ZONE_OFFSET_HOURS:
      return {
        ...state,
        data: { ...state.data, timeZoneOffsetHours: action.payload },
      };
    case ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_FILE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            practiceNewPatientAgreementFileName: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            practiceNewPatientAgreementFile: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_ATHENA:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useAthena: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_RPM:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useRpm: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ATHENA_PRACTICE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            athenaPracticeId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ATHENA_DEPARTMENT_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            athenaDepartmentIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_ASSIGNED_SEX_AT_BIRTH:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            extendedPatientAskAssignedSexAtBirth: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_PREFERRED_PRONOUNS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            extendedPatientAskPreferredPronouns: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_SEXUAL_ORIENTATION:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            extendedPatientAskSexualOrientation: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ATHENA_PROVIDER_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            athenaProviderIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_COMMUNICATION_PREFERENCES:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            extendedPatientAskCommunicationPreferences: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_SHOW_BMI_FILTER_ON_SANDBOX:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            showBmiFilterOnSandbox: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_PATIENT_APPOINTMENT_REASON_ADDITIONAL_QUESTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            patientAppointmentReasonAdditionalQuestionId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_PATIENT_APPOINTMENT_REASON_NEW_PATIENT_ADDITIONAL_QUESTION_OPTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            patientAppointmentReasonNewPatientAdditionalQuestionOptionId:
              action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ADDITIONAL_QUESTION_IDS_TO_ONLY_SHOW_FOR_NEW_PATIENT_PATIENT_APPOINTMENT_REASON:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            additionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason:
              action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_INTAKE_FORM_NOTIFICATION_HOURS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            intakeFormNotificationHours: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_SHOW_INTAKE_FORM_FOR_NEW_PATIENTS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            showIntakeFormForNewPatients: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_PATIENT_APPOINTMENT_REASON_FOLLOW_UP_ADDITIONAL_QUESTION_OPTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            patientAppointmentReasonFollowUpAdditionalQuestionOptionId:
              action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_PAYMENT_COLLECTION:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enablePaymentCollection: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_SHOW_OPT_IN_TO_CONSENT_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            showOptInToConsentPage: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_DEMO_DASHBOARD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableDemoDashboard: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DEMO_PATIENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            demoPatientId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DEMO_PATIENT_APPOINTMENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            demoPatientAppointmentId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DEMO_PATIENT_EXTERNAL_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            demoPatientExternalId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_EDUCATIONAL_VIDEOS_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableEducationalVideosPage: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ATHENA_THERAPIST_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            athenaTherapistIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ATHENA_ORTHOPEDIC_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            athenaOrthopedicIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_SPANISH_FILE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            practiceNewPatientAgreementSpanishFileName: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_SPANISH_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            practiceNewPatientAgreementSpanishFile: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_UDS_STATUS_REMINDER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableUdsStatusReminder: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_REACTIV_DIAGNOSIS_CODES:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            reactivDiagnosisCodes: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_REACTIV_OVERRIDE_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            reactivOverrideDate: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_REACTIV_EMAIL_RECIPIENTS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            reactivEmailRecipients: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_REACTIV:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useReactiv: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_REACTIVE_PROVIDERS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            reactivProviders: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CLINIC_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            clinicAddress: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_HOME_CHECKIN:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableHomeCheckin: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CLINIC_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            clinicEmail: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_PROCEDURE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableProcedureNote: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_SHOT_CONSENT:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableShotConsent: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_THERAPY_MANAGER_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            therapyManagerNumber: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_WELCOME_SCREEN_ENABLED:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            welcomeScreenEnabled: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_IS_PHARMACY_REQUIRED:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            isPharmacyRequired: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_HIDE_EXIT_PRO:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            hideExitPro: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_APPT_CANCEL_EMAILS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            apptCancelEmails: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_APPT_CANCEL_EMAIL_BODY:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            apptCancelEmailBody: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DEPLOYMENT_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            deploymentDate: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_SKIP_PAYMENT_DEPARTMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            skipPaymentDepartments: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_LUCID_LANE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useLucidLane: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ORDER_TYPE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            orderTypeId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_LUCID_LANE_NOTIFICATION_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            lucidLaneNotificationEmail: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CONSENT_EXPIRATION:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            consentExpiration: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_HEALTH_HISTORY_RECONSILIATION:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            healthHistoryReconsiliation: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CUSTOM_FORM_SUBMISSION_EMAILS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            customFormSubmissionEmails: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_UPSIDE_APPOINTMENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            upsideAppointmentId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_NEVRO:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useNevro: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CLINIC_PHONENUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            clinicPhoneNumber: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CHECKIN_CONFIRMATION_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            checkinConfirmationPage: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_PROVIDER_APPOINTMENT_DASHBOARD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableProviderAppointmentDashboard: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_DRCHRONO:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useDrChrono: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DRCHRONO_CLIENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            drChronoClientId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DRCHRONO_CLIENT_SECRET:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            drChronoClientSecret: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DRCHRONO_REDIRECT_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            drChronoRedirectUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DRCHRONO_WEBHOOK_SECRET:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            drChronoWebhookSecret: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_MODMED:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useModMed: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_ACCESS_TOKEN_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedAccessTokenUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_RESOURCE_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedResourceUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_USERNAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedUsername: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_PASSWORD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedPassword: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_APIKEY:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedApiKey: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_DOC_MAPPING_DEFAULT:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedDocMappingDefault: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_DOC_MAPPING_PATIENTAGREEMENT:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedDocMappingPatientAgreement: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_DOC_MAPPING_ONEPAGER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedDocMappingOnePager: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_MODMED_DOC_MAPPING_SCREENER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            modMedDocMappingScreener: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_MAINSTAY:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useMainstay: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_DEFAULT_PAIN_SCORE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            defaultPainScore: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_ADVANCEDMD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useAdvancedMD: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ADVANCEDMD_USERNAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            advancedMDUsername: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ADVANCEDMD_PASSWORD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            advancedMDPassword: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ADVANCEDMD_OFFICE_CODE:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            advancedMDOfficeCode: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ADVANCEDMD_APPNAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            advancedMDAppName: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ADVANCEDMD_INITIAL_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            advancedMDInitialUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_NEVRO_SALESFORCE_API_VERSION:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            nevroSalesforceApiVersion: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_NEVRO_SALESFORCE_CLIENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            nevroSalesforceClientId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_NEVRO_SALESFORCE_CLIENT_SECRET:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            nevroSalesforceClientSecret: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_NEVRO_SALESFORCE_TOKEN_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            nevroSalesforceTokenUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_NEVRO_SALESFORCE_INTROSPECT_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            nevroSalesforceIntrospectUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_SHOW_NEW_APPOINTMENT_DASHBOARD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            newAppointmentDashboard: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_DIGITAL_SHOT_CONSENT:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableDigitalShotConsent: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_CUSTOM_QUESTION_DEPARTMENT_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            customQuestionDepartmentIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_AUTO_LOGOUT_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            autoLogoutTime: action.payload,
          },
        },
      };
    case ACTION_TYPES.USE_CODE_PULL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useCodePull: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_PORTAL_PRO:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enablePortalPro: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_IS_PORTAL_PRO_CLINIC:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            isPortalProClinic: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_USE_GREENWAY:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useGreenway: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_API_KEY:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayApiKey: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_PRACTICE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayPracticeId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_SERVICE_CENTER_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayServiceCenterIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_PROVIDER_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayProviderIds: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_USERNAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayUsername: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_PASSWORD:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayPassword: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_LICENSE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayLicenseId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_USER_LOGON:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayUserLogon: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_APPLICATION_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayApplicationName: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_MACHINE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayMachineName: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_DEFAULT:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayDocMappingDefault: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_PATIENTAGREEMENT:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayDocMappingPatientAgreement: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_ONEPAGER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayDocMappingOnePager: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_SCREENER:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            greenwayDocMappingScreener: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_MRN_LOOKUP:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enableMrnLookup: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ENABLE_PSYC_EVAL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            enablePsycEval: action.payload,
          },
        },
      };
      case ACTION_TYPES.SET_CAMPAIGN_EMAILS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            campaignEmails: action.payload,
          },
        },
      };
      case ACTION_TYPES.SET_MAPPED_ALGOS_CLINIC_INSTANCE_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            mappedAlgosClinicInstanceId: action.payload,
          },
        },
      };

    case ACTION_TYPES.SET_USE_ECLINICALWORKS:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            useEClinicalWorks: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_CLIENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            eclinicalWorksClientId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_CLIENT_SECRET:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            eclinicalWorksClientSecret: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_RESOURCE_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            eclinicalWorksResourceUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_REDIRECT_URL:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            eclinicalWorksRedirectUrl: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_BULK_CLIENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            eclinicalWorksBulkClientId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_BULK_GROUP_ID:
      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            eclinicalWorksBulkGroupId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, eclinicalWorksBulkPrivateKeyFileName: action.payload },
        },
      };
    case ACTION_TYPES.SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE:
      return {
        ...state,
        data: {
          ...state.data,
          list: { ...state.data.list, eclinicalWorksBulkPrivateKeyFile: action.payload },
        },
      };
    default:
      return state;
  }
};

export const selectDefaultEmailSender = (state) =>
  state.instanceSettings.data.list.defaultEmailSender;

export const selectDefaultSmsNumber = (state) =>
  state.instanceSettings.data.list.defaultSmsNumber;

export const selectClinicName = (state) =>
  state.instanceSettings.data.list.clinicName;

export const selectDefaultState = (state) =>
  state.instanceSettings.data.list.defaultState;

export const selectEnableSms = (state) =>
  state.instanceSettings.data.list.enableSms;

export const selectEnableEmail = (state) =>
  state.instanceSettings.data.list.enableEmail;

export const selectEnablePatientSelfCheckin = (state) =>
  state.instanceSettings.data.list.enablePatientSelfCheckin;

export const selectDefaultRandForm = (state) =>
  state.instanceSettings.data.list.defaultRandForm;

export const selectApplicableForms = (state) =>
  state.instanceSettings.data.list.applicableForms;

export const selectDefaultPainScore = (state) =>
  state.instanceSettings.data.list.defaultPainScore;

export const selectTimeZoneId = (state) =>
  state.instanceSettings.data.list.timeZoneId;

export const selectProRate = (state) =>
  state.instanceSettings.data.list.proRate;

export const selectRpmRate = (state) =>
  state.instanceSettings.data.list.rpmRate;

export const selectClinicLogoPrimaryFileName = (state) =>
  state.instanceSettings.data.list.clinicLogoPrimaryFileName;

export const selectClinicLogoPrimaryFile = (state) =>
  state.instanceSettings.data.list.clinicLogoPrimaryFile;

export const selectClinicLogoSecondaryFileName = (state) =>
  state.instanceSettings.data.list.clinicLogoSecondaryFileName;

export const selectClinicLogoSecondaryFile = (state) =>
  state.instanceSettings.data.list.clinicLogoSecondaryFile;

export const selectPracticeLogoFileName = (state) =>
  state.instanceSettings.data.list.practiceLogoFileName;

export const selectPracticeLogoFile = (state) =>
  state.instanceSettings.data.list.practiceLogoFile;

export const selectTimeZoneOffsetHours = (state) =>
  state.instanceSettings.data.timeZoneOffsetHours;

export const selectPracticeNewPatientAgreementFileName = (state) =>
  state.instanceSettings.data.list.practiceNewPatientAgreementFileName;

export const selectPracticeNewPatientAgreementSpanishFileName = (state) =>
  state.instanceSettings.data.list.practiceNewPatientAgreementSpanishFileName;

export const selectPracticeNewPatientAgreementFile = (state) =>
  state.instanceSettings.data.list.practiceNewPatientAgreementFile;

export const selectPracticeNewPatientAgreementSpanishFile = (state) =>
  state.instanceSettings.data.list.practiceNewPatientAgreementSpanishFile;

export const selectUseAthena = (state) =>
  state.instanceSettings.data.list.useAthena;

export const selectAthenaPracticeId = (state) =>
  state.instanceSettings.data.list.athenaPracticeId;

export const selectAthenaDepartmentIds = (state) =>
  state.instanceSettings.data.list.athenaDepartmentIds;

export const selectUseEhr = (state) =>
  state.instanceSettings.data.list.useAthena;

export const selectExtendedPatientAskSexualOrientation = (state) =>
  state.instanceSettings.data.list.extendedPatientAskSexualOrientation;
export const selectExtendedPatientAskAssignedSexAtBirth = (state) =>
  state.instanceSettings.data.list.extendedPatientAskAssignedSexAtBirth;
export const selectExtendedPatientAskPreferredPronouns = (state) =>
  state.instanceSettings.data.list.extendedPatientAskPreferredPronouns;

export const selectUseRpm = (state) => state.instanceSettings.data.list.useRpm;

export const selectAthenaProviderIds = (state) =>
  state.instanceSettings.data.list.athenaProviderIds;

export const selectExtendedPatientAskCommunicationPreferences = (state) =>
  state.instanceSettings.data.list.extendedPatientAskCommunicationPreferences;

export const selectShowBmiFilterOnSandbox = (state) =>
  state.instanceSettings.data.list.showBmiFilterOnSandbox;

export const selectPatientAppointmentReasonAdditionalQuestionId = (state) =>
  state.instanceSettings.data.list.patientAppointmentReasonAdditionalQuestionId;

export const selectPatientAppointmentReasonNewPatientAdditionalQuestionOptionId = (
  state,
) =>
  state.instanceSettings.data.list
    .patientAppointmentReasonNewPatientAdditionalQuestionOptionId;

export const selectAdditionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason = (
  state,
) =>
  state.instanceSettings.data.list
    .additionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason;

export const selectIntakeFormNotificationHours = (state) =>
  state.instanceSettings.data.list.intakeFormNotificationHours;

export const selectShowIntakeFormForNewPatients = (state) =>
  state.instanceSettings.data.list.showIntakeFormForNewPatients;

export const selectPatientAppointmentReasonFollowUpAdditionalQuestionOptionId = (
  state,
) =>
  state.instanceSettings.data.list
    .patientAppointmentReasonFollowUpAdditionalQuestionOptionId;

export const selectEnablePaymentCollection = (state) =>
  state.instanceSettings.data.list.enablePaymentCollection;
export const selectShowOptInToConsentPage = (state) =>
  state.instanceSettings.data.list.showOptInToConsentPage;

export const selectEnableDemoDashboard = (state) =>
  state.instanceSettings.data.list.enableDemoDashboard;

export const selectDemoPatientId = (state) =>
  state.instanceSettings.data.list.demoPatientId;

export const selectDemoPatientAppointmentId = (state) =>
  state.instanceSettings.data.list.demoPatientAppointmentId;

export const selectDemoPatientExternalId = (state) =>
  state.instanceSettings.data.list.demoPatientExternalId;

export const selectEnableEducationalVideosPage = (state) =>
  state.instanceSettings.data.list.enableEducationalVideosPage;

export const selectAthenaTherapistIds = (state) =>
  state.instanceSettings.data.list.athenaTherapistIds;

export const selectAthenaOrthopedicIds = (state) =>
  state.instanceSettings.data.list.athenaOrthopedicIds;

export const selectEnableUdsStatusReminder = (state) =>
  state.instanceSettings.data.list.enableUdsStatusReminder;

export const selectReactivDiagnosisCodes = (state) =>
  state.instanceSettings.data.list.reactivDiagnosisCodes;

export const selectReactivOverrideDate = (state) =>
  state.instanceSettings.data.list.reactivOverrideDate;

export const selectReactivEmailRecipients = (state) =>
  state.instanceSettings.data.list.reactivEmailRecipients;

export const selectUseReactiv = (state) =>
  state.instanceSettings.data.list.useReactiv;

export const selectReactivProviders = (state) =>
  state.instanceSettings.data.list.reactivProviders;

export const selectClinicAddress = (state) =>
  state.instanceSettings.data.list.clinicAddress;

export const selectEnableHomeCheckin = (state) =>
  state.instanceSettings.data.list.enableHomeCheckin;

export const selectClinicEmail = (state) =>
  state.instanceSettings.data.list.clinicEmail;

export const selectEnableProcedureNote = (state) =>
  state.instanceSettings.data.list.enableProcedureNote;

export const selectEnableShotConsent = (state) =>
  state.instanceSettings.data.list.enableShotConsent;

export const selectTherapyManagerNumber = (state) =>
  state.instanceSettings.data.list.therapyManagerNumber;

export const selectWelcomeScreenEnabled = (state) =>
  state.instanceSettings.data.list.welcomeScreenEnabled;

export const selectIsPharmacyRequired = (state) =>
  state.instanceSettings.data.list.isPharmacyRequired;

export const selectHideExitPro = (state) =>
  state.instanceSettings.data.list.hideExitPro;

export const selectApptCancelEmails = (state) =>
  state.instanceSettings.data.list.apptCancelEmails;

export const selectApptCancelEmailBody = (state) =>
  state.instanceSettings.data.list.apptCancelEmailBody;

export const selectDeploymentDate = (state) =>
  state.instanceSettings.data.list.deploymentDate;

export const selectSkipPaymentDepartments = (state) =>
  state.instanceSettings.data.list.skipPaymentDepartments;

export const selectUseModMed = (state) =>
  state.instanceSettings.data.list.useModMed;

export const selectModMedAccessTokenUrl = (state) =>
  state.instanceSettings.data.list.modMedAccessTokenUrl;

export const selectModMedResourceUrl = (state) =>
  state.instanceSettings.data.list.modMedResourceUrl;

export const selectModMedUsername = (state) =>
  state.instanceSettings.data.list.modMedUsername;

export const selectModMedPassword = (state) =>
  state.instanceSettings.data.list.modMedPassword;

export const selectModMedApiKey = (state) =>
  state.instanceSettings.data.list.modMedApiKey;

export const selectModMedDocMappingDefault = (state) =>
  state.instanceSettings.data.list.modMedDocMappingDefault;

export const selectModMedDocMappingPatientAgreement = (state) =>
  state.instanceSettings.data.list.modMedDocMappingPatientAgreement;

export const selectModMedDocMappingOnePager = (state) =>
  state.instanceSettings.data.list.modMedDocMappingOnePager;

export const selectModMedDocMappingScreener = (state) =>
  state.instanceSettings.data.list.modMedDocMappingScreener;
export const selectConsentExpiration = (state) =>
  state.instanceSettings.data.list.consentExpiration;

export const selectHealthHistoryReconsiliation = (state) =>
  state.instanceSettings.data.list.healthHistoryReconsiliation;

export const selectUseLucidLane = (state) =>
  state.instanceSettings.data.list.useLucidLane;

export const selectOrderTypeId = (state) =>
  state.instanceSettings.data.list.orderTypeId;

export const selectLucidLaneNotificationEmail = (state) =>
  state.instanceSettings.data.list.lucidLaneNotificationEmail;

export const selectCustomFormSubmissionEmails = (state) =>
  state.instanceSettings.data.list.customFormSubmissionEmails;

export const selectUpsideAppointmentId = (state) =>
  state.instanceSettings.data.list.upsideAppointmentId;

export const selectCheckinConfirmationPage = (state) =>
  state.instanceSettings.data.list.checkinConfirmationPage;

export const selectEnableProviderAppointmentDashboard = (state) =>
  state.instanceSettings.data.list.enableProviderAppointmentDashboard;

export const selectUseDrChrono = (state) =>
  state.instanceSettings.data.list.useDrChrono;

export const selectDrChronoClientId = (state) =>
  state.instanceSettings.data.list.drChronoClientId;

export const selectDrChronoClientSecret = (state) =>
  state.instanceSettings.data.list.drChronoClientSecret;

export const selectDrChronoRedirectUrl = (state) =>
  state.instanceSettings.data.list.drChronoRedirectUrl;

export const selectDrChronoWebhookSecret = (state) =>
  state.instanceSettings.data.list.drChronoWebhookSecret;

export const selectUseNevro = (state) =>
  state.instanceSettings.data.list.useNevro;

export const selectClinicPhoneNumber = (state) =>
  state.instanceSettings.data.list.clinicPhoneNumber;

export const selectUseMainstay = (state) =>
  state.instanceSettings.data.list.useMainstay;

export const selectUseAdvancedMD = (state) =>
  state.instanceSettings.data.list.useAdvancedMD;

export const selectAdvancedMDUsername = (state) =>
  state.instanceSettings.data.list.advancedMDUsername;

export const selectAdvancedMDPassword = (state) =>
  state.instanceSettings.data.list.advancedMDPassword;

export const selectAdvancedMDOfficeCode = (state) =>
  state.instanceSettings.data.list.advancedMDOfficeCode;

export const selectAdvancedMDAppName = (state) =>
  state.instanceSettings.data.list.advancedMDAppName;

export const selectAdvancedMDInitialUrl = (state) =>
  state.instanceSettings.data.list.advancedMDInitialUrl;

export const selectNevroSalesforceApiVersion = (state) =>
  state.instanceSettings.data.list.nevroSalesforceApiVersion;

export const selectNevroSalesforceClientId = (state) =>
  state.instanceSettings.data.list.nevroSalesforceClientId;

export const selectNevroSalesforceClientSecret = (state) =>
  state.instanceSettings.data.list.nevroSalesforceClientSecret;

export const selectNevroSalesforceTokenUrl = (state) =>
  state.instanceSettings.data.list.nevroSalesforceTokenUrl;

export const selectNevroSalesforceIntrospectUrl = (state) =>
  state.instanceSettings.data.list.nevroSalesforceIntrospectUrl;

export const selectShowNewAppointmentDashboard = (state) =>
  state.instanceSettings.data.list.newAppointmentDashboard;

export const selectEnableDigitalShotConsent = (state) =>
  state.instanceSettings.data.list.enableDigitalShotConsent;

export const selectCustomQuestionDepartmentIds = (state) =>
  state.instanceSettings.data.list.customQuestionDepartmentIds;

export const selectAutoLogoutTime = (state) =>
  state.instanceSettings.data.list.autoLogoutTime;

export const selectUseCodePull = (state) =>
  state.instanceSettings.data.list.useCodePull;

export const selectEnablePortalPro = (state) =>
  state.instanceSettings.data.list.enablePortalPro;

export const selectIsPortalProClinic = (state) =>
  state.instanceSettings.data.list.isPortalProClinic;

export const selectUseGreenway = (state) =>
  state.instanceSettings.data.list.useGreenway;

export const selectGreenwayApiKey = (state) =>
  state.instanceSettings.data.list.greenwayApiKey;

export const selectGreenwayPracticeId = (state) =>
  state.instanceSettings.data.list.greenwayPracticeId;

export const selectGreenwayServiceCenterIds = (state) =>
  state.instanceSettings.data.list.greenwayServiceCenterIds;

export const selectGreenwayProviderIds = (state) =>
  state.instanceSettings.data.list.greenwayProviderIds;

export const selectGreenwayUsername = (state) =>
  state.instanceSettings.data.list.greenwayUsername;

export const selectGreenwayPassword = (state) =>
  state.instanceSettings.data.list.greenwayPassword;

export const selectGreenwayLicenseId = (state) =>
  state.instanceSettings.data.list.greenwayLicenseId;

export const selectGreenwayUserLogon = (state) =>
  state.instanceSettings.data.list.greenwayUserLogon;

export const selectGreenwayApplicationName = (state) =>
  state.instanceSettings.data.list.greenwayApplicationName;

export const selectGreenwayMachineName = (state) =>
  state.instanceSettings.data.list.greenwayMachineName;

export const selectGreenwayDocMappingDefault = (state) =>
  state.instanceSettings.data.list.greenwayDocMappingDefault;

export const selectGreenwayDocMappingPatientAgreement = (state) =>
  state.instanceSettings.data.list.greenwayDocMappingPatientAgreement;

export const selectGreenwayDocMappingOnePager = (state) =>
  state.instanceSettings.data.list.greenwayDocMappingOnePager;

export const selectGreenwayDocMappingScreener = (state) =>
  state.instanceSettings.data.list.greenwayDocMappingScreener;

export const selectEnableMrnLookup = (state) =>
  state.instanceSettings.data.list.enableMrnLookup;

export const selectEnablePsycEval = (state) =>
  state.instanceSettings.data.list.enablePsycEval;

export const selectCampaignEmails = (state) =>
  state.instanceSettings.data.list.campaignEmails;

export const selectMappedAlgosClinicInstanceId = (state) =>
  state.instanceSettings.data.list.mappedAlgosClinicInstanceId;

export const selectUseEClinicalWorks = (state) =>
  state.instanceSettings.data.list.useEClinicalWorks;

export const selectEClinicalWorksClientId = (state) =>
  state.instanceSettings.data.list.eclinicalWorksClientId;

export const selectEClinicalWorksClientSecret = (state) =>
  state.instanceSettings.data.list.eclinicalWorksClientSecret;

export const selectEClinicalWorksResourceUrl = (state) =>
  state.instanceSettings.data.list.eclinicalWorksResourceUrl;

export const selectEClinicalWorksRedirectUrl = (state) =>
  state.instanceSettings.data.list.eclinicalWorksRedirectUrl;

export const selectEClinicalWorksCodeChallenge = (state) =>
  state.instanceSettings.data.list.eclinicalWorksCodeChallenge;

export const selectEClinicalWorksBulkClientId = (state) =>
  state.instanceSettings.data.list.eclinicalWorksBulkClientId;

export const selectEClinicalWorksBulkGroupId = (state) =>
  state.instanceSettings.data.list.eclinicalWorksBulkGroupId;

export const selectEClinicalWorksBulkPrivateKeyFileName = (state) =>
  state.instanceSettings.data.list.eclinicalWorksBulkPrivateKeyFileName;