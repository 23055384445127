import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {onJWTAuthSignout, setInitialPath} from '../../redux/actions';
import {matchRoutes} from 'react-router-config';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {initialUrl} from '../../shared/constants/AppConst';
import {
  selectAutoLogoutTime,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import moment from 'moment';
import GetSessionExpirationTime from './SessionExpiration';
import ApiConfig from '@e74/services/ApiConfig';

const AuthRoutes = ({children}) => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {routes} = useContext(AppContext);
  const useNevro = useSelector(selectUseNevro);
  const [user, loading] = useAuthToken();
  const initialPath = useSelector(({settings}) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  const events = ['click', 'keydown', 'scroll'];
  const [autoLogoutTime, setAutoLogoutTime] = useState('');

  let expTime = GetSessionExpirationTime();
  let timeOut = React.useRef();

  const resetTimer = () => {
    clearTimeout(timeOut);
    if (user && localStorage.getItem('expireTime')) {
      let defaultTimeout = 30;
      let defaultTime = 1800000;

      if (!useNevro && autoLogoutTime !== '') {
        defaultTime = 60000 * parseInt(autoLogoutTime);
      }

      if (!useNevro && autoLogoutTime !== '') {
        defaultTimeout = parseInt(autoLogoutTime);
      }

      localStorage.setItem(
        'expireTime',
        moment(new Date()).add(defaultTimeout, 'm').toDate().toJSON(),
      );

      timeOut = window.setTimeout(checkExp, defaultTime);
    }
  };

  const checkExp = () => {
    let expireTime = GetSessionExpirationTime();

    if (expireTime != null && new Date() > expireTime) {
      // localStorage.removeItem('user');
      // localStorage.removeItem('expireTime');
      // history.push('/');

      dispatch(onJWTAuthSignout(history));
    }
  };

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        dispatch(setInitialPath(pathname));
      }
    }

    setInitPath();
  }, [dispatch, initialPath, loading, pathname, user]);

  useEffect(() => {
    ApiConfig.instanceSettings()
      .getAutoLogoutTime()
      .then((response) => {
        setAutoLogoutTime(() => {
          return response.data;
        });
      })
      .finally(() => {
        resetTimer();
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!user && currentRoute.auth && currentRoute.auth.length >= 1) {
        history.push('/signin');
      } else if (
        (pathname === '/signin' ||
          pathname === '/signup' ||
          pathname === '/confirm-signup' ||
          pathname === '/reset-password' ||
          pathname === '/forget-password') &&
        user
      ) {
        if (pathname === '/') {
          if (useNevro) {
            history.push('/patients/list');
          } else {
            history.push(initialUrl);
          }
        } else if (
          initialPath !== '/' ||
          initialPath !== '/signin' ||
          initialPath !== '/signup'
        ) {
          if (useNevro) {
            history.push('/patients/list');
          } else {
            history.push(initialUrl);
          }
        } else {
          if (useNevro) {
            history.push('/patients/list');
          } else {
            history.push(initialUrl);
          } // on sign in push them to dashboard(initialUrl constant)
        }
      }
    }
  }, [user, loading, pathname, initialPath, currentRoute.auth, history]);

  useEffect(() => {
    if (user) {
      events.forEach((element) => {
        window.addEventListener(element, resetTimer);
      });

      if (expTime !== null) {
        if (new Date() > expTime) {
          dispatch(onJWTAuthSignout(history));
        } else {
          let defaultTime = 1800000;

          if (!useNevro && autoLogoutTime !== '') {
            defaultTime = 60000 * parseInt(autoLogoutTime);
          }

          timeOut = window.setTimeout(checkExp, defaultTime);
        }
      }
    }
  }, [user]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
