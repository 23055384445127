import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCPeKFdkM1TzzYQjZlpzXW4fPx_gJKBTOE',
  authDomain: 'algos-1a225.firebaseapp.com',
  projectId: 'algos-1a225',
  storageBucket: 'algos-1a225.appspot.com',
  messagingSenderId: '997299133105',
  appId: '1:997299133105:web:9eed8e9e198a0432fea090',
  measurementId: 'G-3HTPLBS5L4',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
