import {lazy} from 'react';

export const reportsPagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/reports/summary',
        component: lazy(() => import('./summary/')),
      },
      {
        path: '/reports/patientsspotlight',
        component: lazy(() => import('./PatientsSpotlight')),
      },
      {
        path: '/reports/patientsperformancemeasure',
        component: lazy(() => import('./PatientsPerformanceMeasure')),
      },
      {
        path: '/reports/surveyreport',
        component: lazy(() => import('./surveyReport/')),
      },
      {
        path: '/reports/officevisits',
        component: lazy(() => import('./OfficeVisits/')),
      },
      {
        path: '/reports/refferaltracker',
        component: lazy(() => import('./RefferalTracker/')),
      },
      {
        path: '/reports/providerbaselines',
        component: lazy(() => import('./ProviderBaselines')),
      },
    ],
  },
];
