import {
  SET_TOKEN_SET,
  SIGNOUT_USER_SUCCESS,
  UPDATE_AUTH_USER,
  SET_USER_DATA,
  CHANGE_PASSWORD,
  SET_PASSWORD,
  SET_USER_ROLE,
  FORGET_PASSWORD,
  SET_IS_PARENT,
  SET_IS_CHILD,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '@e74/services/auth/index';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {setDoctorId} from './doctor';
import {fetchTimeZoneOffsetHours} from './admin/instance-settings';
import moment from 'moment';
import swal from 'sweetalert';
import ApiConfig from '@e74/services/ApiConfig';

export const onJwtUserSignUp = ({email, password, name}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, name, password};
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = ({email, password}, history) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, password};
    try {
      const res = await jwtAxios.post('users/authenticate', body);

      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      localStorage.setItem('userId', res.data.id);
      dispatch(setUserRole(res.data.role));
      localStorage.setItem('userRole', res.data.role);
      dispatch(setIsParent(res.data.isParent));
      localStorage.setItem('isParent', res.data.isParent);
      dispatch(setIsChild(res.data.isChild));
      localStorage.setItem('isChild', res.data.isChild);
      res.data.role === 'Doctor' && dispatch(setDoctorId(res.data.id));
      localStorage.setItem(
        'expireTime',
        moment(new Date()).add(1, 'm').toDate().toJSON(),
      );
      dispatch(setUserId(res.data.id));
      dispatch(loadJWTUser(res.data.id, history));
      dispatch(fetchTimeZoneOffsetHours());
      if (res.status === 200) {
        history.push('/');
      }
    } catch (err) {
      if (err.response !== undefined) {
        dispatch(
          fetchError(
            err.response.data !== undefined
              ? err.response.data.message
              : 'Unable to login. Please check email and password',
          ),
        );
      } else {
        dispatch(
          fetchError(
            'There is a problem with the API. Please contact System Administrator.',
          ),
        );
      }
    }
  };
};

export const loadJWTUser = (id, history) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('/users/' + id);
      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          displayName: res.data.firstName + ' ' + res.data.lastName,
          email: res.data.email,
          profileImage: res.data.profileImage,
          role: res.data.role,
          isParent: res.data.isParent,
          isChild: res.data.isChild,
        },
      });
      if (res.status === 200) {
        ApiConfig.instanceSettings()
          .isPortalProClinic()
          .then((response) => {
            const responseData = response.data;

            if (responseData) {
              history.push('/patientcampaigns/dashboard');
            } else {
              history.push('/');
            }
          })
          .catch(() => {
            history.push('/');
          });
      }
    } catch (err) {
      dispatch(fetchError(err.response.error));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_TOKEN_SET,
      payload: token,
    });
  };
};

export const setUserId = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      payload: userId,
    });
  };
};

export const setUserRole = (role) => {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_ROLE,
      payload: role,
    });
  };
};

export const setIsParent = (isParent) => {
  return async (dispatch) => {
    dispatch({
      type: SET_IS_PARENT,
      payload: isParent,
    });
  };
};

export const setIsChild = (isChild) => {
  return async (dispatch) => {
    dispatch({
      type: SET_IS_CHILD,
      payload: isChild,
    });
  };
};

export const onJWTAuthSignout = (history) => {
  return (dispatch) => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch({type: SIGNOUT_USER_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('userRole');
      localStorage.removeItem('isParent');
      localStorage.removeItem('isChild');
      localStorage.removeItem('clinicId');
      localStorage.removeItem('doctorId');
      localStorage.removeItem('expireTime');
      dispatch(setDoctorId(''));
      localStorage.removeItem('timeZoneOffsetHours');
      history.push('/');
    }, 500);
  };
};

export const onJWTForgotPassword = (email, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch(fetchStart());

    try {
      const res = await jwtAxios.post('users/forgetPassword', email);
      dispatch({type: FORGET_PASSWORD});
      dispatch(showMessage(res.data.message));
      onSuccess();
    } catch (err) {
      if (err.response !== undefined) {
        dispatch(fetchError(err.response.data.message));
      } else {
        dispatch(fetchError('Unable to forget password'));
      }
      onError();
    }

    setTimeout(() => {
      dispatch({type: SIGNOUT_USER_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('auth-user');
    }, 500);
  };
};

export const onJwtChangePassword = (
  currentPassword,
  newPassword,
  confirmNewPassword,
  history,
) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    let id = localStorage.getItem('userId');
    const body = {id, currentPassword, newPassword, confirmNewPassword};
    try {
      const res = await jwtAxios.post('users/changepassword', body);
      dispatch({type: CHANGE_PASSWORD});
      dispatch(showMessage(res.data.message));
      if (res.status === 200) {
        history.push('/');
      }
    } catch (err) {
      if (err.response) {
        const messageToDisplay =
          err.response.data?.errors?.join('\n') || err.response.data.message;
        dispatch(fetchError(messageToDisplay));
      } else {
        dispatch(fetchError('Unable to update password'));
      }
    }
  };
};

export const onJwtSetPassword = (
  id,
  token,
  newPassword,
  confirmNewPassword,
  history,
) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {id, token, newPassword, confirmNewPassword};

    try {
      const res = await jwtAxios.post('users/setPassword', body);
      dispatch({type: SET_PASSWORD});
      dispatch(showMessage(res.data.message));
      if (res.status === 200) {
        swal({
          icon: 'success',
          closeOnClickOutside: false,
          buttons: false,
          title: 'Your account setup is complete.',
          text: 'You will now be redirected to the access portal.',
          timer: 3000,
        }).then(() => {
          history.push('/signin');
        });
      }
    } catch (err) {
      if (err.response) {
        const messageToDisplay =
          err?.response?.data?.errors?.join('\n') ||
          err?.response?.data?.message;
        // dispatch(fetchError(messageToDisplay));

        swal({
          icon: 'error',
          closeOnClickOutside: false,
          buttons: {
            confirm: {
              text: 'OK',
              value: true,
              visible: true,
            },
            cancel: {
              text: 'Close',
              value: null,
              visible: true,
            },
          },
          title: 'Failed to Set up Account',
          text:
            'We failed to verify your information. Would you like us to resend the email to set up your account?',
        }).then((resedEmail) => {
          let welcomeEmailInformation = {
            id,
            role: '',
            url: window.location.origin,
          };
          if (resedEmail) {
            ApiConfig.users()
              .sendwelcomeemail(welcomeEmailInformation)
              .then((emails) => {
                swal({
                  title: 'Success!',
                  text:
                    'We have resent the Email to set up your account. Please feel free to close this browser tab or window',
                  icon: 'success',
                }).then(() => {
                  history.push('/signin');
                });
              });
          }
        });
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError('Unable to set password'));
      }
    }
  };
};
