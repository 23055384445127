import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_SUMMARY: 'FETCH_REPORTS_SUMMARY',
  SET_FILTER_STUDY_GROUPS: 'SET_REPORTS_SUMMARY_FILTER_STUDY_GROUPS',
  SET_FILTER_VISIT_RANGE: 'SET_REPORTS_SUMMARY_FILTER_VISIT_RANGE',
  SET_FILTER_AGE_RANGE: 'SET_REPORTS_SUMMARY_FILTER_AGE_RANGE',
  SET_FILTER_PAIN_SCORE_RANGE: 'SET_REPORTS_SUMMARY_FILTER_PAIN_SCORE_RANGE',
  SET_FILTER_BMI_RANGE: 'SET_REPORTS_SUMMARY_FILTER_BMI_RANGE',
  SET_FILTER_BODY_LOCATIONS: 'SET_REPORTS_SUMMARY_FILTER_BODY_LOCATIONS',
  SET_FILTER_GENDERS: 'SET_REPORTS_SUMMARY_FILTER_GENDERS',
  SET_FILTER_OCCUPATIONS: 'SET_REPORTS_SUMMARY_FILTER_OCCUPATIONS',
  SET_FILTER_MARITAL_STATUS: 'SET_REPORTS_SUMMARY_FILTER_MARITAL_STATUS',
  SET_FILTER_PAYOR_STATUS: 'SET_REPORTS_SUMMARY_FILTER_PAYOR_STATUS',
  SET_FILTER_NICOTINE_STATUS: 'SET_REPORTS_SUMMARY_FILTER_NICOTINE_STATUS',
  SET_FILTER_DISEASE_STATES: 'SET_REPORTS_SUMMARY_FILTER_DISEASE_STATES',
  SET_FILTER_PSYCHAITRIC_STATUS:
    'SET_REPORTS_SUMMARY_FILTER_PSYCHAITRIC_STATUS',
  SET_FILTER_SURGICAL_HISTORY: 'SET_REPORTS_SUMMARY_FILTER_SURGICAL_HISTORY',
  SET_FILTER_PATIENT_NAME: 'SET_REPORTS_SUMMARY_FILTER_PATIENT_NAME',
  SET_FILTER_RAND_SCORE_RANGE: 'SET_REPORTS_SUMMARY_FILTER_RAND_SCORE_RANGE',
  RESET_FILTER: 'RESET_REPORTS_SUMMARY_FILTER',
  SET_FILTER_PERIOPERATIVE_MESSAGE_TEMPLATE_IDS:
    'SET_REPORTS_SUMMARY_FILTER_PERIOPERATIVE_MESSAGE_TEMPLATE_IDS',
  SET_FILTER_FORM_IDS: 'SET_REPORTS_SUMMARY_FILTER_FORM_IDS',
  SET_FILTER_REPORT_SUMMARY_DATA_TYPE:
    'SET_REPORTS_SUMMARY_FILTER_REPORT_SUMMARY_DATA_TYPE',
};

export const fetchSummary =
  (startDate, endDate, postDeploymentAppointments) => (dispatch, getState) => {
    dispatch(fetchStart());
    const filter = getState().summary.filter;
    api
      .reports()
      .summary({
        studyGroups: filter.studyGroups,
        bodyLocations: filter.bodyLocations,
        genders: filter.genders,
        occupations: filter.occupations,
        maritalStatus: filter.maritalStatus,
        payorStatus: filter.payorStatus,
        nicotineStatus: filter.nicotineStatus.map(Number),
        diseaseStates: filter.diseaseStates,
        psychaitricStatus: filter.psychaitricStatus,
        surgicalHistory: filter.surgicalHistory,
        startingPainScoreMax: filter.painScoreRange[1],
        startingPainScoreMin: filter.painScoreRange[0],
        ageMin: filter.ageRange[0],
        ageMax: filter.ageRange[1],
        visitMin: filter.visitRange[0],
        visitMax: filter.visitRange[1],
        bmiMin: filter.bmiRange[0],
        bmiMax: filter.bmiRange[1],
        doctorId: getState().doctor.id,
        randScoreMin: filter.randScoreRange[0],
        randScoreMax: filter.randScoreRange[1],
        perioperativeMessageTemplateIds: filter.perioperativeMessageTemplateIds,
        formIds: filter.formIds,
        reportSummaryDataType: filter.reportSummaryDataType,
        // lastAppointmentMonth: filterMonth,
        startDate: startDate,
        endDate: endDate,
        postDeploymentAppointments: postDeploymentAppointments,
      })
      .then((response) => {
        dispatch({type: ACTION_TYPES.FETCH_SUMMARY, payload: response.data});
        dispatch(fetchSuccess());
      })
      .catch((err) =>
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err)),
      );
  };

export const setSummary = (newSummary) => (dispatch) => {
  dispatch({type: ACTION_TYPES.FETCH_SUMMARY, payload: newSummary});
};

export const setFilterStudyGroups = (newStudyGroups) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_STUDY_GROUPS,
    payload: newStudyGroups,
  });
};

export const setFilterVisitRange = (newVisitRange) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_VISIT_RANGE,
    payload: newVisitRange,
  });
};

export const setFilterAgeRange = (newAgeRange) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_AGE_RANGE,
    payload: newAgeRange,
  });
};

export const setFilterPainScoreRange = (newPainScoreRange) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_PAIN_SCORE_RANGE,
    payload: newPainScoreRange,
  });
};

export const setFilterBmiRange = (newBmiRange) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_BMI_RANGE,
    payload: newBmiRange,
  });
};

export const setFilterBodyLocations = (newBodyLocations) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_BODY_LOCATIONS,
    payload: newBodyLocations,
  });
};

export const setFilterGenders = (newGenders) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_GENDERS,
    payload: newGenders,
  });
};

export const setFilterOccupations = (newOccupations) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_OCCUPATIONS,
    payload: newOccupations,
  });
};

export const setFilterMaritalStatus = (newMaritalStatus) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_MARITAL_STATUS,
    payload: newMaritalStatus,
  });
};

export const setFilterPayorStatus = (newPayorStatus) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_PAYOR_STATUS,
    payload: newPayorStatus,
  });
};

export const setFilterNicotineStatus = (newNicotineStatus) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_NICOTINE_STATUS,
    payload: newNicotineStatus,
  });
};

export const setFilterDiseaseStates = (newDiseaseStates) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_DISEASE_STATES,
    payload: newDiseaseStates,
  });
};

export const setFilterPsychaitricStatus =
  (newPsychaitricStatus) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_FILTER_PSYCHAITRIC_STATUS,
      payload: newPsychaitricStatus,
    });
  };

export const setFilterSurgicalHistory = (newSurgicalHistory) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_SURGICAL_HISTORY,
    payload: newSurgicalHistory,
  });
};

export const setFilterPatientName = (newPatientName) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_PATIENT_NAME,
    payload: newPatientName,
  });
};

export const setFilterRandScoreRange = (newRandScoreRange) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_RAND_SCORE_RANGE,
    payload: newRandScoreRange,
  });
};

export const setFilterPerioperativeMessageTemplateIds =
  (payload) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_FILTER_PERIOPERATIVE_MESSAGE_TEMPLATE_IDS,
      payload: payload,
    });
  };

export const setFilterFormIds = (payload) => (dispatch) => {
  dispatch({type: ACTION_TYPES.SET_FILTER_FORM_IDS, payload});
};

export const setFilterReportSummaryDataType = (payload) => (dispatch) => {
  dispatch({type: ACTION_TYPES.SET_FILTER_REPORT_SUMMARY_DATA_TYPE, payload});
};

export const resetFilter = () => (dispatch) => {
  dispatch({type: ACTION_TYPES.RESET_FILTER});
};
