import React, {useEffect} from 'react';
const GooglePageTranslator = () => {
  useEffect(() => {
    const translatorLoadingScript = document.createElement('script');
    translatorLoadingScript.type = 'text/javascript';
    translatorLoadingScript.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(translatorLoadingScript);
  }, []);
  return <div id='google_translate_element'></div>;
};

export default GooglePageTranslator;
