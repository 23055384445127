import api from '@e74/services/ApiConfig';
import {fetchError, fetchSuccess} from '../Common';
export const ACTION_TYPES = {
  UPDATE: 'UPDATE',
};

export const updatestudygroups = (data, onSuccess) => (dispatch) => {
  api
    .patientStudyGroups()
    .update(data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.UPDATE,
        payload: res.data,
      });
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
