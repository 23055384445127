import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_AVAILABLE_INSURANCE_PACKAGES: 'FETCH_EHR_AVAILABLE_INSURANCE_PACKAGES',
  FETCH_SOCIAL_HISTORY_QUESTIONS: 'FETCH_EHR_SOCIAL_HISTORY_QUESTIONS',
  FETCH_GYN_HISTORY_QUESTIONS: 'FETCH_EHR_GYN_HISTORY_QUESTIONS',
  FETCH_ENABLED_CUSTOM_SCREENERS: 'FETCH_EHR_ENABLED_CUSTOM_SCREENERS',
};

export const fetchAvailableInsurancePackages = (planName) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .fetchAvailableInsurancePackages(planName)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_AVAILABLE_INSURANCE_PACKAGES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchSocialHistoryQuestions = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .socialHistory()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_SOCIAL_HISTORY_QUESTIONS,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchGynHistoryQuestions = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .gynHistory()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_GYN_HISTORY_QUESTIONS,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchEnabledScreeners = (
  patientAppointmentId,
  onSuccess = null,
  onError = null,
) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .fetchEnabledCustomScreeners(patientAppointmentId)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ENABLED_CUSTOM_SCREENERS,
        payload: response.data,
      });
      dispatch(fetchSuccess());
      onSuccess && onSuccess(response);
    })
    .catch((err) => {
      onError && onError(err);
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const setEnabledScreeners = (newScreeners) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.FETCH_ENABLED_CUSTOM_SCREENERS,
    payload: newScreeners,
  });
};

export const submitCustomScreeners = (
  patientAppointmentId,
  customScreeners,
  onSuccess,
) => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .submitCustomScreeners(patientAppointmentId, customScreeners)
    .then((response) => {
      onSuccess && onSuccess();
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const cancelPatientAppointmentCheckin = (patientAppointmentId) => (
  dispatch,
) => {
  dispatch(fetchStart());
  api
    .ehr()
    .cancelCheckin(patientAppointmentId)
    .then((response) => {
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
