import React from 'react';

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  photoURL: 'https://via.placeholder.com/150',
};

export const initialUrl = '/patients/list'; // this url will open after login

export const PainScoreMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export const PainScoreMarksWithBestModerateAndWorstLabels = [
  {
    value: 0,
    label: (
      <center>
        0<br />
        No
        <br />
        pain
      </center>
    ),
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: (
      <center>
        5<br />
        Moderate
        <br />
        pain
      </center>
    ),
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: (
      <center>
        10
        <br />
        Worst
        <br />
        possible
        <br />
        pain
      </center>
    ),
  },
];

export const SliderScaleMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export const AgeMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100&+',
  },
];

export const BmiMarks = [
  {
    value: 18,
    label: '18',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 50,
    label: '50&+',
  },
];

export const VisitRangeMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 24,
    label: '24&+',
  },
];

export const S3_RESOURCES_BASE_URL =
  'https://algos-2021-assets.s3.us-west-2.amazonaws.com/Resources/';

export const S3_INSTANCE_SETTINGS_BASE_URL =
  'https://algos-2021-assets.s3.us-west-2.amazonaws.com/config/';

export const S3_USERS_BASE_URL =
  'https://algos-2021-assets.s3.us-west-2.amazonaws.com/users/';

export const DEFAULT_PAGINATION_ROWS_PER_PAGE = 100;
export const DEFAULT_CASE_STATUS =
  localStorage.getItem('caseStatus') || 'Cases in Progress';
export const DEFAULT_PATIENT_STATUS = 'Active';
export const DEFAULT_SORT_BY_OPTION = 'CreationStamp';
export const DEFAULT_CURRENT_PAGE_NUMBER = 0;
export const DEFAULT_ROW_PER_PAGE = 25;
export const DEFAULT_FETCH_PATIENTS = true;

export const ALL_OF_THESE_CODES = 'All of These Codes';
export const ANY_OF_THESE_CODES = 'Any of These Codes';

export const CUSTOM_TIMEFRAME = 'Custom Timeframe';

export const archiveReasons = [
  'Procedure Completed',
  'Procedure Not Moving Forward',
];

export const availableLanguages = [
  {
    name: 'English',
    language6392code: 'eng',
  },
  {
    name: 'Spanish',
    language6392code: 'spa',
  },
  {
    name: 'Portuguese',
    language6392code: 'por',
  },
  {
    name: 'Abkhazian',
    language6392code: 'abk',
  },
  {
    name: 'Afar',
    language6392code: 'aar',
  },
  {
    name: 'Afrikaans',
    language6392code: 'afr',
  },
  {
    name: 'Akan',
    language6392code: 'aka',
  },
  {
    name: 'Albanian',
    language6392code: 'sqi',
  },
  {
    name: 'Amharic',
    language6392code: 'amh',
  },
  {
    name: 'Arabic',
    language6392code: 'ara',
  },
  {
    name: 'Aragonese',
    language6392code: 'arg',
  },
  {
    name: 'Armenian',
    language6392code: 'hye',
  },
  {
    name: 'Assamese',
    language6392code: 'asm',
  },
  {
    name: 'Avaric',
    language6392code: 'ava',
  },
  {
    name: 'Avestan',
    language6392code: 'ave',
  },
  {
    name: 'Aymara',
    language6392code: 'aym',
  },
  {
    name: 'Azerbaijani',
    language6392code: 'aze',
  },
  {
    name: 'Bambara',
    language6392code: 'bam',
  },
  {
    name: 'Basque',
    language6392code: 'eus',
  },
  {
    name: 'Belarusian',
    language6392code: 'bel',
  },
  {
    name: 'Bengali',
    language6392code: 'ben',
  },
  {
    name: 'Bihari languages',
    language6392code: 'bih',
  },
  {
    name: 'Bislama',
    language6392code: 'bis',
  },
  {
    name: 'Bokmål, Norwegian; Norwegian Bokmål',
    language6392code: 'nob',
  },
  {
    name: 'Bosnian',
    language6392code: 'bos',
  },
  {
    name: 'Breton',
    language6392code: 'bre',
  },
  {
    name: 'Bulgarian',
    language6392code: 'bul',
  },
  {
    name: 'Burmese',
    language6392code: 'mya',
  },
  {
    name: 'Central Khmer',
    language6392code: 'khm',
  },
  {
    name: 'Chamorro',
    language6392code: 'cha',
  },
  {
    name: 'Chinese',
    language6392code: 'zho',
  },
  {
    name:
      'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    language6392code: 'chu',
  },
  {
    name: 'Chuvash',
    language6392code: 'chv',
  },
  {
    name: 'Corsican',
    language6392code: 'cos',
  },
  {
    name: 'Creoles and pidgins, English based',
    language6392code: 'cpe',
  },
  {
    name: 'Creoles and pidgins, French-based',
    language6392code: 'cpf',
  },
  {
    name: 'Creoles and pidgins, Portuguese-based',
    language6392code: 'cpp',
  },
  {
    name: 'Croatian',
    language6392code: 'hrv',
  },
  {
    name: 'Czech',
    language6392code: 'ces',
  },
  {
    name: 'Danish',
    language6392code: 'dan',
  },
  {
    name: 'Dutch; Flemish',
    language6392code: 'nld',
  },
  {
    name: 'Dzongkha',
    language6392code: 'dzo',
  },
  {
    name: 'Esperanto',
    language6392code: 'epo',
  },
  {
    name: 'Estonian',
    language6392code: 'est',
  },
  {
    name: 'Ewe',
    language6392code: 'ewe',
  },
  {
    name: 'Faroese',
    language6392code: 'fao',
  },
  {
    name: 'Fijian',
    language6392code: 'fij',
  },
  {
    name: 'Filipino; Pilipino',
    language6392code: 'fil',
  },
  {
    name: 'Finnish',
    language6392code: 'fin',
  },
  {
    name: 'French',
    language6392code: 'fra',
  },
  {
    name: 'Fulah',
    language6392code: 'ful',
  },
  {
    name: 'Gaelic; Scottish Gaelic',
    language6392code: 'gla',
  },
  {
    name: 'Galician',
    language6392code: 'glg',
  },
  {
    name: 'Ganda',
    language6392code: 'lug',
  },
  {
    name: 'Georgian',
    language6392code: 'kat',
  },
  {
    name: 'German',
    language6392code: 'deu',
  },
  {
    name: 'Greek, Modern (1453-)',
    language6392code: 'ell',
  },
  {
    name: 'Guarani',
    language6392code: 'grn',
  },
  {
    name: 'Gujarati',
    language6392code: 'guj',
  },
  {
    name: 'Haitian; Haitian Creole',
    language6392code: 'hat',
  },
  {
    name: 'Hawaiian',
    language6392code: 'haw',
  },
  {
    name: 'Hebrew',
    language6392code: 'heb',
  },
  {
    name: 'Hindi',
    language6392code: 'hin',
  },
  {
    name: 'Hiri Motu',
    language6392code: 'hmo',
  },
  {
    name: 'Hmong',
    language6392code: 'hmn',
  },
  {
    name: 'Hungarian',
    language6392code: 'hun',
  },
  {
    name: 'Icelandic',
    language6392code: 'isl',
  },
  {
    name: 'Ido',
    language6392code: 'ido',
  },
  {
    name: 'Indonesian',
    language6392code: 'ind',
  },
  {
    name: 'Interlingue; Occidental',
    language6392code: 'ile',
  },
  {
    name: 'Inuktitut',
    language6392code: 'iku',
  },
  {
    name: 'Irish',
    language6392code: 'gle',
  },
  {
    name: 'Italian',
    language6392code: 'ita',
  },
  {
    name: 'Japanese',
    language6392code: 'jpn',
  },
  {
    name: 'Javanese',
    language6392code: 'jav',
  },
  {
    name: 'Kalaallisut; Greenlandic',
    language6392code: 'kal',
  },
  {
    name: 'Kanuri',
    language6392code: 'kau',
  },
  {
    name: 'Karen languages',
    language6392code: 'kar',
  },
  {
    name: 'Kazakh',
    language6392code: 'kaz',
  },
  {
    name: 'Kikuyu; Gikuyu',
    language6392code: 'kik',
  },
  {
    name: 'Kirghiz; Kyrgyz',
    language6392code: 'kir',
  },
  {
    name: 'Komi',
    language6392code: 'kom',
  },
  {
    name: 'Kongo',
    language6392code: 'kon',
  },
  {
    name: 'Korean',
    language6392code: 'kor',
  },
  {
    name: 'Kuanyama; Kwanyama',
    language6392code: 'kua',
  },
  {
    name: 'Kurdish',
    language6392code: 'kur',
  },
  {
    name: 'Lao',
    language6392code: 'lao',
  },
  {
    name: 'Latin',
    language6392code: 'lat',
  },
  {
    name: 'Lingala',
    language6392code: 'lin',
  },
  {
    name: 'Luba-Katanga',
    language6392code: 'lub',
  },
  {
    name: 'Luxembourgish; Letzeburgesch',
    language6392code: 'ltz',
  },
  {
    name: 'Macedonian',
    language6392code: 'mkd',
  },
  {
    name: 'Malagasy',
    language6392code: 'mlg',
  },
  {
    name: 'Malay',
    language6392code: 'msa',
  },
  {
    name: 'Malayalam',
    language6392code: 'mal',
  },
  {
    name: 'Manx',
    language6392code: 'glv',
  },
  {
    name: 'Marathi',
    language6392code: 'mar',
  },
  {
    name: 'Marshallese',
    language6392code: 'mah',
  },
  {
    name: 'Mayan languages',
    language6392code: 'myn',
  },
  {
    name: 'Navajo; Navaho',
    language6392code: 'nav',
  },
  {
    name: 'Ndebele, South; South Ndebele',
    language6392code: 'nbl',
  },
  {
    name: 'Nepali',
    language6392code: 'nep',
  },
  {
    name: 'Northern Sami',
    language6392code: 'sme',
  },
  {
    name: 'Norwegian Nynorsk; Nynorsk, Norwegian',
    language6392code: 'nno',
  },
  {
    name: 'Oriya',
    language6392code: 'ori',
  },
  {
    name: 'Oromo',
    language6392code: 'orm',
  },
  {
    name: 'Ossetian; Ossetic',
    language6392code: 'oss',
  },
  {
    name: 'Pali',
    language6392code: 'pli',
  },
  {
    name: 'Persian',
    language6392code: 'fas',
  },
  {
    name: 'Polish',
    language6392code: 'pol',
  },
  {
    name: 'Quechua',
    language6392code: 'que',
  },
  {
    name: 'Romanian; Moldavian; Moldovan',
    language6392code: 'ron',
  },
  {
    name: 'Romansh',
    language6392code: 'roh',
  },
  {
    name: 'Rundi',
    language6392code: 'run',
  },
  {
    name: 'Russian',
    language6392code: 'rus',
  },
  {
    name: 'Sanskrit',
    language6392code: 'san',
  },
  {
    name: 'Sardinian',
    language6392code: 'srd',
  },
  {
    name: 'Serbian',
    language6392code: 'srp',
  },
  {
    name: 'Shona',
    language6392code: 'sna',
  },
  {
    name: 'Sichuan Yi; Nuosu',
    language6392code: 'iii',
  },
  {
    name: 'Sign Languages',
    language6392code: 'sgn',
  },
  {
    name: 'Sindhi',
    language6392code: 'snd',
  },
  {
    name: 'Sinhala; Sinhalese',
    language6392code: 'sin',
  },
  {
    name: 'Slovak',
    language6392code: 'slk',
  },
  {
    name: 'Slovenian',
    language6392code: 'slv',
  },
  {
    name: 'Somali',
    language6392code: 'som',
  },
  {
    name: 'Sotho, Southern',
    language6392code: 'sot',
  },
  {
    name: 'Sundanese',
    language6392code: 'sun',
  },
  {
    name: 'Swahili',
    language6392code: 'swa',
  },
  {
    name: 'Swedish',
    language6392code: 'swe',
  },
  {
    name: 'Tagalog',
    language6392code: 'tgl',
  },
  {
    name: 'Tahitian',
    language6392code: 'tah',
  },
  {
    name: 'Tajik',
    language6392code: 'tgk',
  },
  {
    name: 'Tamil',
    language6392code: 'tam',
  },
  {
    name: 'Tatar',
    language6392code: 'tat',
  },
  {
    name: 'Telugu',
    language6392code: 'tel',
  },
  {
    name: 'Thai',
    language6392code: 'tha',
  },
  {
    name: 'Tigrinya',
    language6392code: 'tir',
  },
  {
    name: 'Tonga (Tonga Islands)',
    language6392code: 'ton',
  },
  {
    name: 'Tsonga',
    language6392code: 'tso',
  },
  {
    name: 'Turkish',
    language6392code: 'tur',
  },
  {
    name: 'Twi',
    language6392code: 'twi',
  },
  {
    name: 'Ukrainian',
    language6392code: 'ukr',
  },
  {
    name: 'Undetermined',
    language6392code: 'und',
  },
  {
    name: 'Urdu',
    language6392code: 'urd',
  },
  {
    name: 'Uzbek',
    language6392code: 'uzb',
  },
  {
    name: 'Venda',
    language6392code: 'ven',
  },
  {
    name: 'Vietnamese',
    language6392code: 'vie',
  },
  {
    name: 'Walloon',
    language6392code: 'wln',
  },
  {
    name: 'Welsh',
    language6392code: 'cym',
  },
  {
    name: 'Xhosa',
    language6392code: 'xho',
  },
  {
    name: 'Yiddish',
    language6392code: 'yid',
  },
  {
    name: 'Yoruba',
    language6392code: 'yor',
  },
  {
    name: 'Zhuang; Chuang',
    language6392code: 'zha',
  },
  {
    name: 'Bashkir',
    language6392code: 'bak',
  },
  {
    name: 'Catalan; Valencian',
    language6392code: 'cat',
  },
  {
    name: 'Chechen',
    language6392code: 'che',
  },
  {
    name: 'Chichewa; Chewa; Nyanja',
    language6392code: 'nya',
  },
  {
    name: 'Chuukese',
    language6392code: 'chk',
  },
  {
    name: 'Cornish',
    language6392code: 'cor',
  },
  {
    name: 'Cree',
    language6392code: 'cre',
  },
  {
    name: 'Divehi; Dhivehi; Maldivian',
    language6392code: 'div',
  },
  {
    name: 'Hausa',
    language6392code: 'hau',
  },
  {
    name: 'Herero',
    language6392code: 'her',
  },
  {
    name: 'Hopi',
    language6392code: 'und',
  },
  {
    name: 'Igbo',
    language6392code: 'ibo',
  },
  {
    name: 'Interlingua (International Auxiliary Language Association)',
    language6392code: 'ina',
  },
  {
    name: 'Inupiaq',
    language6392code: 'ipk',
  },
  {
    name: 'Kannada',
    language6392code: 'kan',
  },
  {
    name: 'Kashmiri',
    language6392code: 'kas',
  },
  {
    name: 'Kinyarwanda',
    language6392code: 'kin',
  },
  {
    name: 'Latvian',
    language6392code: 'lav',
  },
  {
    name: 'Limburgan; Limburger; Limburgish',
    language6392code: 'lim',
  },
  {
    name: 'Lithuanian',
    language6392code: 'lit',
  },
  {
    name: 'Maltese',
    language6392code: 'mlt',
  },
  {
    name: 'Maori',
    language6392code: 'mri',
  },
  {
    name: 'Mongolian',
    language6392code: 'mon',
  },
  {
    name: 'Nauru',
    language6392code: 'nau',
  },
  {
    name: 'Ndebele, North; North Ndebele',
    language6392code: 'nde',
  },
  {
    name: 'Ndonga',
    language6392code: 'ndo',
  },
  {
    name: 'Norwegian',
    language6392code: 'nor',
  },
  {
    name: 'Occitan (post 1500)',
    language6392code: 'oci',
  },
  {
    name: 'Ojibwa',
    language6392code: 'oji',
  },
  {
    name: 'Panjabi; Punjabi',
    language6392code: 'pan',
  },
  {
    name: 'Pushto; Pashto',
    language6392code: 'pus',
  },
  {
    name: 'Samoan',
    language6392code: 'smo',
  },
  {
    name: 'Sango',
    language6392code: 'sag',
  },
  {
    name: 'Swati',
    language6392code: 'ssw',
  },
  {
    name: 'Tibetan',
    language6392code: 'bod',
  },
  {
    name: 'Tswana',
    language6392code: 'tsn',
  },
  {
    name: 'Turkmen',
    language6392code: 'tuk',
  },
  {
    name: 'Uighur; Uyghur',
    language6392code: 'uig',
  },
  {
    name: 'Volapük',
    language6392code: 'vol',
  },
  {
    name: 'Western Frisian',
    language6392code: 'fry',
  },
  {
    name: 'Wolof',
    language6392code: 'wol',
  },
  {
    name: 'Zulu',
    language6392code: 'zul',
  },
  {
    name: 'Patient Declined',
    language6392code: '',
  },
];

export const RandScoreMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

export const RESOURCE_SHORTENED_URL_BASE_ROUTE = 'r';

export const META_TAG_CONTENT_TO_DISABLE_ZOOM =
  'minimum-scale=1,initial-scale=1,maximum-scale=1,user-scalable=0,width=device-width,shrink-to-fit=no';

export const EDUCATIONAL_RESOURCES_TAG = 'educational-page';

export const nevroCaseTypes = [
  {value: 1, label: 'Spinal Cord Stimulation(SCS)'},
  {value: 2, label: 'Sacroiliac Joint Fusion(SIJF)'},
];

export const nevroProcedureTypes = [
  {value: 1, label: 'SCS Trial'},
  {value: 2, label: 'SCS Permanent Implant'},
  {value: 3, label: 'SCS Replacement'},
  {value: 4, label: 'SCS Revision/Explant'},
  {value: 5, label: 'SI Joint Fusion, Non-Transfixing (Pro)'},
  {value: 6, label: 'SI Joint Fusion, Transfixing (V1, Fix)'},
];

export const nevroRequestTypes = [
  {value: 1, label: 'Prior Authorization (PA)'},
  {value: 2, label: 'Pre-Determination'},
  {value: 3, label: 'Appeal for Prior Authorization Denial'},
];

export const serviceSiteTypes = [
  {value: 1, label: 'Office'},
  {value: 2, label: 'Ambulatory Surgery Center'},
  {value: 3, label: 'Outpatient Hospital'},
];

export const bodyRegions = [
  {value: 1, label: 'Cervical'},
  {value: 2, label: 'Thoracic'},
  {value: 3, label: 'Lumbar'},
  {value: 4, label: 'Occipital'},
  {value: 5, label: 'SI Joint'},
];

export const NOTIFICATION_POLLING_INTERVAL_SECONDS = 5;

export const GUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gi;
