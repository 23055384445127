import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      position: 'relative',
      top: 0,
      left: 0,
      width: '19rem',
      maxHeight: '100vh',
      [theme.breakpoints.up('lg')]: {
        position: 'fixed',
      },
      [theme.breakpoints.up('xl')]: {
        width: '21.6rem',
      },
    },
    sidebarBg: {
      // backgroundColor: 'white',
    },
    scrollAppSidebar: {
      paddingTop: 0,
      paddingBottom: 20,
      height: 'calc(100vh - 180px) !important',
      // backgroundColor: 'white',
      backgroundColor: '#fff',
      [theme.breakpoints.up('xl')]: {
        height: 'calc(100vh - 180px) !important',
      },
    },
    drawerScrollAppSidebar: {
      paddingTop: 0,
      paddingBottom: 20,
      height: 'calc(100vh - 180px) !important',
      // backgroundColor: 'white',
      backgroundColor: '#fff',
      [theme.breakpoints.up('xl')]: {
        height: 'calc(100vh - 180px) !important',
      },
    },
    sidebarStandard: {
      height: '100%',
      width: '100%',
      // color: 'white',
      color: '#000',
      overflow: 'hidden',
    },

    bottomFixed: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      width: '100%',
      zIndex: 1,
      backgroundColor: 'gray',
      padding: '0px 20px',
      paddingBottom: 0,
      height: 60,
    },
  };
});
export default useStyles;
