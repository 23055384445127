import React from 'react';

export const errorPagesConfigs = [
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('./Error404')),
      },
      {
        path: '/error-pages/unexpected',
        component: React.lazy(() => import('./unexpected')),
      },
      {
        path: '/error-pages/unauthorized',
        component: React.lazy(() => import('./Unauthorized')),
      },
    ],
  },
];
