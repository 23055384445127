import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import {medicine} from './admin/medicine';
import {bodylocation} from './admin/bodylocation';
import {studygroup} from './admin/studygroup';
import {patient} from './patient/patient';
import {otherenum} from './admin/otherenum';
import {interventionalitem} from './admin/interventionalitem';
import {interventionaldirection} from './admin/interventionaldirection';
import {interventionallocation} from './admin/interventionallocation';
import {adaInterventionalLocationReducer} from './admin/adainterventionallocation';
import {perioperativemessagedetails} from './admin/perioperativemessagedetails';
import {perioperativemessagetemplates} from './admin/perioperativemessagetemplate';
import {quiz} from './Common/quiz';
import {patientappointments} from './patient/patientappointments';
import {room} from './admin/room';
import dashboard from './patient/dashboard';
import {patientappointmentprocedures} from './patient/patientappointmentprocedures';
import {referral} from './admin/referral';
import {painpumpmedicine} from './admin/painpumpmedicine';
import {users} from './users/users';
import {instances} from './instances/instances';
import {resourcetype} from './admin/resourcetype';
import {resources} from './resources/resources';
import {resourcefileactivities} from './resources/resourcefileactivities';
import doctor from './doctor';
import searchbar from './searchbar';
import summary from './reports/summary';
import userMessages from './user-messages/user-messages';
import instanceSettings from './admin/instance-settings';
import timeZones from './time-zones';
import {customFieldsReducer} from './custom-fields';
import {ehrReducer} from './ehr';
import patientsSpotlightReducer from './reports/patientsSpotlight';
import patientsPerformanceMeasureReducer from './reports/patientsPerformanceMeasure';
import clinic from './clinic';
import notification from './notification';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    medicine,
    bodylocation,
    studygroup,
    interventionalitem,
    interventionallocation,
    adaInterventionalLocation: adaInterventionalLocationReducer,
    interventionaldirection,
    referral,
    painpumpmedicine,
    patient,
    otherenum,
    quiz,
    patientappointments,
    patientappointmentprocedures,
    room,
    dashboard,
    users,
    instances,
    resourcetype,
    resources,
    resourcefileactivities,
    doctor,
    searchbar,
    summary,
    userMessages,
    instanceSettings,
    timeZones,
    perioperativemessagedetails,
    perioperativemessagetemplates,
    customFields: customFieldsReducer,
    ehr: ehrReducer,
    patientsSpotlight: patientsSpotlightReducer,
    patientsPerformanceMeasure: patientsPerformanceMeasureReducer,
    clinic,
    notification,
  });
