import React from 'react';
import IntlMessages from '../../../@e74/utility/IntlMessages';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {grey} from '@material-ui/core/colors';
import {Button, makeStyles} from '@material-ui/core';
import {Fonts} from '../../../shared/constants/AppEnums';
import UnexpectedError from '../../../assets/images/unexpected.jpeg';

const Unexpected = () => {
  const useStyles = makeStyles((theme) => {
    return {
      button: {
        // fontFamily: Fonts.BOLD,
        fontSize: 16,
        textTransform: 'capitalize',
        padding: '12px 32px',
        [theme.breakpoints.up('xl')]: {
          fontSize: 20,
        },
      },
      image: {
        width: '100%',
      },
    };
  });

  const classes = useStyles();

  return (
    <Box
      py={{xl: 8}}
      flex={1}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'>
      <Box
        mb={{xs: 4, xl: 8}}
        width='100%'
        maxWidth={{xs: 200, sm: 450, xl: 750}}>
        <img
          className={classes.image}
          src={UnexpectedError}
          alt='Unexpected Error'
        />
      </Box>
      <Box mb={{xs: 4, xl: 5}}>
        <Box
          variant='h3'
          mb={{xs: 3, xl: 10}}
          fontSize={{xs: 24, md: 28}}
          // fontFamily={Fonts.BOLD}
        >
          <IntlMessages id='error.unexpectedmessage2' />.
        </Box>
        <Box
          mb={{xs: 4, xl: 10}}
          color={grey[600]}
          fontSize={20}
          // fontFamily={Fonts.MEDIUM}
        >
          <Typography>
            <IntlMessages id='error.unexpected' />
          </Typography>
          <Typography>
            <IntlMessages id='error.unexpectedmessage1' />
          </Typography>
          <Button variant='contained' color='primary'
            onClick={() => {
              window.location.reload();
            }}>
            Refresh
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Unexpected;
