import {ACTION_TYPES} from '../../actions/patient/patientappointments';

const initialState = {
  data: {
    list: {},
  },
  ui: {
    filter: {
      paginationFilter: {
        pageNumber: 0,
        pageSize: 15,
      },
      dateFilter: 1,
      startDate: null,
      endDate: null,
      painScorePair: [0, 10],
      bodyLocationId: '',
      appointmentStatusId: null,
      topDate: new Date(),
      fixedDate: '',
    },
  },
};

export const patientappointments = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_FILTERED:
      return {
        ...state,
        data: {
          ...state.data,
          list: {...action.payload},
        },
      };
    case ACTION_TYPES.SET_FILTER_DATE_FILTER:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {...state.ui.filter, dateFilter: action.payload},
        },
      };
    case ACTION_TYPES.SET_FILTER_APPOINTMENT_START_DATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            startDate: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_FILTER_APPOINTMENT_END_DATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            endDate: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_FILTER_TOP_DATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            topDate: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_FILTER_FIXED_DATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            fixedDate: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_FILTER_PAIN_SCORE_PAIR:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            painScorePair: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_FILTER_BODY_LOCATION_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            bodyLocationId: action.payload,
          },
        },
      };
    case ACTION_TYPES.SET_FILTER_APPOINTMENT_STATUS_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: {
            ...state.ui.filter,
            appointmentStatusId: action.payload,
          },
        },
      };
    case ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        ui: {
          ...state.ui,
          filter: initialState.ui.filter,
        },
      };
    default:
      return state;
  }
};

export const selectPatientAppointments = (state) =>
  state.patientappointments.data.list;
export const selectFilter = (state) => state.patientappointments.ui.filter;
export const selectFilterDateFilter = (state) =>
  state.patientappointments.ui.filter.dateFilter;
export const selectFilterStartDate = (state) =>
  state.patientappointments.ui.filter.startDate;
export const selectFilterEndDate = (state) =>
  state.patientappointments.ui.filter.endDate;
export const selectFilterTopDate = (state) =>
  state.patientappointments.ui.filter.topDate;
export const selectFilterFixedDate = (state) =>
  state.patientappointments.ui.filter.fixedDate;
export const selectFilterPainScorePair = (state) =>
  state.patientappointments.ui.filter.painScorePair;
export const selectFilterBodyLocationId = (state) =>
  state.patientappointments.ui.filter.bodyLocationId;
export const selectFilterAppointmentStatusId = (state) =>
  state.patientappointments.ui.filter.appointmentStatusId;
