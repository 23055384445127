import React from 'react';
import AppAnimate from '@e74/core/AppAnimate';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import IntlMessages from '../../utility/IntlMessages';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {selectUseNevro} from 'redux/reducers/admin/instance-settings';
const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 70,
    backgroundColor: '#7E57FE',
    height: '100%',
    overflow: 'scroll',
    right: 0,
    paddingTop: 5,
    width: 350,
    paddingBottom: 55,
    marginRight: 25,
  },
  patientList: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    display: 'block',
    borderBottomColor: 'gray',
    backgroundColor: '#7E57FE',
    color: '#fff',
    transitionDuration: 300,
    '&:hover': {
      backgroundColor: '#6b8e23',
    },
    '& span': {
      margin: '0px 20px',
    },
    paddingLeft: 5,
  },
  patientListNevro: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    display: 'block',
    borderBottomColor: 'gray',
    backgroundColor: '#00B6EE',
    color: '#fff',
    transitionDuration: 300,
    '&:hover': {
      backgroundColor: '#ffff',
      color: '#00B6EE',
      fontWeight: 'bold',
    },
    '& span': {
      margin: '0px 20px',
    },
    paddingLeft: 5,
  },
  noResults: {
    color: 'white',
  },
  nevroRoot: {
    backgroundColor: '#00B6EE',
  },
}));
const Results = ({data, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const useNevro = useSelector(selectUseNevro);

  return (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <Box
        className={
          useNevro ? `${classes.root} ${classes.nevroRoot}` : classes.root
        }>
        {data?.length > 0 ? (
          <Box component='div' fontSize={16}>
            {data?.map((patient, index) => (
              <Link
                key={index}
                to={'/patients/patientdashboard/' + patient.id}
                className={
                  useNevro
                    ? `${classes.patientListNevro} ${classes.nevroRoot}`
                    : classes.patientList
                }
                onClick={props.onHandleCloseSearch}>
                <span>
                  {patient.fullName} - {patient.birthDate}
                </span>
              </Link>
            ))}
          </Box>
        ) : (
          <Box
            component='h3'
            mb={3}
            className={
              useNevro
                ? `${classes.noResults} ${classes.nevroRoot}`
                : classes.noResults
            }
            style={{backgroundColor: useNevro && '#00B6EE'}}>
            <IntlMessages id='common.noMatchingRecord' />
          </Box>
        )}
      </Box>
    </AppAnimate>
  );
};

export default Results;
