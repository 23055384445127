import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_ALL_GENDERS: 'FETCH_ALL_GENDERS',
};

export const fetchAllGenders = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .gender()
    .fetchAll()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL_GENDERS,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
