import React, {useContext, useEffect, useState} from 'react';
import AppHeaderNevro from './AppHeaderNevro';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppSidebarNevro from './AppSidebarNevro';
import {ContentView} from '../../../index';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppContext from '../../../utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import AppLogoWhite from '../../../../shared/components/AppLogoWhite';
import {useSelector} from 'react-redux';
import {selectInPatientView} from 'redux/reducers/patient/patient';
import {
  selectEnablePortalPro,
  selectIsPortalProClinic,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import ApiConfig from '@e74/services/ApiConfig';

const StandardLayout = (props) => {
  const classes = useStyles(props);
  const {footer, layoutType, footerType} = useContext(AppContext);
  const inPatientView = useSelector(selectInPatientView);
  const useNevro = useSelector(selectUseNevro);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);

  if (useNevro === undefined) {
    return <></>;
  }

  return (
    <>
      {inPatientView ? (
        <Box>
          <AppLogoWhite />
          <ContentView />
        </Box>
      ) : (
        <Box
          className={clsx(
            classes.appMain,
            layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
            {
              appMainFooter: footer && footerType === 'fluid',
              appMainFixedFooter: footer && footerType === 'fixed',
            },
          )}>
          {useNevro && !isPortalProClinic ? (
            <AppSidebarNevro />
          ) : (
            <AppSidebar />
          )}

          <Box className={classes.mainContent}>
            <Hidden mdDown>
              <Box className={classes.mainContainer}>
                {useNevro && !isPortalProClinic ? (
                  <AppHeaderNevro />
                ) : (
                  <AppHeader />
                )}
                <ContentView />
                <AppFixedFooter />
              </Box>
            </Hidden>

            <Hidden lgUp>
              <Box className={classes.mainContainerFull}>
                {useNevro && !isPortalProClinic ? (
                  <AppHeaderNevro />
                ) : (
                  <AppHeader />
                )}
                <ContentView />
                <AppFixedFooter />
              </Box>
            </Hidden>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StandardLayout;
