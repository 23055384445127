import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
// import moment from 'moment';
import {
  createMuiTheme,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import AppContext from '../AppContext';
import AppLocale from '../../../shared/localization';
import {responsiveFontSizes} from '@material-ui/core';
import {isBreakPointDown} from '../Utils';
import {
  ThemeStyle,
  Fonts,
  FontsNevro,
  ThemeStyleRadius,
} from '../../../shared/constants/AppEnums';
import {useUrlSearchParams} from 'use-url-search-params';
import defaultConfig from '../ContextProvider/defaultConfig';
import {
  selectIsPortalProClinic,
  selectUseNevro,
} from 'redux/reducers/admin/instance-settings';
import {useSelector} from 'react-redux';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const cardRadius = ThemeStyleRadius.STANDARD;
const breakpoints = createBreakpoints({});

const E74ThemeProvider = (props) => {
  const {
    theme,
    isRTL,
    updateThemeMode,
    changeNavStyle,
    updateThemeStyle,
    setRTL,
    updateTheme,
    locale,
  } = useContext(AppContext);
  const {muiLocale} = AppLocale[locale.locale];
  const useNevro = useSelector(selectUseNevro);
  const isPortalProClinic = useSelector(selectIsPortalProClinic);

  const [params] = useUrlSearchParams({});

  if (useNevro === true) {
    document
      .getElementById('favicon')
      .setAttribute('href', '/favicons/nevro.ico');
  }

  useEffect(() => {
    if (isPortalProClinic) {
      updateTheme(defaultConfig.theme);
    } else {
      if (useNevro) {
        const nevroTheme = {
          ...theme,
          typography: {
            fontFamily: [FontsNevro.REGULAR, 'sans-serif'].join(','),
          },
          palette: {
            ...theme.palette,
            primary: {
              main: '#00B6EE',
              contrastText: '#fff',
            },
          },
          overrides: {
            ...theme.overrides,
            MuiButton: {
              root: {
                borderRadius: cardRadius,
                boxShadow: '0px 5px 6px rgb(100, 180, 253, 0.2)',
                [breakpoints.down('md')]: {
                  paddingTop: '8px !important',
                  paddingBottom: '8px !important',
                },
                border: '1px solid #fff',
                '&:hover': {
                  backgroundColor: '#fff !important',
                  color: '#00B6EE !important',
                  border: '1px solid #00B6EE !important',
                },
              },
            },
            MuiCheckbox: {
              root: {
                color: '#00B6EE !important',
              },
            },
            MuiRadio: {
              root: {
                color: '#00B6EE !important',
              },
            },
            MuiAutocomplete: {
              option: {
                background: '#00B6EE',
                '&:hover': {
                  background: '#298094',
                },
              },
            },
            MuiMenu: {
              root: {
                backgroundColor: '#00B6EE',
              },
            },
            MuiMenuItem: {
              root: {
                '&:hover': {
                  backgroundColor: '#fff !important',
                },
                '&.Mui-selected': {
                  color: '#00B6EE !important',
                },
              },
            },
            MuiInputLabel: {
              root: {
                color: '#006E9F !important',
              },
            },
          },
        };
        updateTheme(nevroTheme);
      } else {
        const regularTheme = {
          ...theme,
          typography: {
            fontFamily: [Fonts.REGULAR, 'sans-serif'].join(','),
          },
          palette: {
            ...theme.palette,
            background: {
              // paper: '#FFFFFF',
              default: '#f3f4f6',
            },
          },
        };
        updateTheme(regularTheme);
      }
    }
  }, [useNevro, isPortalProClinic]);

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.theme_mode) {
        updateThemeMode(params.theme_mode);
      }
    };
    updateQuerySetting();
  }, [params.theme_mode, updateThemeMode]);

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.is_rtl) {
        setRTL(params.is_rtl);
      }
      if (params.is_rtl || isRTL) {
        document.body.setAttribute('dir', 'rtl');
      } else {
        document.body.setAttribute('dir', 'ltr');
      }
    };
    updateQuerySetting();
  }, [isRTL, params.is_rtl, setRTL]);

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.nav_style) {
        changeNavStyle(params.nav_style);
      }
    };
    updateQuerySetting();
  }, [changeNavStyle, params.nav_style]);

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.theme_style) {
        if (params.theme_style === ThemeStyle.MODERN) {
          if (isBreakPointDown('md')) {
            theme.overrides.MuiCard.root.borderRadius = 20;
            theme.overrides.MuiToggleButton.root.borderRadius = 20;
          } else {
            theme.overrides.MuiCard.root.borderRadius = 30;
            theme.overrides.MuiToggleButton.root.borderRadius = 30;
          }
          theme.overrides.MuiButton.root.borderRadius = 30;
          theme.overrides.MuiCardLg.root.borderRadius = 50;
        } else {
          theme.overrides.MuiCard.root.borderRadius = 4;
          theme.overrides.MuiToggleButton.root.borderRadius = 4;
          theme.overrides.MuiButton.root.borderRadius = 4;
          theme.overrides.MuiCardLg.root.borderRadius = 4;
        }
        updateTheme(theme);
        updateThemeStyle(params.theme_style);
      }
    };
    updateQuerySetting();
  }, [params.theme_style, theme, updateTheme, updateThemeStyle]);

  return (
    <ThemeProvider
      theme={responsiveFontSizes(createMuiTheme(theme, muiLocale))}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {props.children}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default React.memo(E74ThemeProvider);

E74ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
