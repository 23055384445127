import React from 'react';
import {ListItem, withStyles} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalNavGroup.style';
import {
  selectUseNevro,
  selectUseMainstay,
} from 'redux/reducers/admin/instance-settings';
import {useDispatch, useSelector} from 'react-redux';
const VerticalNavGroup = ({classes, ...props}) => {
  const {item, level} = props;
  const useNevro = useSelector(selectUseNevro);
  const useMainstay = useSelector(selectUseMainstay);
  let isParent = false;
  let isChild = false;
  if (localStorage.getItem('isParent')) {
    isParent = localStorage.getItem('isParent') === 'true';
  }
  if (localStorage.getItem('isChild')) {
    isChild = localStorage.getItem('isChild') === 'true';
  }
  let userRole = localStorage.getItem('userRole');
  let isSystemManager = userRole.indexOf('System Manager') > -1;

  const canRenderThisItem = (item) => {
    if (!useNevro) {
      if (item.hideOnNevroNotEnabled !== undefined) {
        if (item.hideOnNevroNotEnabled) {
          return false;
        }
      }
    }

    if (isSystemManager) {
      return true;
    }

    if (isParent) {
      if (useMainstay) {
        if (item.mainstayParent) {
          return true;
        } else {
          return false;
        }
      }

      if (item.isParent !== undefined) {
        if (item.isParent === isParent && isParent === true) {
          return true;
        }
      }
      return false;
    }

    if (isChild) {
      if (useMainstay) {
        if (item.mainstayChild) {
          return item.access.indexOf(userRole) > -1;
        } else {
          return false;
        }
      }
    }

    if (useNevro) {
      if (item.nevro === undefined) {
        return true;
      }
      return item.nevro === useNevro;
    }

    if (item.access.indexOf(userRole) > -1) {
      return true;
    }

    return false;
  };

  return (
    <>
      <ListItem
        component='li'
        className={clsx(classes.navItem, 'nav-item nav-item-header')}>
        {<IntlMessages id={item.messageId} />}
      </ListItem>

      {item.children && (
        <>
          {item.children.map(
            (children) =>
              canRenderThisItem(item) && (
                <React.Fragment key={item.id}>
                  {children.type === 'group' && (
                    <NavVerticalGroup item={children} level={level} />
                  )}

                  {children.type === 'collapse' && (
                    <VerticalCollapse item={children} level={level} />
                  )}

                  {children.type === 'item' && (
                    <VerticalItem item={children} level={level} />
                  )}
                </React.Fragment>
              ),
          )}
        </>
      )}
    </>
  );
};

VerticalNavGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

VerticalNavGroup.defaultProps = {};

const NavVerticalGroup = VerticalNavGroup;

export default withStyles(useStyles)(NavVerticalGroup);
