import {ACTION_TYPES} from '../../actions/Common/Quiz';

let initialQuiz = {
  quiz: {
    config: {
      allowBack: true,
      allowReview: true,
      autoMove: true, // if true, it will move to next question automatically when answered.
      duration: 0, // indicates the time in which quiz needs to be completed. 0 means unlimited.
      pageSize: 1,
      requiredAll: false, // indicates if you must answer all the questions before submitting.
      richText: false,
      shuffleQuestions: false,
      shuffleOptions: false,
      showClock: false,
      showPager: true,
      theme: 'none',
    },
  },
  mode: 'quiz',
  pager: {
    index: 0,
    size: 1,
    count: 1,
  },
};

export const quiz = (state = {...initialQuiz}, action) => {
  switch (action.type) {
    case ACTION_TYPES.PagerUpdate:
      return {
        ...state,
        pager: action.payload,
        mode: 'quiz',
      };

    case ACTION_TYPES.QuizLoad:
      return {
        ...state,
        quiz: action.payload,
      };

    case ACTION_TYPES.QuizSubmit: {
      return {
        ...state,
        quiz: action.payload,
      };
    }

    case ACTION_TYPES.QuizAnswer:
      return {
        ...state,
        quiz: action.payload,
      };

    default:
      return state;
  }
};
