import React from 'react';
import {Provider} from 'react-redux';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@e74/core/AppLayout';
import AuthRoutes from '@e74/utility/AuthRoutes';
import LocaleProvider from '@e74/utility/LocaleProvider';
import E74ThemeProvider from '@e74/utility/E74ThemeProvider';
import E74StyleProvider from '@e74/utility/E74StyleProvider';
import ContextProvider from '@e74/utility/ContextProvider';
import ErrorBoundary from 'shared/components/ErrorHandling/ErrorBoundary';
import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import ApiConfig from '@e74/services/ApiConfig';

const store = configureStore();

const App = () => {
  React.useEffect(() => {
    ApiConfig.instanceSettings()
      .fetchClinicName()
      .then((response) => {
        if (response) document.title = response.data;
      });
  }, []);

  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot remove a child from a different parent',
            child,
            this,
          );
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };
    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }

  return (
    <ContextProvider>
      <Provider store={store}>
        <E74ThemeProvider>
          <E74StyleProvider>
            <LocaleProvider>
              <ConnectedRouter history={history}>
                <ErrorBoundary>
                  <AuthRoutes>
                    <CssBaseline />
                    <AppLayout />
                  </AuthRoutes>
                </ErrorBoundary>
              </ConnectedRouter>
            </LocaleProvider>
          </E74StyleProvider>
        </E74ThemeProvider>
      </Provider>
    </ContextProvider>
  );
};

export default App;
