import {ACTION_TYPES} from 'redux/actions/custom-fields';
import {CustomFieldType} from 'shared/constants/AppEnums';

const initialState = {
  data: {
    list: [],
  },
};

export const customFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {...state, data: {...state.data, list: action.payload}};
    case ACTION_TYPES.FETCH_GENDERS: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.GENDER,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    case ACTION_TYPES.FETCH_APPOINTMENT_TYPES: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.APPOINTMENT_TYPE,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    case ACTION_TYPES.FETCH_MARITAL_STATUSES: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.MARITAL_STATUS,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    case ACTION_TYPES.FETCH_OCCUPATION_STATUSES: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.OCCUPATION_STATUS,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    case ACTION_TYPES.FETCH_PAYOR_STATUSES: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.PAYOR_STATUS,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    case ACTION_TYPES.FETCH_RACES: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.RACE,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    case ACTION_TYPES.FETCH_ETHNICITIES: {
      const stateCopy = {...state};
      stateCopy.data.list = stateCopy.data.list.filter(
        (customField) => customField.type !== CustomFieldType.ETHNICITY,
      );
      stateCopy.data.list = [...stateCopy.data.list, ...action.payload];
      return stateCopy;
    }
    default:
      return state;
  }
};

export const selectGenders = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) => customFieldType?.type === CustomFieldType.GENDER,
  );

export const selectRaces = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) => customFieldType?.type === CustomFieldType.RACE,
  );

export const selectOccupationStatuses = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) =>
      customFieldType?.type === CustomFieldType.OCCUPATION_STATUS,
  );

export const selectAppointmentTypes = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) =>
      customFieldType?.type === CustomFieldType.APPOINTMENT_TYPE,
  );

export const selectMaritalStatuses = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) =>
      customFieldType?.type === CustomFieldType.MARITAL_STATUS,
  );

export const selectPayorStatuses = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) => customFieldType?.type === CustomFieldType.PAYOR_STATUS,
  );

export const selectEthnicities = (state) =>
  state.customFields.data.list?.filter(
    (customFieldType) => customFieldType?.type === CustomFieldType.ETHNICITY,
  );
