import {onGetDashboardData} from './patient/dashboard';
import {fetchFiltered as fetchFilteredPatients} from './patient/patient';
import {fetchFiltered as fetchFilteredAppointments} from './patient/patientappointments';
import {
  fetchSummary,
  setFilterPatientName as setReportSummaryFilterPatientName,
} from './reports/summary';
import {fetchByTemplateId} from './admin/perioperativemessagedetails';
import {fetchPatientsSpotlight} from './reports/patientsSpotlight';

export const SET_DOCTOR_ID = 'SET_DOCTOR_ID';
export const setDoctorId = (id, loadData = true) => async (dispatch) => {
  dispatch({
    type: SET_DOCTOR_ID,
    payload: {id},
  });

  if (loadData) {
    dispatch(onGetDashboardData());
    dispatch(fetchFilteredPatients({}));
    dispatch(fetchFilteredAppointments({}));
    dispatch(fetchSummary());
    dispatch(setReportSummaryFilterPatientName(''));
    dispatch(fetchByTemplateId());
    dispatch(fetchPatientsSpotlight());
  }
};
