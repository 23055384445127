import {lazy} from 'react';
import {RESOURCE_SHORTENED_URL_BASE_ROUTE} from 'shared/constants/AppConst';

export const resourcePagesConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/resources/resourceform/:id?',
        component: lazy(() => import('./resourceform')),
      },
      {
        path: '/resources/activity',
        component: lazy(() => import('./resourcefileactivity')),
      },
      {
        path: '/resources/list',
        component: lazy(() => import('./resourceslist')),
      },
    ],
  },
  {
    routes: [
      {
        path: `/${RESOURCE_SHORTENED_URL_BASE_ROUTE}/:shortenedUrl`,
        component: lazy(() => import('./ShortenedUrlRedirector')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/resources/faq',
        component: lazy(() => import('./faq/index')),
      },
    ],
  },
];
