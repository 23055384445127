import api from '@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';
import {CustomFieldType} from 'shared/constants/AppEnums';

export const ACTION_TYPES = {
  FETCH_ALL: 'FETCH_ALL_INSTANCE_SETTINGS',
  FETCH_GENDERS: 'FETCH_CUSTOM_FIELDS_GENDER',
  FETCH_RACES: 'FETCH_CUSTOM_FIELDS_RACES',
  FETCH_APPOINTMENT_TYPES: 'FETCH_CUSTOM_FIELDS_APPOINTMENT_TYPES',
  FETCH_MARITAL_STATUSES: 'FETCH_CUSTOM_FIELDS_MARITAL_STATUSES',
  FETCH_OCCUPATION_STATUSES: 'FETCH_CUSTOM_FIELDS_OCCUPATION_STATUSES',
  FETCH_PAYOR_STATUSES: 'FETCH_CUSTOM_FIELDS_PAYOR_STATUSES',
  FETCH_ETHNICITIES: 'FETCH_CUSTOM_FIELDS_ETHNICITIES',
};

export const fetchAll = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettins()
    .fetchAll()
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_ALL, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchGenders = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.GENDER)
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_GENDERS, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchMaritalStatuses = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.MARITAL_STATUS)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_MARITAL_STATUSES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchOccupationStatuses = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.OCCUPATION_STATUS)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_OCCUPATION_STATUSES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchPayorStatuses = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.PAYOR_STATUS)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_PAYOR_STATUSES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchRaces = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.RACE)
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_RACES, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchAppointmentTypes = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.APPOINTMENT_TYPE)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_APPOINTMENT_TYPES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchEthnicities = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .customFields()
    .fetchAll(CustomFieldType.ETHNICITY)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ETHNICITIES,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
