import {ACTION_TYPES} from '../../actions/reports/patientsPerformanceMeasure';

const initialState = {
  list: {data: []},
  filter: {
    dateFilter: 1,
    startDate: null,
    endDate: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PATIENTS_PERFORMANCE_MEASURE:
      return {...state, list: action.payload};
    case ACTION_TYPES.SET_FILTER_DATE_FILTER:
      return {
        ...state,
        filter: {...state.filter, dateFilter: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_APPOINTMENT_START_DATE:
      return {
        ...state,
        filter: {...state.filter, startDate: action.payload},
      };
    case ACTION_TYPES.SET_FILTER_APPOINTMENT_END_DATE:
      return {
        ...state,
        filter: {...state.filter, endDate: action.payload},
      };
    case ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      };
    default:
      return state;
  }
};

export const selectPatientsPerformanceMeausreList = (state) =>
  state.patientsPerformanceMeasure.list;

export const selectFilter = (state) => state.patientsPerformanceMeasure.filter;
export const selectFilterDateFilter = (state) => selectFilter(state).dateFilter;
export const selectFilterStartDateFilter = (state) =>
  selectFilter(state).startDate;
export const selectFilterEndDateFilter = (state) => selectFilter(state).endDate;
