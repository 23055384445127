import {
  DEFAULT_CASE_STATUS,
  DEFAULT_CURRENT_PAGE_NUMBER,
  DEFAULT_FETCH_PATIENTS,
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
  DEFAULT_PATIENT_STATUS,
  DEFAULT_SORT_BY_OPTION,
} from 'shared/constants/AppConst';
import {ACTION_TYPES} from '../../actions/patient/patient';

const initialState = {
  data: {
    currentPatient: {},
    list: {
      totalRecords: 0,
      pageNumber: 0,
      pageSize: 0,
      data: [],
      message: null,
      portalStatuses: [],
    },
  },
  ui: {
    inPatientView: false,
  },
  sortBy: DEFAULT_SORT_BY_OPTION,
  caseStatus: DEFAULT_CASE_STATUS,
  patientStatus: DEFAULT_PATIENT_STATUS,
  fetchPatients: DEFAULT_FETCH_PATIENTS,
  currentPageNumber: DEFAULT_CURRENT_PAGE_NUMBER,
  rowPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE,
};

export const patient = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...state,
        data: {...state.data, list: {...action.payload}},
      };
    case ACTION_TYPES.FETCH_FILTERED: {
      if (
        state.data.list.portalStatuses?.length > 0 &&
        !state.previousStatusCount
      ) {
        return {
          ...state,
          data: {...state.data, list: {...action.payload}},
        };
      } else {
        return {
          ...state,
          data: {...state.data, list: {...action.payload}},
        };
      }
    }

    case ACTION_TYPES.FETCH_BY_ID:
      return {
        ...state,
        data: {...state.data, currentPatient: action.payload},
      };
    case ACTION_TYPES.SET_IN_PATIENT_VIEW:
      return {...state, ui: {...state.ui, inPatientView: action.payload}};

    case ACTION_TYPES.SET_CASE_STATUS:
      return {
        ...state,
        caseStatus: action.payload,
      };

    case ACTION_TYPES.SET_PATIENT_STATUS:
      return {
        ...state,
        patientStatus: action.payload,
      };

    case ACTION_TYPES.SET_FETCH_PATIENTS:
      return {
        ...state,
        fetchPatients: action.payload,
      };

    case ACTION_TYPES.SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };

    case ACTION_TYPES.SET_CURRENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    case ACTION_TYPES.SET_ROW_PER_PAGE:
      return {
        ...state,
        rowPerPage: action.payload,
      };
    case ACTION_TYPES.START_PATIENT_LOADING:
      return {
        ...state,
        patientLoader: true,
      };
    case ACTION_TYPES.STOP_PATIENT_LOADING:
      return {
        ...state,
        patientLoader: false,
      };

    case ACTION_TYPES.SET_PREVIOUS_STATUS_COUNT:
      return {
        ...state,
        previousStatusCount: {
          ...state.previousStatusCount,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export const selectPatients = (state) => state.patient.data.list;
export const selectCurrentPatient = (state) =>
  state.patient.data.currentPatient;
export const selectCurrentPatientNevroCases = (state) =>
  state.patient.data.currentPatient.nevroCases;
export const selectInPatientView = (state) => state.patient.ui.inPatientView;
export const selectSortBy = (state) => state.patient.sortBy;
export const selectCaseStatus = (state) => state.patient.caseStatus;
export const selectPatientStatus = (state) => state.patient.patientStatus;
export const selectFetchPatients = (state) => state.patient.fetchPatients;
export const selectCurrentPageNumber = (state) =>
  state.patient.currentPageNumber;
export const selectRowPerPage = (state) => state.patient.rowPerPage;
export const selectPatientLoader = (state) => state.patient.patientLoader;

export const selectCasesInProgressCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('cases in progress'),
  )?.count || 0;
export const selectPendingSubmissionCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('pending submission to nevro'),
  )?.count || 0;
export const selectCaseReceivedCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('case received by nevro'),
  )?.count || 0;
export const selectActionRequiredCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('action required by office'),
  )?.count || 0;
export const selectPendingInsuranceCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('case pending with insurance'),
  )?.count || 0;
export const selectPriorAuthNotRequiredCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('prior auth not required'),
  )?.count || 0;
export const selectApprovedCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('approved cases'),
  )?.count || 0;
export const selectDeniedCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('denied cases'),
  )?.count || 0;
export const selectPatientNotMovingForwardCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('patient not moving forward'),
  )?.count || 0;
export const selectProcedurePerformedCount = (state) =>
  state.patient.data.list.portalStatuses?.find((x) =>
    x.status.toLowerCase().includes('procedure performed'),
  )?.count || 0;

export const selectPreviousStatusCount = (state) =>
  state.patient.previousStatusCount;

export const selectPortalStatusList = (state) =>
  state.patient.data.list.portalStatuses;
