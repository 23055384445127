import {selectDoctorId} from 'redux/reducers/doctor';
import {
  selectFilterImprovementFactor,
  selectFilterPatientFirstName,
  selectFilterPatientLastName,
  selectFilterReferringProviderIds,
} from 'redux/reducers/reports/patientsSpotlight';
import {DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'shared/constants/AppConst';
import api from '../../../@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_PATIENTS_SPOTLIGHT: 'FETCH_PATIENTS_SPOTLIGHT_PATIENTS_SPOTLIGHT',
  SET_FILTER_IMPROVEMENT_FACTOR:
    'SET_PATIENTS_SPOTLIGHT_FILTER_IMPROVEMENT_FACTOR',
  SET_FILTER_REFERRING_PROVIDER_IDS:
    'SET_PATIENTS_SPOTLIGHT_FILTER_REFERRING_PROVIDER_IDS',
  SET_FILTER_PATIENT_FIRST_NAME:
    'SET_PATIENTS_SPOTLIGHT_FILTER_PATIENT_FIRST_NAME',
  SET_FILTER_PATIENT_LAST_NAME:
    'SET_PATIENTS_SPOTLIGHT_FILTER_PATIENT_LAST_NAME',
  RESET_FILTER: 'RESET_PATIENTS_SPOTLIGHT_FILTER',
};

export const fetchPatientsSpotlight = (
  pageSize = DEFAULT_PAGINATION_ROWS_PER_PAGE,
  pageNumber = 0,
) => (dispatch, getState) => {
  dispatch(fetchStart());
  const currentReduxState = getState();
  api
    .reports()
    .patientsSpotlight(
      selectFilterImprovementFactor(currentReduxState),
      selectFilterReferringProviderIds(currentReduxState),
      selectFilterPatientFirstName(currentReduxState),
      selectFilterPatientLastName(currentReduxState),
      pageSize,
      pageNumber,
      selectDoctorId(currentReduxState),
    )
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_PATIENTS_SPOTLIGHT,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setFilterImprovementFactor = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_IMPROVEMENT_FACTOR,
    payload: newValue,
  });
};

export const setFilterReferringProviderIds = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_REFERRING_PROVIDER_IDS,
    payload: newValue,
  });
};

export const setFilterPatientFirstName = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_PATIENT_FIRST_NAME,
    payload: newValue,
  });
};

export const setFilterPatientLastName = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_FILTER_PATIENT_LAST_NAME,
    payload: newValue,
  });
};

export const resetFilter = () => (dispatch) => {
  dispatch({type: ACTION_TYPES.RESET_FILTER});
  dispatch(fetchPatientsSpotlight());
};
