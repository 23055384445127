import {lazy} from 'react';

export const usersPagesConfigs = [
  {
    routes: [
      {
        path: '/users/profile/:id',
        component: lazy(() => import('./profileform')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/users/userform/:id?',
        component: lazy(() => import('./userform')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/users/',
        component: lazy(() => import('./userslist')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/logindetails/',
        component: lazy(() => import('./logindetail')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/activitylogs/',
        component: lazy(() => import('../activitylogs')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/nevro/doctor/consent/:id',
        component: lazy(() => import('./nevro/doctor/consent')),
      },
    ],
  },
];
