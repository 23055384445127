import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import routesConfig from '../../../../modules/routesConfig';
import Box from '@material-ui/core/Box';

const HorizontalNav = () => {
  return (
    <List className='navbarNav'>
      {routesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' &&
            item.access.indexOf(localStorage.getItem('userRole')) > -1 && (
              <HorizontalGroup item={item} nestedLevel={0} />
            )}

          {item.type === 'collapse' &&
            item.access.indexOf(localStorage.getItem('userRole')) > -1 && (
              <HorizontalCollapse item={item} nestedLevel={0} />
            )}

          {item.type === 'item' &&
            item.access.indexOf(localStorage.getItem('userRole')) > -1 && (
              <HorizontalItem item={item} nestedLevel={0} />
            )}

          {item.type === 'divider' &&
            item.access.indexOf(localStorage.getItem('userRole')) > -1 && (
              <Box my={5} clone>
                <Divider />
              </Box>
            )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;
