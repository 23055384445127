import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
  makeStyles,
  InputAdornment,
  TextField,
  Paper,
  ThemeProvider,
} from '@material-ui/core';

import { LocalHospitalOutlined, PersonOutlined } from '@material-ui/icons';

import { useSelector, useDispatch } from 'react-redux';
import { setDoctorId } from '../../../redux/actions/doctor';
import ApiConfig from '@e74/services/ApiConfig';
import { setClinicId } from 'redux/actions/clinic';
import { selectUseNevro } from 'redux/reducers/admin/instance-settings';
import { Autocomplete } from '@material-ui/lab';
import { nevroDropdownTheme } from '@e74/utility/Utils';
import clinic from 'redux/reducers/clinic';

export default () => {
  const dispatch = useDispatch();
  const selectedClinicId = useSelector((state) => state.clinic.id);
  const selectedDoctorId = useSelector((state) => state.doctor.id);
  const [availableDoctors, setAvailableDoctors] = useState([
    {
      firstName: 'Select',
      lastName: 'Doctor',
      disableSelect: true,
    },
    {
      id: '',
      firstName: 'Show',
      lastName: 'All',
    },
  ]);
  const [availableClinics, setAvailableClinics] = useState([
    {
      name: 'Select Clinic',
      disableSelect: true,
    },
    {
      id: '',
      name: 'Nevro HFX Access',
    },
  ]);
  const useNevro = useSelector(selectUseNevro);

  const isParent = JSON.parse(localStorage.getItem('isParent')) || false;

  const useStyles = makeStyles(() => ({
    doctorSelect: {
      '& .MuiSelect-root': {
        whiteSpace: 'nowrap !important',
      },
    },

    clinicSelectNevro: {
      color: 'black',
      borderRadius: '0.3rem',
      // marginTop: 15,
      position: 'fixed',
      bottom: 70,
      width: 280
    },

    container: {
      width: '270px',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      // paddingLeft: '5px',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    fetchClinics();
    let clinicId = localStorage.getItem('clinicId') ?? '';
    let doctorId = localStorage.getItem('doctorId') ?? '';

    dispatch(setClinicId(clinicId));
    dispatch(setDoctorId(doctorId, false));
    // setTimeout(() => {
    //   if (clinicId) {
    fetchDoctors();
    //   }
    // }, 500);
  }, []);

  useEffect(() => {
    dispatch(setClinicId(localStorage.getItem('clinicId') ?? ''));
  }, [availableClinics]);

  const handleChangeClinicId = (e) => {
    const newValue = e.target.value === 0 ? '' : e.target.value;

    localStorage.removeItem('doctorId');
    localStorage.setItem('clinicId', newValue);
    dispatch(setDoctorId('', false));
    dispatch(setClinicId(newValue));

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleChangeClinicIdNevro = (newValue) => {
    const selectedValue = newValue ? newValue.id : '';

    localStorage.removeItem('doctorId');
    localStorage.setItem('clinicId', selectedValue);
    dispatch(setDoctorId('', false));
    dispatch(setClinicId(selectedValue));

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleChangeDoctorIdNevro = (newValue) => {
    const selectedValue = newValue ? newValue.id : 0;
    const newValueToSet = selectedValue === 0 ? '' : selectedValue;

    localStorage.setItem('doctorId', newValueToSet);
    dispatch(setDoctorId(newValueToSet, false));

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleChangeDoctorId = (e) => {
    const newValue = e.target.value === 0 ? '' : e.target.value;

    localStorage.setItem('doctorId', newValue);

    dispatch(setDoctorId(newValue, false));

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const fetchDoctors = () =>
    ApiConfig.users()
      .fetchAll({
        paginationFilter: { pageSize: 999, pageNumber: 0 },
        firstName: '',
        lastName: '',
        email: '',
        role: 'Doctor',
        status: 1,
      })
      .then((response) => {
        setAvailableDoctors([...availableDoctors, ...response.data.data]);
      })
      .catch((error) => {
        console.error(error);
      });

  const fetchClinics = () =>
    ApiConfig.parent()
      .getClinics()
      .then((response) => {
        if (response.data) {
          const clinics = response.data;
          // clinics.sort();
          clinics.sort((a, b) => a.name.localeCompare(b.name));
          // setAvailableClinics([...availableClinics, ...response.data]);
          setAvailableClinics([...availableClinics, ...clinics]);
        }
      })
      .catch((error) => {
        console.error(error);
      });

  const setDoctorIdFromLocalStorage = () => {
    const currentUserRole = localStorage.getItem('userRole');
    const currentUserId = localStorage.getItem('userId');
    currentUserRole === 'Doctor' && dispatch(setDoctorId(currentUserId, false));
  };

  return (
    <>
      {!useNevro ? (
        <Box>
          <FormControl variant='outlined' style={{ width: '10rem' }}>
            <InputLabel id='label-doctor-id'>Select Clinic</InputLabel>
            <Select
              label='Select Doctor'
              labelId='label-doctor-id'
              value={selectedClinicId}
              onChange={handleChangeClinicId}
              className={classes.doctorSelect}>
              <MenuItem value='0' key={0}>
                Nevro HFX Access
              </MenuItem>
              {availableClinics?.map((clinic, index) => (
                <MenuItem value={clinic.id} key={index + 1}>
                  {clinic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='outlined' style={{ width: '10rem' }}>
            <InputLabel id='label-doctor-id'>Select Doctor</InputLabel>
            <Select
              label='Select Doctor'
              labelId='label-doctor-id'
              value={selectedDoctorId || 0}
              onChange={handleChangeDoctorId}
              className={classes.doctorSelect}>
              <MenuItem value={0}>Show all</MenuItem>
              {availableDoctors.map((doctor, index) => (
                <MenuItem value={doctor.id} key={index}>
                  {doctor.firstName} {doctor.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>{' '}
        </Box>
      ) : (
        <Box className={classes.container}>
          {isParent && (
            <ThemeProvider theme={nevroDropdownTheme}>
              <Autocomplete
                id='clinicId'
                name='clinicId'
                className={classes.clinicSelectNevro}
                options={availableClinics}
                getOptionLabel={(clinic) => clinic.name}
                getOptionDisabled={(option) => option.disableSelect === true}
                value={
                  selectedClinicId
                    ? availableClinics.find(
                      (clinic) => clinic.id === selectedClinicId,
                    )
                    : {
                      id: '',
                      name: 'Nevro HFX Access',
                    }
                }
                onChange={(_, newValue) => handleChangeClinicIdNevro(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      style: { padding: 2 },
                      startAdornment: (
                        <InputAdornment position='start'>
                          <LocalHospitalOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </ThemeProvider>
          )}

          {/*         
          <ThemeProvider theme={nevroDropdownTheme}>
            <Autocomplete
              autoHighlight='true'
              id='doctorId'
              name='doctorId'
              className={classes.doctorSelectNevro}
              options={availableDoctors}
              getOptionLabel={(doctor) =>
                `${doctor.firstName} ${doctor.lastName}`
              }
              getOptionDisabled={(option) => option.disableSelect === true}
              value={
                selectedDoctorId
                  ? availableDoctors.find(
                      (doctor) => doctor.id === selectedDoctorId,
                    )
                  : {
                      id: 0,
                      firstName: 'Show',
                      lastName: 'All',
                    }
              }
              onChange={(_, newValue) => handleChangeDoctorIdNevro(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    style: {padding: 0},
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PersonOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </ThemeProvider> */}
        </Box>
      )}
    </>
  );
};
