import api from '../../../@e74/services/ApiConfig';
import {fetchError, hideMessage, fetchStart, fetchSuccess} from '../Common';
export const ACTION_TYPES = {
  CREATE: 'CREATE_ADA_INTERVENTIONAL_LOCATION',
  UPDATE: 'UPDATE_ADA_INTERVENTIONAL_LOCATION',
  DELETE: 'DELETE_ADA_INTERVENTIONAL_LOCATION',
  FETCH_ALL: 'FETCH_ALL_ADA_INTERVENTIONAL_LOCATIONS',
};

export const fetchAll = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .adaInterventionalLocations()
    .fetchAll()
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        payload: response.data,
      });
      dispatch(hideMessage());
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const create = (data, onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .adaInterventionalLocations()
    .create(data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.CREATE,
        payload: res.data,
      });
      //dispatch(showMessage('Interventional Location added successfully'));
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const update = (id, data, onSuccess) => (dispatch) => {
  dispatch(fetchStart());
  api
    .adaInterventionalLocations()
    .update(id, data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.UPDATE,
        payload: {id, ...data},
      });
      //dispatch(showMessage('Interventional Location updated successfully'));
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const Delete = (id) => (dispatch) => {
  dispatch(fetchStart());
  api
    .adaInterventionalLocations()
    .delete(id)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.DELETE,
        payload: id,
      });
      //dispatch(showMessage('Interventional Location deleted successfully'));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
