import api from '@e74/services/ApiConfig';
import {fetchError, fetchSuccess} from '../Common';
export const ACTION_TYPES = {
  CREATE: 'CREATE',
  FETCH_ALL: 'FETCH_ALL',
  FETCH_BY_ID: 'FETCH_BY_ID',
};

export const fetchAll = (pageSize, pageNumber, onSuccess, onError) => (
  dispatch,
) => {
  api
    .resourceFileActivities()
    .fetchAll(pageSize, pageNumber)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        payload: response.data,
      });
      onSuccess(response);
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const create = (data, onSuccess, onError) => {
  return async (dispatch) => {
    api
      .resourceFileActivities()
      .create(data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch({
            type: ACTION_TYPES.CREATE,
            payload: res.data,
          });
          dispatch(fetchSuccess());
          onSuccess();
        } else {
          dispatch(fetchError(res.response.data.error));
          dispatch(fetchError(res));
          onError(res.errors);
        }
      })
      .catch((err) => {
        onError();
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err));
      });
  };
};

export const fetchById = (id, onSuccess) => (dispatch) => {
  api
    .resourceFileActivities()
    .fetchById(id)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.FETCH_BY_ID,
        payload: id,
      });
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
