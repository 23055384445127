import {ACTION_TYPES} from 'redux/actions/time-zones';

const initialState = {
  data: {
    list: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {...state, data: {...state.data, list: action.payload}};
    default:
      return state;
  }
};

export const selectTimeZones = (state) => state.timeZones.data.list;
