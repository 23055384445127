import axios from 'axios';

const jwtAxios = axios.create({
  baseURL: window.SERVER_URL,
  withCredentials: false,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

jwtAxios.interceptors.request.use(
  (config) => {
    let clinicId = localStorage.getItem('clinicId');
    if (clinicId) {
      config.headers['ClinicId'] = clinicId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      delete jwtAxios.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('userRole');
      localStorage.removeItem('isParent');
      localStorage.removeItem('isChild');
      localStorage.removeItem('clinicId');
      localStorage.removeItem('doctorId');
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
