import {ACTION_TYPES} from '../../actions/searchbar';

const initialState = {
  list: {},
  showResults: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {...state, list: action.payload};
    case ACTION_TYPES.SET_SHOW_RESULTS:
      return {...state, showResults: action.payload};
    default:
      return state;
  }
};
