import {ACTION_TYPES} from '../../actions/admin/otherenum';

const initialState = {
  list: [],
};

export const otherenum = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL_GENDERS:
      return {
        ...state,
        list: [...action.payload],
      };

    default:
      return state;
  }
};
