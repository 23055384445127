import React, {useEffect, useState} from 'react';
import {
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {setDoctorId} from '../../../redux/actions/doctor';
import ApiConfig from '@e74/services/ApiConfig';

export default () => {
  const dispatch = useDispatch();
  const selectedDoctorId = useSelector((state) => state.doctor.id);
  const [availableDoctors, setAvailableDoctors] = useState([{}]);
  const useStyles = makeStyles(() => ({
    doctorSelect: {'& .MuiSelect-root': {whiteSpace: 'nowrap !important'}},
  }));
  const classes = useStyles();

  useEffect(() => {
    setDoctorIdFromLocalStorage();
    fetchDoctors();
  }, []);

  const handleChangeDoctorId = (e) => {
    const newValue = e.target.value === 0 ? '' : e.target.value;

    // Removing the translation then setting it back to prevent errors
    let translateToLanguageSelect = document.querySelector(
      'select.goog-te-combo',
    );
    var chosenTranslateToLanguageSelect = translateToLanguageSelect.value;

    if (chosenTranslateToLanguageSelect) {
      let googleTranslateBanner = document.querySelector(
        'iframe.goog-te-banner-frame.skiptranslate',
      );
      let stopTranslatingButton = googleTranslateBanner.contentWindow.document.querySelector(
        'a.goog-close-link',
      );
      stopTranslatingButton.dispatchEvent(new Event('click'));
    }

    setTimeout(() => {
      dispatch(setDoctorId(newValue));

      if (chosenTranslateToLanguageSelect) {
        translateToLanguageSelect.value = chosenTranslateToLanguageSelect;
        translateToLanguageSelect.dispatchEvent(new Event('change'));
      }
    }, 500);
  };

  const fetchDoctors = () =>
    ApiConfig.users()
      .fetchAll({
        paginationFilter: {pageSize: 999, pageNumber: 0},
        firstName: '',
        lastName: '',
        email: '',
        role: 'Doctor',
      })
      .then((response) => {
        setAvailableDoctors(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

  const setDoctorIdFromLocalStorage = () => {
    const currentUserRole = localStorage.getItem('userRole');
    const currentUserId = localStorage.getItem('userId');
    currentUserRole === 'Doctor' && dispatch(setDoctorId(currentUserId));
  };

  return (
    <Box
      style={{
        display: localStorage.getItem('userRole') === 'Doctor' && 'none',
      }}>
      <FormControl variant='outlined' style={{width: '10rem'}}>
        <InputLabel id='label-doctor-id'>Select Doctor</InputLabel>
        <Select
          label='Select Doctor'
          labelId='label-doctor-id'
          value={selectedDoctorId || 0}
          onChange={handleChangeDoctorId}
          className={classes.doctorSelect}>
          <MenuItem value={0}>Show all</MenuItem>
          {availableDoctors?.map((doctor, index) => (
            <MenuItem value={doctor.id} key={index}>
              {doctor.firstName} {doctor.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
