import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {setJWTToken, setUserId, setUserRole} from '../../redux/actions';
import jwtAxios from '../services/auth';
import {UPDATE_AUTH_USER} from '../../shared/constants/ActionTypes';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector(({auth}) => auth);
  useEffect(() => {
    const userAthCheck = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token !== null) {
          dispatch(setJWTToken(token));
          const userId = localStorage.getItem('userId');
          const res = await jwtAxios.get('/users/' + userId);
          dispatch({
            type: UPDATE_AUTH_USER,
            payload: {
              _id: res.data.id,
              displayName: res.data.firstName + ' ' + res.data.lastName,
              email: res.data.email,
              profileImage: res.data.profileImage,
              role: res.data.role,
              isParent: res.data.isParent,
            },
          });
        }
      } catch (e) {
        dispatch(setJWTToken(null));
        dispatch(setUserId(null));
        dispatch(setUserRole(null));
        return null;
      }
    };

    const checkAuth = () => {
      Promise.all([userAthCheck()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [user, loading];
};

export const useAuthUser = () => {
  const authUser = useSelector(({auth}) => auth.user);
  if (authUser) {
    return authUser;
  }
  return {
    displayName: 'Loading',
  };
};
