import {lazy} from 'react';

export const demoPagesConfigs = [
  {
    routes: [
      {
        path: '/demo/dashboard',
        component: lazy(() => import('./demoDashboard')),
      },
      {
        path: '/demo/educationalResources',
        component: lazy(() => import('./educationalResources')),
      },
    ],
  },
];
