import api from '@e74/services/ApiConfig';
import {fetchError, fetchSuccess, showMessage} from '../Common';
export const ACTION_TYPES = {
  ADD_PROCEDURES: 'ADD_PROCEDURES',
  COMPLIANCE_UPDATE: 'COMPLIANCE_UPDATE',
};

export const upsertProcedures = (data, onSuccess) => (dispatch) => {
  api
    .patientappointmentprocedures()
    .upsert(data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.ADD_PROCEDURES,
        payload: data,
      });
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const complianceUpdate = (data, onSuccess) => (dispatch) => {
  api
    .patientappointmentprocedures()
    .complianceupdate(data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.COMPLIANCE_UPDATE,
        payload: data,
      });
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
