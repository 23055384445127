import { lazy } from 'react';

export const customFormsPagesConfigs = [
  {
    routes: [
      {
        path: '/customform/:id/surveryform',
        component: lazy(() => import('./customform/displaycustomform')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/nevroaccessrequest',
        component: lazy(() => import('./customform/nevroAccessRequest')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/customform/:id/reactiveight/:appointmentid',
        component: lazy(() => import('./customform/reactiv')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/mainstaysubmission',
        component: lazy(() => import('./submissionpages/mainstaythankyou')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/submissionexists',
        component: lazy(() => import('./submissionpages/submissionexist')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/mainstaycancel',
        component: lazy(() => import('./submissionpages/mainstaycancel')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/customformresponse/view/:id',
        component: lazy(() => import('./customformresponse/patientResponse')),
      },
    ],
  },
];
