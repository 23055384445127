import api from '@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';

export const ACTION_TYPES = {
  FETCH_ALL: 'FETCH_ALL_TIME_ZONES',
};

export const fetchAll = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .timeZones()
    .fetchAll()
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_ALL, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
