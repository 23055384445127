import React, {useState, useEffect, useRef} from 'react';
import {Box, fade, InputBase, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import {Fonts} from '../../../shared/constants/AppEnums';
import Results from './results';
import {useSelector, useDispatch} from 'react-redux';
import {fetchAll, setShowResults} from '../../../redux/actions/searchbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: 20,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: (props) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginLeft: props.align === 'right' ? 'auto' : 0,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: '50%',
      zIndex: 1,
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 15,
    },
  }),
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.right': {
      left: 'auto',
      right: 0,
      '& + $inputRoot $inputInput': {
        paddingLeft: theme.spacing(2),
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    backgroundColor: '#F3F3F3',
    fontSize: '15px !important',
    width: 350,
    height: 35,
    borderRadius: 4,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  inputBase: {
    backgroundColor: 'transparent',
    // fontFamily: Fonts.MEDIUM,
    border: '1px solid',
    borderColor: (props) =>
      props.borderLight ? '#efefef' : theme.palette.text.hint,
    color: 'black',
    borderRadius: 4,

    '& > .Mui-focused': {
      borderColor: 'red',
    },
  },
  searchIconBox: {
    position: 'relative',
    '& $inputInput': {
      width: 35,
      borderRadius: 50,
      paddingLeft: 27,
      '&:focus': {
        width: 235,
        borderRadius: 4,
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
    '& $searchIcon': {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
}));

const AppSearch = ({
  placeholder,
  iconPosition,
  align,
  borderLight,
  onlyIcon,
  containerStyle,
  inputStyle,
  iconStyle,
  ...rest
}) => {
  const classes = useStyles({borderLight, align});
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const patients = useSelector((state) => state.searchbar.list.data);
  const showResults = useSelector((state) => state.searchbar.showResults);

  /* Close search results */
  const onHandleCloseSearch = () => {
    dispatch(setShowResults(false));
    setValue('');
  };

  /* Close search results on clicking outside */
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setTimeout(() => onHandleCloseSearch(), 500);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchend', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchend', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const searchPatients = async (val) => {
    if (val.length >= 3) {
      dispatch(fetchAll(val));
    } else {
      dispatch(setShowResults(false));
    }
  };

  const onChangeHandler = async (e) => {
    searchPatients(e.target.value);
    setValue(e.target.value);
  };

  return (
    <Box className={classes.root} style={containerStyle} ref={wrapperRef}>
      <Box
        className={clsx(
          classes.search,
          onlyIcon ? classes.searchIconBox : null,
        )}>
        <Box
          className={clsx(classes.searchIcon, {
            right: iconPosition === 'right',
          })}
          style={iconStyle}>
          <SearchIcon />
        </Box>
        <InputBase
          {...rest}
          placeholder={placeholder || 'Search Patient..'}
          className={clsx(classes.inputBase, 'crAppsSearch')}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{'aria-label': 'search'}}
          value={value}
          onChange={(e) => onChangeHandler(e)}
        />
      </Box>
      {showResults ? (
        <Results data={patients} onHandleCloseSearch={onHandleCloseSearch} />
      ) : (
        ''
      )}
    </Box>
  );
};

export default AppSearch;

AppSearch.prototype = {
  iconPosition: PropTypes.string,
  align: PropTypes.string,
  onlyIcon: PropTypes.bool,
};

AppSearch.defaultProps = {
  onlyIcon: false,
  iconPosition: 'left',
  align: 'left',
  iconStyle: {
    color: 'grey',
  },
};
