import React, {Component} from 'react';
import UnexpectedError from 'modules/errorPages/unexpected';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, errorInfo: ''};
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true, errorInfo: info});
  }
  render() {
    if (this.state.hasError) {
      return <UnexpectedError></UnexpectedError>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
