import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../../../@e74/utility/AppContext';
import { ThemeMode } from '../../constants/AppEnums';
import ApiConfig from '@e74/services/ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseNevro } from 'redux/reducers/admin/instance-settings';
import { useHistory } from 'react-router-dom';

const AppLogo = () => {
  const { themeMode } = useContext(AppContext);
  const [clinicLogoPrimaryUrl, setClinicLogoPrimaryUrl] = React.useState('');
  const dispatch = useDispatch();
  const useNevro = useSelector(selectUseNevro);
  const history = useHistory();

  React.useEffect(() => {
    ApiConfig.instanceSettings()
      .fetchClinicLogoPrimaryUrl()
      .then((response) => setClinicLogoPrimaryUrl(response.data));
  });

  const useStyles = makeStyles(() => ({

    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 125,
      margin: '0 auto',
      objectFit: 'contain',
      padding: 10,
    },
    whiteBackgroundForNevro: {
      backgroundColor: '#fff !important'
    }
  }));
  const classes = useStyles();
  return (
    <Box className={useNevro ? `${classes.logoRoot} ${classes.whiteBackgroundForNevro}` : classes.logoRoot}
      onClick={() => {
        history.push('/patients/list')
      }}>
      <img
        className={classes.logo}
        src={
          themeMode === ThemeMode.DARK
            ? clinicLogoPrimaryUrl
            : clinicLogoPrimaryUrl
        }
        alt='e74-logo'
      />
    </Box>
  );
};

export default AppLogo;
