import api from '@e74/services/ApiConfig';
import { fetchStart, fetchError, fetchSuccess } from '../Common';
import { convertToInstanceTime } from '@e74/utility/Utils';
export const ACTION_TYPES = {
  FETCH_ALL: 'FETCH_ALL_PATIENTS',
  FETCH_BY_ID: 'FETCH_PATIENT_BY_ID',
  FETCH_FILTERED: 'FETCH_FILTERED_PATIENTS',
  SET_IN_PATIENT_VIEW: 'SET_PATIENT_DASHBOARD_IN_PATIENT_VIEW',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_CASE_STATUS: 'SET_CASE_STATUS',
  SET_PATIENT_STATUS: 'SET_PATIENT_STATUS',
  SET_CURRENT_PAGE_NUMBER: 'SET_CURRENT_PAGE_NUMBER',
  SET_ROW_PER_PAGE: 'SET_ROW_PER_PAGE',
  START_PATIENT_LOADING: 'START_PATIENT_LOADING',
  STOP_PATIENT_LOADING: 'STOP_PATIENT_LOADING',
  SET_PREVIOUS_STATUS_COUNT:'SET_PREVIOUS_CASE_STATUS', 
  SET_FETCH_PATIENTS: 'SET_FETCH_PATIENTS',
};

const formatData = (data) => ({
  firstName: data.firstName,
  middleName: data.middleName,
  lastName: data.lastName,
  dateOfBirth: convertToInstanceTime(data.dateOfBirth),
  email: data.email,
  rpmKey: data.rpmKey,
  streetAddress1: data.streetAddress1,
  streetAddress2: data.streetAddress2,
  city: data.city,
  state: data.state,
  zip: data.zip,
  weight: parseFloat(data.weight !== '' ? data.weight : 0),
  heightFeet: parseFloat(data.heightFeet !== '' ? data.heightFeet : 0),
  heightInches: parseFloat(data.heightInches !== '' ? data.heightInches : 0),
  height: parseFloat(0),
  genderId: data.genderId,
  maritalStatusId: data.maritalStatusId,
  ethnicityId: data.ethnicityId,
  occupationId: data.occupationId,
  nicotineStatusId: parseInt(data.nicotineStatusId),
  currentLawSuit:
    data.currentLawSuit === null
      ? null
      : data.currentLawSuit === '1'
        ? true
        : false,
  historySubstanceAbuse:
    data.historySubstanceAbuse === null
      ? null
      : data.historySubstanceAbuse === '1'
        ? true
        : false,
  payorStatusId: data.payorStatusId,
  patientIntakeMultipleResponses: data.patientIntakeMultipleResponses,
  agreementSignedDate: data.agreementSignedDate,
  doctorId: data.doctorId,
  enableEmail: data.enableEmail,
  enableText: data.enableText,
  patientPhoneNumbers: data.patientPhoneNumbers,
});

export const fetchAll = (pageSize, pageNumber) => (dispatch) => {
  api
    .patient()
    .fetchAll(pageSize, pageNumber)
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        payload: response.data,
      });
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchFiltered = (request) => (dispatch, getState) => {
    dispatch(fetchStart());
  api
    .patient()
    .fetchFiltered({
      option: request?.option,
      status: request?.status,
      patientStatus: request?.patientStatus,
      sortBy: request?.sortBy,
      firstName: request?.firstName || '',
      lastName: request?.lastName || '',
      email: request?.email || '',
      phone: request?.phone || '',
      ageMin: request?.ageMin || null,
      ageMax: request?.ageMax || null,
      selectedStudyGroups: request?.selectedStudyGroups || [],
      paginationFilter: {
        pageSize: request?.paginationFilter?.pageSize || 15,
        pageNumber: request?.paginationFilter?.pageNumber || 0,
      },
      doctorId: getState().doctor.id,
      dateOfBirth: request?.dateOfBirth,
    })
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.FETCH_FILTERED,
        payload: response.data,
      });
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    )
};

export const setPreviousCaseStatus = (data) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_PREVIOUS_STATUS_COUNT,
    payload: data
  });
}

export const create = (data, onSuccess, onError) => {
  return async (dispatch) => {
    var item = formatData(data);
    api
      .patient()
      .create(item)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(fetchSuccess());
          onSuccess(res.data);
        } else {
          dispatch(fetchError(res.response.data.error));
          dispatch(fetchError(res));
          onError();
        }
      })
      .catch((err) => {
        onError && onError(err?.response?.data);
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err));
      });
  };
};

export const createNevro = (data, onSuccess, onError) => {
  return async (dispatch) => {
    //var item = formatData(data);
    api
      .patient()
      .create(data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(fetchSuccess());
          onSuccess(res.data);
        } else {
          dispatch(fetchError(res.response.data.error));
          dispatch(fetchError(res));
          onError();
        }
      })
      .catch((err) => {
        onError && onError(err?.response?.data);
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err));
      });
  };
};

export const createMainstay = (data, onSuccess, onError) => {
  return async (dispatch) => {
    //var item = formatData(data);
    api
      .patient()
      .create(data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(fetchSuccess());
          onSuccess(res.data);
        } else {
          dispatch(fetchError(res.response.data.error));
          dispatch(fetchError(res));
          onError();
        }
      })
      .catch((err) => {
        onError && onError(err?.response?.data);
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err));
      });
  };
};

export const update = (id, data, onSuccess, onError) => (dispatch) => {
  var item = formatData(data);
  api
    .patient()
    .update(id, item)
    .then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        dispatch(fetchSuccess());
        onSuccess();
      } else {
        onError && onError(res?.response?.data);
        dispatch(fetchError(res.response.data.error));
        dispatch(fetchError(res));
        onError();
      }
    })
    .catch((err) => {
      onError && onError(err?.response?.data);

      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const updateWithDocuments = (
  id,
  nevroCaseId,
  data,
  onSuccess,
  onError,
) => (dispatch) => {
  dispatch(fetchStart());
  api
    .patient()
    .updateWithDocuments(id, nevroCaseId, data)
    .then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        dispatch(fetchSuccess());
        onSuccess();
      } else {
        onError && onError(res?.response?.data);
        dispatch(fetchError(res.response.data.error));
        dispatch(fetchError(res));
        onError();
      }
    })
    .catch((err) => {
      onError && onError(err?.response?.data);

      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    })
  ;
};

export const nevroIntakeDoctor = (
  id,
  nevroCaseId,
  data,
  onSuccess,
  onError,
) => (dispatch) => {
  api
    .patient()
    .updateNevroForDoctor(id, nevroCaseId, data)
    .then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        dispatch(fetchSuccess());
        onSuccess();
      } else {
        onError && onError(res?.response?.data);
        dispatch(fetchError(res.response.data.error));
        dispatch(fetchError(res));
        onError();
      }
    })
    .catch((err) => {
      onError && onError(err?.response?.data);

      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const nevroIntakePatient = (
  id,
  nevroCaseId,
  data,
  onSuccess,
  onError,
) => (dispatch) => {
  api
    .patient()
    .updateNevroForPatient(id, nevroCaseId, data)
    .then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        dispatch(fetchSuccess());
        onSuccess();
      } else {
        onError && onError(res?.response?.data);
        dispatch(fetchError(res.response.data.error));
        dispatch(fetchError(res));
        onError();
      }
    })
    .catch((err) => {
      onError && onError(err?.response?.data);

      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const Delete = (id, onSuccess) => (dispatch) => {
  api
    .patient()
    .delete(id)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchById = (id) => (dispatch) => {
  api
    .patient()
    .fetchById(id)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.FETCH_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateIntakeEndDate = (data, onSuccess) => (dispatch) => {
  api
    .patient()
    .updateIntakeEndDate(data)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateMme = (data, onSuccess) => (dispatch) => {
  api
    .patient()
    .updatemme(data)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const closeIntakeUpdate = (data, onSuccess) => (dispatch) => {
  api
    .patient()
    .closeIntakeUpdate(data)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const getPatientId = (phoneNumber, onSuccess) => (dispatch) => {
  api
    .patient()
    .getPatientId(phoneNumber)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess();
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setInPatientView = (newInPatientView) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_IN_PATIENT_VIEW, payload: newInPatientView });
};

export const setCaseStatus = (newCaseStatus) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_CASE_STATUS, payload: newCaseStatus });
  localStorage.setItem('caseStatus', newCaseStatus);
};

export const setPatientStatus = (newStatus) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_PATIENT_STATUS,
    payload: newStatus,
  });
};

export const setFetchPatients = (value) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_FETCH_PATIENTS, payload: value });
};

export const setSortBy = (newSortBy) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_SORT_BY, payload: newSortBy });
};

export const setCurrentPageNumber = (newPageNumber) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CURRENT_PAGE_NUMBER,
    payload: newPageNumber,
  });
};

export const setRowPerPage = (newRowPerPage) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ROW_PER_PAGE,
    payload: newRowPerPage,
  });
};

export const createExtendedPatient = (
  extendedPatientCreateRequest,
  onSuccess = null,
  onError = null,
) => (dispatch) => {
  dispatch(fetchStart());
  api
    .patient()
    .createExtendedPatient(extendedPatientCreateRequest)
    .then((res) => {
      dispatch(fetchSuccess());
      onSuccess && onSuccess(res.data);
    })
    .catch((err) => {
      onError && onError(err?.response?.data);
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const pullChangedPatients = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedPatients()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const pullChangedPatientsForInstance = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .ehr()
    .pullChangedPatientsForInstance()
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchFiltered(null));
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateConsentSignedDateAfterExpiration = (
  id,
  onSuccess,
  onError,
) => (dispatch) => {
  api
    .patient()
    .updateConsentSignedDateAfterExpiration(id)
    .then((res) => {
      if (res.data.succeeded) {
        dispatch(fetchSuccess());
        onSuccess();
      } else {
        onError(res.data.message);
      }
    });
};
