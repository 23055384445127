import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import db from '@e74/services/FirebaseConfig';
import swal from 'sweetalert';
import ApiConfig from '@e74/services/ApiConfig';
import {Box, IconButton, withStyles, Button} from '@material-ui/core';
import {
  Notifications as NotificationsIcon,
  NotificationsActive,
  Clear,
  FiberManualRecord,
} from '@material-ui/icons';
import firebase from 'firebase';
import {formatDateTime} from '@e74/utility/Utils';

/* styling */
const styles = {
  pointer: {
    cursor: 'pointer',
  },
  notificationCounter: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 999,
    fontSize: 14,
    padding: 3,
    position: 'relative',
    left: -5,
    top: -5,
  },
  root: {
    position: 'fixed',
    top: 70,
    backgroundColor: '#7E57FE',
    height: '100%',
    overflow: 'scroll',
    right: 0,
    paddingTop: 5,
    width: 350,
    paddingBottom: 55,
    marginRight: 25,
  },
  clearButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  clearButton: {
    borderColor: '#E0626A',
    marginBottom: 10,
    '& button': {
      width: '100%',
    },
  },
  notifications: {},
  notification: {
    cursor: 'pointer',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    display: 'block',
    borderBottomColor: 'gray',
    backgroundColor: '#7E57FE',
    color: '#fff',
    transitionDuration: 300,
    '&:hover': {
      backgroundColor: '#6b8e23',
    },
    '& span': {
      margin: '0px 20px',
    },
    paddingLeft: 5,
  },
  notificationSeen: {
    cursor: 'pointer',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    display: 'block',
    borderBottomColor: 'gray',
    backgroundColor: '#7E57FE',
    color: '#fff',
    transitionDuration: 300,
    '&:hover': {
      backgroundColor: '#6b8e23',
    },
    '& span': {
      margin: '0px 20px',
    },
    paddingLeft: 5,
  },
  noNotifications: {
    textAlign: 'center',
    color: 'white',
    padding: 20,
  },
};

const Notifications = ({classes, ...props}) => {
  /*variables */
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const currentUserId = localStorage.getItem('userId');
  const [unseenNotificationsLength, setUnseenNotificationsLength] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [currentInstanceId, setCurrentInstanceId] = useState('');

  /* lifecycle methods */
  useEffect(() => {
    fetchCurrentInstanceId();
  }, []);

  useEffect(() => {
    currentInstanceId && currentUserId && fetchNotifications();
  }, [currentInstanceId]);

  /* handlers */
  const handleClick = (event) => {
    showNotifications ? handleClose() : handleOpen();
  };

  const handleOpen = () => {
    setShowNotifications(true);
  };

  const handleClose = () => {
    setShowNotifications(false);
  };

  /* close on clicking outside */
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (
          ref.current &&
          !ref.current.contains(e.target) &&
          !bellIconRef.current.contains(e.target)
        ) {
          handleClose();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  const bellIconRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleClickNotification = (docId, patientId) => {
    db.collection(`appointmentFormCompletions__${currentInstanceId}`)
      .doc(docId)
      .collection('seen')
      .doc(currentUserId)
      .set({
        seen: true,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
    history.push(`/patients/patientdashboard/${patientId}`);
    fetchNotifications();
  };

  const handleClickClearAll = async () => {
    var collectionRef = await db
      .collection(`appointmentFormCompletions__${currentInstanceId}`)
      .orderBy('timestamp', 'desc')
      .limit(50)
      .get();
    collectionRef.docs.forEach((doc) => {
      doc.ref.collection('seen').doc(currentUserId).set(
        {
          seen: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        },
        {merge: true},
      );
    });
    fetchNotifications();
  };

  /* methods */
  const fetchCurrentInstanceId = () =>
    ApiConfig.instances()
      .getCurrentInstanceId()
      .then((id) => setCurrentInstanceId(id.data))
      .catch((err) => {});

  const fetchNotifications = () => {
    let allNotifications = [];
    db.collection(`appointmentFormCompletions__${currentInstanceId}`)
      .orderBy('timestamp', 'desc')
      .limit(50)
      .onSnapshot(async (snapshot) => {
        allNotifications = await Promise.all(
          snapshot.docs.map(async (doc) => ({
            id: doc.id,
            data: doc.data(),
            seen: (await doc.ref.collection('seen').doc(currentUserId).get())
              .exists,
            notified: (
              await doc.ref.collection('notified').doc(currentUserId).get()
            ).exists,
          })),
        );
        allNotifications.forEach((n) => {
          if (n.data.timestamp) {
            n.data.timestamp = new Date(n.data.timestamp.toDate());
          }
        });
        allNotifications = (await getDetailedNotifications(allNotifications))
          .data;

        allNotifications.forEach((notification) => {
          notification.docId = notification.id;
          notification.message = `${formatDateTime(notification.timestamp)}: ${
            notification.patientFirstName
          } ${
            notification.patientLastName
          } has submitted all forms and are ready for the doctor.`;
        });
        setNotifications(allNotifications);

        const unseenNotifications = allNotifications?.filter((n) => !n.seen);
        setUnseenNotificationsLength(unseenNotifications.length);
        const unnotifiedNotifications = allNotifications?.filter(
          (n) => !n.notified,
        );
        unnotifiedNotifications.forEach((notification) => {
          notification.timestamp &&
            setTimeout(
              swal({
                className: 'notification',
                text: notification.message,
                timer: 10000,
                closeOnClickOutside: false,
              }),
              10000,
            );
          /* set notifiedToTrue for that doc */
          db.collection(`appointmentFormCompletions__${currentInstanceId}`)
            .doc(notification.id)
            .collection('notified')
            .doc(currentUserId)
            .set({
              notified: true,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
        });
      });
  };

  const getDetailedNotifications = (rawNotifications) =>
    ApiConfig.patientappointments().getDetailedNotifications(rawNotifications);

  return (
    <Box py={3} pl={{xs: 2, sm: 6}}>
      <Box className={classes.pointer}>
        <IconButton onClick={handleClick} ref={bellIconRef}>
          {unseenNotificationsLength > 0 ? (
            <>
              <NotificationsActive />
              <span className={classes.notificationCounter}>
                {unseenNotificationsLength}
              </span>
            </>
          ) : (
            <NotificationsIcon />
          )}
        </IconButton>
      </Box>
      {showNotifications && (
        <Box className={classes.root} ref={wrapperRef}>
          <Box className={classes.clearButtonWrapper}>
            <Button
              variant='contained'
              className={classes.clearButton}
              disabled={unseenNotificationsLength <= 0}
              onClick={handleClickClearAll}>
              Clear All <Clear />
            </Button>
          </Box>
          <Box component='div' fontSize={16} className={classes.notifications}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <div
                  key={notification.docId}
                  onClick={() =>
                    handleClickNotification(
                      notification.docId,
                      notification.patientId,
                    )
                  }
                  className={
                    notification?.seen
                      ? classes.notificationSeen
                      : classes.notification
                  }>
                  {!notification.seen && <FiberManualRecord />}
                  {notification.seen ? (
                    notification.message
                  ) : (
                    <strong>{notification.message}</strong>
                  )}
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>No notifications</div>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(Notifications);
