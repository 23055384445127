import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Drawer, Divider, Hidden} from '@material-ui/core';
import clsx from 'clsx';
import Navigation from '../../Navigation/VerticleNav';
import {toggleNavCollapsed} from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import useStyles from './AppSidebarNevro.style';
import HipaaLogo from 'shared/components/HipaaLogo';
import FilterAndSortPanel from 'modules/patients/patientlist/FilterAndSortPanel';
import UserInfo from 'shared/components/UserInfo';
import GooglePageTranslator from 'shared/components/GooglePageTranslator';
import ClinicSwitcher from 'shared/components/ClinicSwitcher';
import DoctorSwitcher from 'shared/components/DoctorSwitcher';
import {useLocation} from 'react-router-dom';
import AppLogo from 'shared/components/AppLogo';
import AppLogoWhite from 'shared/components/AppLogoWhite';

const AppSidebarNevro = (props) => {
  const dispatch = useDispatch();
  const navCollapsed = useSelector(({settings}) => settings.navCollapsed);

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };

  const classes = useStyles(props);
  let sidebarClasses = classes.sidebarStandard;

  const location = useLocation();
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const handleRouteChange = () => {
    switch (location.pathname) {
      case '/patients/list':
        setShowFilterPanel(true);
        break;
      default:
        setShowFilterPanel(false);
        break;
    }
  };

  useEffect(() => {
    handleRouteChange();
  }, [location]);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          open={navCollapsed}
          onClose={(ev) => handleToggleDrawer()}
          classes={{
            root: clsx(classes.sidebarWrapper, props.variant),
            paper: clsx(
              classes.sidebar,
              props.variant,
              props.position === 'left'
                ? classes.leftSidebar
                : classes.rightSidebar,
            ),
          }}
          // container={props.rootRef.current}
          BackdropProps={{
            classes: {
              root: classes.backdrop,
            },
          }}
          style={{position: 'absolute'}}>
          <Box height='100%' className={classes.container}>
            <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
              <AppLogo />
              {/* <AppLogoWhite/> */}
              <PerfectScrollbar className={classes.drawerScrollAppSidebar}>
                <Navigation />
                {showFilterPanel && <FilterAndSortPanel />}
                <div
                  style={{
                    marginTop: 'auto',
                  }}>
                  <ClinicSwitcher />
                </div>
              </PerfectScrollbar>
              <div
                style={{
                  position: 'absolute',
                  bottom: '5px',
                  left: 0,
                  // display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '100%',
                  zIndex: 1,
                  // backgroundColor: 'rgb(217, 217, 217)',
                  backgroundColor: 'white',
                  // marginBottom: '-15px',
                }}>
                <UserInfo />
                {/* <GooglePageTranslator /> */}
              </div>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height='100%' className={clsx(classes.container, 'app-sidebar')}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <AppLogo />
            {/* <AppLogoWhite /> */}
            <PerfectScrollbar className={classes.scrollAppSidebar}>
              <Navigation />
              {/* <hr /> */}
              {showFilterPanel && <FilterAndSortPanel />}

              <div
                style={{
                  // marginTop: 'auto',
                  color: 'black',
                }}>
                <ClinicSwitcher />
              </div>
            </PerfectScrollbar>
            <div
              style={{
                position: 'absolute',
                bottom: '5px',
                left: 0,
                // display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                alignItems: 'center',
                width: '100%',
                zIndex: 1,
                backgroundColor: '#FFF',
                padding: '0px 20px',
                paddingBottom: 0,
                height: 60,
                right: 0,
                borderTop: 'solid 1px rgba(0,0,0,0.12)',
                paddingTop: '10px',
              }}>
              <UserInfo />
              {/* <GooglePageTranslator /> */}
            </div>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default AppSidebarNevro;

AppSidebarNevro.defaultProps = {
  variant: '',
  position: 'left',
};

AppSidebarNevro.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
